import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableBody } from '@mui/material';
import { displayDateWithHour } from '../../../../common/utils';
import { BasicSkeletons } from '../../../../components/basic-skeletons';
import { useImmediateCalls } from '../../hooks/use-immediate-calls.query';
import { CallsTableHeader } from './calls-table-header';
import { CallsTableRow } from './calls-table-row';
import { TimeZoneContext } from '../../../scenarios/provider/timezone-context.provider';

export const CallsTable: React.FC = () => {
  const { data, isLoading } = useImmediateCalls();
  const { t } = useTranslation();
  const { timezone } = useContext(TimeZoneContext);

  return (
    <Box
      sx={{
        overflowX: 'auto',
        overflowY: 'hidden',
        backgroundColor: 'background.paper',
        borderColor: 'background.paper',
        borderStyle: 'solid',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 10,
        borderBottomWidth: 50,
        boxSizing: 'border-box',
      }}
    >
      {isLoading ? (
        <BasicSkeletons number={8} height={60} />
      ) : (
        <Table sx={{ width: '100%' }}>
          <CallsTableHeader />
          <TableBody>
            {data?.records?.map((x) => (
              <CallsTableRow
                key={x.id}
                id={x.id}
                phoneNumber={x.phoneNumber}
                savedOnDateUtc={displayDateWithHour(x.savedOnDate, timezone)}
                status={t(`callsSchedule.recordStatus.${x.status.toLowerCase()}`)}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};
