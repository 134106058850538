import React from 'react';
import { Box, Skeleton } from '@mui/material';

export const ScenariosHeadingLoadingSkeleton: React.FC = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <Box sx={{ maxWidth: '100vw', width: '300px' }}>
      <Skeleton height={50} />
    </Box>
  </Box>
);
