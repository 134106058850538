import { isValid } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const parseDate = (date: string): Date => {
  return new Date(Date.parse(date));
};

export const displayDate = (date: string, timezone: string): string => {
  const isTimezone = timezone !== '';
  return isTimezone && isValid(new Date(date)) ? formatInTimeZone(parseDate(date), timezone, 'dd.MM.yyyy') : date;
};

export const displayHour = (date: string, timezone: string): string => {
  const isTimezone = timezone !== '';
  return isTimezone && isValid(new Date(date)) ? formatInTimeZone(parseDate(date), timezone, 'HH:mm') : date;
};

export const displayDateWithHour = (date: string, timezone: string): string => {
  const isTimezone = timezone !== '';
  if (isTimezone && isValid(new Date(date))) {
    return formatInTimeZone(parseDate(date), timezone, 'dd.MM.yyyy HH:mm');
  }
  return date;
};
