import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

type UseAlertType = {
  successAlert: (message: string | JSX.Element) => void;
  warningAlert: (message: string | JSX.Element) => void;
  errorAlert: (message: string | JSX.Element) => void;
  infoSnackbar: (message: string | JSX.Element) => void;
};

export const useAlert = (): UseAlertType => {
  const { enqueueSnackbar } = useSnackbar();

  const successAlert = useCallback(
    (message: string | JSX.Element) => {
      enqueueSnackbar(message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
    },
    [enqueueSnackbar],
  );

  const warningAlert = useCallback(
    (message: string | JSX.Element) => {
      enqueueSnackbar(message, { variant: 'warning', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
    },
    [enqueueSnackbar],
  );

  const errorAlert = useCallback(
    (message: string | JSX.Element) => {
      enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
    },
    [enqueueSnackbar],
  );

  const infoSnackbar = useCallback(
    (message: string | JSX.Element) => {
      enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'center' } });
    },
    [enqueueSnackbar],
  );

  return {
    successAlert,
    warningAlert,
    errorAlert,
    infoSnackbar,
  };
};
