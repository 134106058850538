import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { apiHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from './use-scenario';

export enum ParamType {
  String = 'String',
  Int = 'Int',
  Double = 'Double',
  Boolean = 'Boolean',
  DateOnly = 'DateOnly',
  TimeOnly = 'TimeOnly',
  DateTime = 'DateTime',
}

type ResponseType = {
  parameters: {
    [key: string]: {
      defaultValue: string | number | boolean;
      readableName: string;
      type: string;
      required: boolean;
    };
  };
};

export const useScenariosParameters = (): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const { id: scenarioId } = useScenario();

  return useQuery<ResponseType, Error>(
    ['scenarioParameters', scenarioId],
    async () => {
      const response = await apiHttpClient.get<ResponseType>(`/Scenarios/${scenarioId}/RequiredParameters`);
      return response.data;
    },
    {
      enabled: authenticated && !!scenarioId,
    },
  );
};
