import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { MenuOutlined } from '@mui/icons-material';
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { useModal } from '../../../../common/hooks';
import { routes } from '../../../../routes';
import { useScenario } from '../../../scenarios/hooks';
import { useBatchDraft } from '../../hooks';
import { useDuplicateBatch } from '../../hooks/use-duplicate-batch.mutation';
import { BatchContext, DraftCollectionContext } from '../../provider';
import { BatchType } from '../../types';
import { CancelCollectionModal } from './cancel-collection-modal';
import { DuplicateCollectionModal } from './duplicate-collection-modal';
import { EditCollectionModal } from './edit-collection-modal';
import { LoaderComponent } from '../../../../components/loader-component';

type Props = {
  batch: BatchType;
};

export const EditBatchButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const cancelCollectionModal = useModal();
  const editCollectionModal = useModal();
  const duplicateCollectionModal = useModal();
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);
  const { data } = useBatchDraft();
  const navigate = useNavigate();
  const { id } = useScenario();
  const { mutate, isSuccess, isLoading } = useDuplicateBatch();
  const { batchId } = useContext(BatchContext);
  const { draftId } = useContext(DraftCollectionContext);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuEl(null);
  };

  const handleCancelBatch = () => {
    cancelCollectionModal.open();
    setMenuEl(null);
  };

  const handleEditBatch = () => {
    editCollectionModal.open();
    setMenuEl(null);
  };

  const handleDuplicateCollection = () => {
    if (data) {
      duplicateCollectionModal.open();
      setMenuEl(null);
      return;
    }
    const newDraftId = uuid();
    setMenuEl(null);
    mutate(
      { newBatchId: newDraftId, duplicationBatchId: batchId.value, scenarioId: id },
      {
        onSuccess: () => {
          draftId.set(newDraftId);
          editCollectionModal.close();
          navigate(routes.scenario.callsSchedule.addCollection.link({ id }));
        },
      },
    );
    if (isSuccess) {
      navigate(routes.scenario.callsSchedule.addCollection.link({ id }));
    }
  };

  if (isLoading) {
    <LoaderComponent />;
  }

  return (
    <>
      <Tooltip
        title={t('callsSchedule.collection.editOptions.editOptionsTooltip')}
        arrow
        enterDelay={500}
        leaveDelay={200}
      >
        <Button
          onClick={handleMenuClick}
          variant="outlined"
          startIcon={<MenuOutlined />}
          sx={{
            width: 48,
            height: 48,
            minWidth: 48,
            '& .MuiButton-startIcon': {
              margin: 0,
            },
          }}
        />
      </Tooltip>
      <Menu id="edit-batch-menu" anchorEl={menuEl} keepMounted open={Boolean(menuEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleEditBatch}>{t('callsSchedule.collection.editOptions.changeName')}</MenuItem>
        {(props.batch.status === 'Planned' || props.batch.status === 'InProgress') && (
          <MenuItem onClick={handleCancelBatch}>{t('callsSchedule.collection.editOptions.cancelCollection')}</MenuItem>
        )}
        <MenuItem onClick={handleDuplicateCollection}>
          {t('callsSchedule.collection.editOptions.duplicateCollection')}
        </MenuItem>
      </Menu>
      <CancelCollectionModal handleModalClose={cancelCollectionModal.close} modalOpen={cancelCollectionModal.isOpen} />
      <EditCollectionModal handleModalClose={editCollectionModal.close} modalOpen={editCollectionModal.isOpen} />
      <DuplicateCollectionModal
        handleModalClose={duplicateCollectionModal.close}
        modalOpen={duplicateCollectionModal.isOpen}
      />
    </>
  );
};
