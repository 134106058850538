import React from 'react';
import Loader from 'react-loader-spinner';
import { Stack } from '@mui/material';

type PropsType = {
  size?: number;
};

export const LoaderComponent: React.FC<PropsType> = (props) => {
  const size = props.size ? props.size : 100;
  return (
    <Stack
      direction={'row'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingY: {
          xs: '2rem',
          md: '6rem',
        },
      }}
    >
      <Loader type={'Oval'} color="#09d9d7" height={size} width={size} />
    </Stack>
  );
};
