import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterList } from '@mui/icons-material';
import { Box, Button, IconButton, Popover, Stack } from '@mui/material';

type Props = {
  onAcceptClick: () => void;
  onResetClick?: () => void;
  clearable?: boolean;
};

export const PopoverFilter: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = useMemo(() => (open ? 'popover-' + Math.floor(Math.random() * 9999) : undefined), [open]);

  const handleAcceptButton = () => {
    props.onAcceptClick();
    setAnchorEl(null);
  };

  const handleResetButton = () => {
    setAnchorEl(null);
    if (props.onResetClick) {
      props.onResetClick();
    }
  };

  return (
    <Box>
      <IconButton
        className="intercom-filter-button"
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          boxShadow: 'none',
          marginLeft: 1,
        }}
      >
        <FilterList />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box padding={2}>
          <Box>{props.children}</Box>
          <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} mt={1}>
            {props.clearable && (
              <Button color="secondary" variant="text" onClick={handleResetButton}>
                {t('clear')}
              </Button>
            )}
            <Button variant="contained" color="primary" onClick={handleAcceptButton}>
              Ok
            </Button>
          </Stack>
        </Box>
      </Popover>
    </Box>
  );
};
