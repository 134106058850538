import { TableCellClasses, TableCellProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const tableCellStyles: {
  defaultProps?: Partial<TableCellProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof TableCellClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '216px',
      whiteSpace: 'nowrap',
      backgroundColor: '#FFFFFF',
    },
  },
};
