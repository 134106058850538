import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { useAlert } from '../../../common/hooks/use-alert';
import { foundationHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';

type ResponseType = {
  scenarioCollection: ScenarioType[];
};

type ScenarioType = {
  scenarioId: string;
  scenarioName: string;
};

export const useScenariosQuery = (): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const { errorAlert } = useAlert();

  return useQuery<ResponseType, Error>(
    ['scenarios'],
    async () => {
      const response = await foundationHttpClient.get<ResponseType>(`/Shared/Scenarios`);
      return response.data;
    },
    {
      enabled: authenticated,
      staleTime: 10000,
      onError: () => {
        errorAlert('Wystąpił problem z synchornizacją scenariuszy');
      },
    },
  );
};
