import {initReactI18next} from 'react-i18next';
import i18n from 'i18next';
import {en} from './langs/en';
import {pl} from './langs/pl';
import {es} from './langs/es';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    pl: {
      translation: pl,
    },
    es: {
        translation: es,
    }
  },
  lng: window.localStorage.getItem('lang') || 'pl',
  fallbackLng: 'pl',
});

export const setHTMLAttrLangTag = (lang: string): void => {
  document.documentElement.setAttribute('lang', lang);
};
