import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { PopoverFilter } from '../../../../components/popover-filter';

type Props = {
  value: { incoming: boolean; outgoing: boolean };
  setFunction: (value: { incoming: boolean; outgoing: boolean }) => void;
};

export const CallDirectionFilter: React.FC<Props> = (props) => {
  const [directions, setDirections] = useState({ incoming: true, outgoing: true });

  return (
    <PopoverFilter
      onAcceptClick={() => props.setFunction({ incoming: directions.incoming, outgoing: directions.outgoing })}
    >
      <Stack>
        <FormControlLabel
          control={
            <Checkbox
              checked={directions.incoming}
              onChange={() => setDirections({ incoming: !directions.incoming, outgoing: directions.outgoing })}
              name="incoming"
            />
          }
          label="Przychodzące"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={directions.outgoing}
              onChange={() => setDirections({ incoming: directions.incoming, outgoing: !directions.outgoing })}
              name="outgoing"
            />
          }
          label="Wychodzące"
        />
      </Stack>
    </PopoverFilter>
  );
};
