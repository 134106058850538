import React, { FC, useContext, useEffect, useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import { enGB, pl } from 'date-fns/locale';
import { Button, Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import { BasicModal } from '../../../../../components/basic-modal';
import { TranslationContext } from '../../../../translation';
import { useAddCalsDateTime } from '../../../hooks/use-add-calls-date-time.mutation';
import { RangeDateItem } from './components';
import { DraftCollectionContext } from '../../../provider';
import { useErrorHandler } from '../../../../../common/hooks';

type Props = {
  handleModalClose: () => void;
  modalOpen: boolean;
  batchId: string;
  scenarioId: string;
  refetch: () => void;
};

export const RangeDateModal: FC<Props> = (props) => {
  const { currentLanguage } = useContext(TranslationContext);
  const { mutate } = useAddCalsDateTime();
  const { t } = useTranslation();
  const desktopFormWidth = 490;
  const { draftId } = useContext(DraftCollectionContext);

  const [rangeTime, setRangeTime] = useState({
    startTime: '08:00',
    endTime: '16:00',
  });
  const [rangeDate, setRangeDate] = useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
    color: '#1c1b40',
  });
  const [locale, setLocale] = useState({});
  const [step, setStep] = useState<number>(0);
  const [skipWeekends, setSkipWeekends] = useState(false);

  useEffect(() => {
    const languages: { [key: string]: Locale } = {
      pl: pl,
      en: enGB,
    };
    setLocale(languages[currentLanguage || 'pl']);
  }, [currentLanguage]);

  const parseDaySchedule = (startTime: string, endTime: string, dates: Date[]) => {
    const parsedSchedule = dates.map((date) => {
      const start = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        parseInt(startTime.split(':')[0]),
        parseInt(startTime.split(':')[1]),
      );
      const stop = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        parseInt(endTime.split(':')[0]),
        parseInt(endTime.split(':')[1]),
      );

      return {
        start: start,
        stop: stop,
      };
    });

    return parsedSchedule;
  };

  const [dates, setDates] = useState<Date[]>([]);
  useEffect(() => {
    const startDate = rangeDate.startDate || new Date();
    const endDate = rangeDate.endDate || new Date();
    const dates = [];
    const days = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
    if (skipWeekends) {
      for (let i = 0; i <= days; i++) {
        const date = new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
        if (date.getDay() !== 6 && date.getDay() !== 0) {
          dates.push(date);
        }
      }
    } else {
      for (let i = 0; i <= days; i++) {
        const date = new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
        dates.push(date);
      }
    }
    setDates(dates);
  }, [rangeDate, skipWeekends]);
  const errorHandler = useErrorHandler();

  const modalBody: JSX.Element = (
    <Stack
      flexDirection="column"
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{
        width: desktopFormWidth,
      }}
    >
      {step === 0 && (
        <>
          <DateRange
            minDate={new Date()}
            dateDisplayFormat="dd.MM.yyyy"
            editableDateInputs={true}
            startDatePlaceholder={t('datePicker.dateFrom')}
            endDatePlaceholder={t('datePicker.dateTo')}
            locale={locale}
            onChange={(item) => {
              const dates = item as { selection: Range };
              setRangeDate(dates.selection);
            }}
            moveRangeOnFirstSelection={false}
            ranges={[{ ...rangeDate, endDate: rangeDate.endDate || new Date() }]}
          />
          <Stack flexDirection="row" gap={2} justifyContent="center" alignItems="center" mt={2}>
            <TextField
              label={t('from')}
              type="time"
              defaultValue="08:00"
              value={rangeTime.startTime}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setRangeTime({
                  ...rangeTime,
                  startTime: event.target.value,
                });
              }}
            />
            <Typography variant="body2" color="textSecondary">
              -
            </Typography>
            <TextField
              label={t('to')}
              type="time"
              value={rangeTime.endTime}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                setRangeTime({
                  ...rangeTime,
                  endTime: event.target.value,
                });
              }}
            />
          </Stack>
          <Stack
            flexDirection="row"
            sx={{
              width: desktopFormWidth,
            }}
            gap={2}
            justifyContent="end"
            alignItems="center"
            mt={2}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setStep(1);
                setSkipWeekends(false);
              }}
            >
              {t('next')}
            </Button>
          </Stack>
        </>
      )}
      {step === 1 && (
        <Stack>
          <FormControlLabel
            label={t('skipWeekends')}
            control={
              <Checkbox
                disabled={dates.length === 0 || dates.every((date) => date.getDay() === 6 || date.getDay() === 0)}
                checked={skipWeekends}
                onChange={(event) => setSkipWeekends(event.target.checked)}
              />
            }
          />
          <Stack
            flexDirection="column"
            justifyContent="start"
            alignItems="center"
            sx={{
              overflowY: 'auto',
              maxHeight: 300,
            }}
          >
            <Stack
              flexDirection="column"
              justifyContent="start"
              alignItems="center"
              sx={{
                overflowY: 'auto',
                maxHeight: 300,
              }}
            >
              {dates.map((date, index) => (
                <RangeDateItem
                  key={index}
                  date={date}
                  time={{
                    endTime: rangeTime.endTime,
                    startTime: rangeTime.startTime,
                  }}
                  sx={{
                    backgroundColor: index % 2 === 0 ? 'transparent' : 'rgba(0, 0, 0, 0.02)',
                  }}
                  onRemove={() => {
                    setDates(dates.filter((item) => item.getTime() !== date.getTime()));
                    if (dates.length === 1) {
                      setStep(0);
                      rangeDate.startDate = new Date();
                      rangeDate.endDate = new Date();
                    }
                  }}
                />
              ))}
            </Stack>
          </Stack>
          <Stack
            flexDirection="row"
            sx={{
              width: desktopFormWidth,
            }}
            gap={2}
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <Button variant="outlined" color="primary" onClick={() => setStep(0)}>
              {t('back')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={dates.length === 0}
              onClick={() => {
                const parsedDates = parseDaySchedule(rangeTime.startTime, rangeTime.endTime, dates);
                mutate(
                  {
                    ranges: parsedDates,
                    batchId: draftId.value,
                    scenarioId: props.scenarioId,
                  },
                  {
                    onSuccess: () => {
                      setStep(0);
                      props.handleModalClose();
                      props.refetch();
                    },
                    onError: (err) => {
                      errorHandler(err);
                    },
                  },
                );
              }}
            >
              {t('add')}
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );

  return (
    <BasicModal
      title={t('callsSchedule.draftCollection.addNewDateRange')}
      modalBody={modalBody}
      description={t('callsSchedule.draftCollection.addNewDateRangeDescription')}
      handleModalClose={() => {
        setStep(0);
        setRangeDate({
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
          color: '#1c1b40',
        });
        props.handleModalClose();
      }}
      modalOpen={props.modalOpen}
    />
  );
};
