import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Container, Grid, List, Theme, Typography, useMediaQuery } from '@mui/material';
import { LoaderComponent } from '../../../../components/loader-component';
import { useCollectionQuery } from '../../hooks';
import { CollectionItem } from './index';

export const CollectionList: React.FC = () => {
  const { t } = useTranslation();
  const { data, isFetched, isLoading, status } = useCollectionQuery();
  const disableGutters = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const filtredCollections = data?.collections?.filter((collection) => collection.collectionType !== 3);
  return (
    <Container
      maxWidth="xxl"
      disableGutters={disableGutters}
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {(!isFetched || filtredCollections === null || status == 'error') && !isLoading ? (
        <Grid container justifyContent="center">
          <Grid
            item
            sx={{
              width: '100%',
              py: 3,
              px: {
                xs: 0,
                md: 3,
              },
            }}
          >
            <Card
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                padding: 4,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textAlign: 'center',
                }}
              >
                {t('scenarioConfigurator.noCollections')}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <List
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            rowGap: (theme) => theme.spacing(1),
            width: 0,
            pb: 12,
          }}
        >
          {isLoading ? (
            <LoaderComponent />
          ) : (
            filtredCollections?.map((collection) => <CollectionItem collection={collection} key={collection.id} />)
          )}
        </List>
      )}
    </Container>
  );
};
