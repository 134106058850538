import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { displayDateWithHour } from '../../../../common/utils';
import { CallDetailsModal } from '../../../../components/call-details-modal';
import { LoaderComponent } from '../../../../components/loader-component';
import { useReportColumns, useReportData } from '../../hooks';
import { useReportList } from '../../hooks/use-report-list.query';
import { ReportsContext } from '../../provider/reports-context.provider';
import { ReportTableHead } from './report-table-head';
import { TimeZoneContext } from '../../../scenarios/provider/timezone-context.provider';

export const ReportTable: React.FC = () => {
  const { t } = useTranslation();
  const { reportId, columns, callDetails } = useContext(ReportsContext);
  const reportData = useReportData(reportId.value);
  const reportColumns = useReportColumns(reportId.value);
  const reportList = useReportList();
  const { timezone } = useContext(TimeZoneContext);
  useEffect(() => {
    if (reportColumns.data) {
      const filteredColumns = reportColumns.data.columnMapping.filter((column) => column.type !== 'Details');
      callDetails.set(reportColumns.data.columnMapping.length !== filteredColumns.length);
      columns.set(filteredColumns);
    }
  }, [reportColumns.data]);

  const dbColumnName = useMemo(() => {
    return reportColumns?.data?.columnMapping?.find((x) => x.type === 'Details')?.dbColumnName;
  }, [reportColumns?.data]);

  const isSticky = (columnIndex: number) => !callDetails.value && columnIndex === columns.value.length - 1;

  if (reportList.isSuccess && !reportList.data?.reports?.length) {
    return (
      <Grid container justifyContent="center" width="100%">
        <Grid
          item
          sx={{
            width: '100%',
            py: 3,
            px: {
              xs: 0,
              md: 3,
            },
          }}
        >
          <Card
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              padding: 4,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textAlign: 'center',
              }}
            >
              {t('reports.noReports')}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box
      sx={{
        mx: {
          xs: 2.5,
          sm: 3,
        },
        overflowX: 'auto',
        overflowY: 'hidden',
        borderColor: 'background.paper',
        borderStyle: 'solid',
        borderLeftWidth: 24,
        borderRightWidth: 24,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        boxSizing: 'border-box',
      }}
    >
      <Table>
        <ReportTableHead reportId={reportId.value} />
        <TableBody
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
          {reportData.data?.rows.map((row, index) => (
            <TableRow key={index}>
              {columns.value.map((column, columnIndex) => {
                const columnData = row.columns.find((item) => item.columnName === column.dbColumnName);
                return (
                  <TableCell
                    key={`${index}-${columnIndex}-${columnData?.columnName}`}
                    sx={{
                      position: isSticky(columnIndex) ? 'sticky' : undefined,
                      right: isSticky(columnIndex) ? -26 : undefined,
                      zIndex: isSticky(columnIndex) ? 1 : undefined,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {column.type === 'date'
                      ? displayDateWithHour(columnData?.value as string, timezone)
                      : columnData?.value}
                  </TableCell>
                );
              })}
              {callDetails.value && (
                <TableCell
                  key={`${index}-details`}
                  sx={{
                    position: 'sticky',
                    right: -26,
                    zIndex: 1,
                  }}
                >
                  <CallDetailsModal callId={row?.columns?.find((z) => z?.columnName === dbColumnName)?.value || ''} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {reportData.isSuccess && !reportData.isLoading && !reportData.data?.rows.length ? (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '1.1rem',
            fontWeight: 700,
            width: '100%',
            mt: 3,
          }}
        >
          {t('reports.noResults')}
        </Typography>
      ) : null}
      {reportData.isLoading ? <LoaderComponent /> : null}
    </Box>
  );
};
