import React, { useContext, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DeleteOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useDeviceQuery, useErrorHandler, useModal } from '../../../../common/hooks';
import { useAlert } from '../../../../common/hooks/use-alert';
import { downloadFileWithToken } from '../../../../common/utils';
import { InputText } from '../../../../components/inputs';
import { environment } from '../../../../environment';
import { routes } from '../../../../routes';
import { useScenario } from '../../../scenarios/hooks';
import {
  useBatchDraft,
  useDraftRecords,
  useInsertDraftFile,
  useRemoveDraftFile,
  useSaveAndStartBatch,
  useUpdateBatchName,
} from '../../hooks';
import { useDefaultParameters } from '../../hooks/use-default-parameters.query';
import { useRemoveCallsDateTime } from '../../hooks/use-remove-calls-date-time.mutation';
import { useUpdateDelayParameter } from '../../hooks/use-update-delay-parameter.mutation';
import { useUpdateRepeatParameter } from '../../hooks/use-update-repeat-parameter.mutation';
import { DraftCollectionContext } from '../../provider';
import { DateRangeType } from '../../types/default-parameters';
import { AddRecordModal } from './add-record-modal';
import { FileErrorsModal } from './file-errors.modal';
import { RangeDateModal } from './range-date-modal/range-date-modal';
import { RangeTime } from '../../range-time';
import { useUpdateAdditionalParameter } from '../../hooks/use-update-additional-parameter.mutation';

export type LoginFormValues = {
  batchName: string;
  delay: number;
  repeat: number;
  rangeDate: DateRangeType[];
  file: File;
  additionalParameters: {
    [key: string]: string | number | boolean;
  };
};

export const DraftCollectionForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceQuery();
  const { id } = useScenario();
  const { draftId } = useContext(DraftCollectionContext);
  const [error, setError] = useState<string>();
  const addRecordModal = useModal();
  const errorInsertFileModal = useModal();
  const rangeDateModal = useModal();
  const { handleSubmit, watch, control, register, reset, setValue } = useFormContext<LoginFormValues>();
  const { data } = useDefaultParameters();
  const removeCallsDateTime = useRemoveCallsDateTime();
  const saveAndStartBatch = useSaveAndStartBatch();
  const batchDraft = useBatchDraft();
  const updateAdditionalParameters = useUpdateAdditionalParameter();
  const updateBatchName = useUpdateBatchName();
  const updateBatchDelay = useUpdateDelayParameter();
  const updateBatchRepeat = useUpdateRepeatParameter();
  const draftRecords = useDraftRecords();
  const insertDraftFile = useInsertDraftFile();
  const removeDraftFile = useRemoveDraftFile();
  const { successAlert, errorAlert } = useAlert();
  const mdUpSize = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const errorHandler = useErrorHandler();

  const submit = async (data: LoginFormValues) => {
    let activeElementId;

    if (document.activeElement === null) {
      errorAlert(t('callsSchedule.draftCollection.errors.saveCollectionError'));
    } else {
      activeElementId = document.activeElement.id;

      if (activeElementId === 'saveAndStartBatch') {
        saveAndStartBatch.mutate(
          { scenarioId: id, batchName: data.batchName },
          {
            onSuccess: () => {
              reset();
              successAlert(t('callsSchedule.draftCollection.successSavedAndStartAlert', { batchName: data.batchName }));
              navigate(routes.scenario.callsSchedule.collectionList.link({ id }));
            },
            onError: (err) => {
              const errorCode = err.response?.data.errorCode;
              errorAlert(t(`errorCode.${errorCode}`) || t('callsSchedule.draftCollection.errors.saveCollectionError'));
            },
          },
        );
      }
    }
  };

  const handleFileUpload = async (data: React.ChangeEvent<HTMLInputElement>) => {
    if (!data.target.files) return;
    const file = data.target.files[0];
    draftRecords.refetch();
    if (!file.name.endsWith('csv') && !file.name.endsWith('xls') && !file.name.endsWith('xlsx')) {
      setError(t('callsSchedule.draftCollection.errors.wrongFileFormat'));
      return;
    }
    const text = await file.text();
    if (!text) {
      setError(t('callsSchedule.draftCollection.errors.fileReadingError'));
      return;
    }

    insertDraftFile.mutate(
      {
        scenarioId: id,
        file: file,
      },
      {
        onSuccess: () => {
          batchDraft.refetch();
          draftRecords.refetch();
          data.target.value = '';
        },
        onError: (err) => {
          data.target.value = '';
          errorHandler(err);
          errorInsertFileModal.open();
        },
      },
    );
  };

  const handleRemoveFile = (fileId: string) => {
    removeDraftFile.mutate(
      { fileId },
      {
        onSuccess: () => {
          batchDraft.refetch();
          draftRecords.refetch();
        },
      },
    );
  };

  const handleUpdateBatchAdditionalParameters = (key: string, value: string | Date | number | boolean | null) => {
    updateAdditionalParameters.mutate(
      {
        key,
        value,
        scenarioId: id,
        batchId: draftId.value,
      },
      {
        onSuccess: () => {
          batchDraft.refetch();
        },
      },
    );
  };

  const handleUpdateBatchName = (name: string) => {
    if (draftId.value && name) {
      updateBatchName.mutate({
        batchId: draftId.value,
        scenarioId: id,
        batchName: name,
      });
      return;
    }
  };

  const handleUpdateBatchDelay = (delay: number) => {
    if (draftId.value && delay) {
      updateBatchDelay.mutate({
        BatchId: draftId.value,
        scenarioId: id,
        newValue: delay,
      });
      return;
    }
  };

  const handleUpdateBatchRepeat = (repeat: number) => {
    if (draftId.value && repeat) {
      updateBatchRepeat.mutate({
        BatchId: draftId.value,
        scenarioId: id,
        newValue: repeat,
      });
      return;
    }
  };

  const handleClickOnDownloadTemplate = async () => {
    downloadFileWithToken(environment.CALLING_API_URL + '/File/' + id + '/DownloadTemplateFile', 'template.xlsx');
  };

  return (
    <Stack
      sx={{
        width: '100%',
        paddingRight: {
          md: 2,
        },
        maxWidth: {
          md: '42%',
          lg: '40%',
          xl: '30%',
        },
      }}
    >
      <Typography
        variant={'h3'}
        sx={{
          fontSize: '18px',
          lineHeight: '22.59px',
          fontWeight: 700,
          marginTop: 2,
          marginBottom: {
            xs: '1rem',
            md: '2rem',
          },
        }}
      >
        {t('callsSchedule.draftCollection.addCollection')}
      </Typography>

      <form
        onSubmit={handleSubmit(submit)}
        id="draft-collection-form"
        style={{
          flexGrow: 1,
          paddingRight: mdUpSize ? '0.8rem' : 0,
          borderRight: mdUpSize ? '1px solid #AAABC4' : '',
          borderBottom: mdUpSize ? '' : '1px solid #AAABC4',
        }}
      >
        <Controller
          name="batchName"
          control={control}
          rules={{ required: t('callsSchedule.draftCollection.errors.enterCollectionName') as string }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputText
              fullWidth
              disabled={insertDraftFile.isLoading}
              name="collectionName"
              type={'text'}
              label={t('callsSchedule.draftCollection.collectionName')}
              value={value || ''}
              onChange={onChange}
              error={error}
              onBlur={() => handleUpdateBatchName(value)}
            />
          )}
        />

        <Typography
          variant={'h4'}
          sx={{
            marginTop: '1.5rem',
            fontSize: '14px',
            lineHeight: '17.57px',
            fontWeight: 700,
          }}
        >
          {t('callsSchedule.draftCollection.addRecords')}
        </Typography>

        <Stack
          direction={'row'}
          gap={2}
          justifyContent={'space-between'}
          sx={{
            flexWrap: 'wrap',
            marginTop: '1.5rem',
            marginBottom: '1rem',
          }}
        >
          <Button
            disabled={insertDraftFile.isLoading}
            variant="contained"
            component="label"
            color="primary"
            sx={{
              whiteSpace: 'nowrap',
              textAlign: 'center',
              width: {
                xs: '100%',
                md: '40%',
              },
            }}
          >
            {t('callsSchedule.draftCollection.importFile')}
            <input type="file" {...register('file')} accept={'.csv, .xlsx, .xls'} hidden onChange={handleFileUpload} />
          </Button>
          <Button
            disabled={insertDraftFile.isLoading}
            onClick={handleClickOnDownloadTemplate}
            type="button"
            variant="contained"
            color="primary"
            sx={{
              whiteSpace: 'nowrap',
              textAlign: 'center',
              width: {
                xs: '100%',
                md: '50%',
              },
            }}
          >
            {t('callsSchedule.draftCollection.downloadTemplate')}
          </Button>
          <Button
            disabled={insertDraftFile.isLoading}
            onClick={addRecordModal.open}
            type="button"
            variant="contained"
            color="primary"
            sx={{
              whiteSpace: 'nowrap',
              textAlign: 'center',
              width: {
                xs: '100%',
              },
            }}
          >
            {t('callsSchedule.draftCollection.addRecordsManually')}
          </Button>
          {error && <p>{error}</p>}
        </Stack>
        {batchDraft.data?.files ? (
          <Box>
            {batchDraft.data.files.map((file) => (
              <Typography
                component={'p'}
                key={file.fileId}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: 'background.default',
                  borderRadius: '4px',
                  fontSize: '14px',
                  lineHeight: '17.57px',
                  padding: '4px 15px',
                  minHeight: '36px',
                  margin: 0,
                  '& + &': {
                    marginTop: '0.5rem',
                  },
                  '& > *': {
                    display: 'flex',
                  },
                }}
              >
                <Typography
                  component={'span'}
                  sx={{
                    flexGrow: 1,
                    paddingRight: '1.5rem',
                  }}
                >
                  {file.fileName}
                </Typography>
                <Typography
                  component={'span'}
                  onClick={() => handleRemoveFile(file.fileId)}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <DeleteOutline />
                </Typography>
              </Typography>
            ))}
          </Box>
        ) : null}
        <Box
          sx={{
            mt: 2,
          }}
        >
          <Typography
            variant={'h4'}
            sx={{
              fontSize: '14px',
              lineHeight: '17.57px',
              fontWeight: 700,
            }}
          >
            {t('callsSchedule.draftCollection.settings')}
          </Typography>
          <Stack
            direction={'row'}
            gap={2}
            justifyContent={'space-between'}
            sx={{ marginTop: '1.5rem' }}
            flexWrap={'wrap'}
          >
            <Grid container spacing={2}>
              {data?.defaultParameters?.additionalParameters &&
                Object.entries(data?.defaultParameters?.additionalParameters || {}).map(([key, x]) => {
                  if (x.type === 'Boolean')
                    return (
                      <Grid
                        item
                        xs={12}
                        key={key}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant={'body1'}>{x.readableName}</Typography>
                        <Controller
                          name={`additionalParameters.${key}`}
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              disabled={insertDraftFile.isLoading || !data?.defaultParameters?.editable}
                              {...field}
                              checked={field.value as boolean}
                              onChange={() => {
                                handleUpdateBatchAdditionalParameters(key, !field.value);
                                field.onChange(!field.value);
                              }}
                            />
                          )}
                        />
                      </Grid>
                    );
                  if (x.type === 'DateTime') {
                    return (
                      <Grid item xs={12} key={key}>
                        <Controller
                          name={`additionalParameters.${key}`}
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                disabled={insertDraftFile.isLoading || !data?.defaultParameters?.editable}
                                name={key}
                                type={'datetime-local'}
                                value={value || ''}
                                onChange={onChange}
                                onBlur={() => {
                                  const date = new Date(value as string);
                                  if (date.toString() !== 'Invalid Date') {
                                    handleUpdateBatchAdditionalParameters(key, date.toISOString());
                                  }
                                }}
                                label={x.readableName || key}
                              />
                            );
                          }}
                        />
                      </Grid>
                    );
                  }

                  const type =
                    x.type === 'Double'
                      ? 'number'
                      : x.type === 'TimeOnly'
                      ? 'time'
                      : x.type === 'DateOnly'
                      ? 'date'
                      : 'text';
                  return (
                    <Grid item xs={12} key={key}>
                      <Controller
                        // defaultValue={type === 'date' ? reverseDateFormat(x.defaultValue as string) : x.defaultValue}
                        name={`additionalParameters.${key}`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            multiline={x.type === 'String'}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            disabled={insertDraftFile.isLoading || !data?.defaultParameters?.editable}
                            name={key}
                            type={type}
                            value={value || ''}
                            onChange={onChange}
                            onBlur={() => handleUpdateBatchAdditionalParameters(key, value)}
                            label={x.readableName || key}
                          />
                        )}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Stack>
        </Box>
        <Box
          sx={{
            mt: 2,
          }}
        >
          <Typography
            variant={'h4'}
            sx={{
              fontSize: '14px',
              lineHeight: '17.57px',
              fontWeight: 700,
            }}
          >
            {t('callsSchedule.draftCollection.schedule')}
          </Typography>
          <Stack
            direction={'row'}
            gap={2}
            justifyContent={'space-between'}
            sx={{ marginTop: '1.5rem' }}
            flexWrap={'wrap'}
            pb={2}
          >
            <Stack
              direction={'row'}
              gap={2}
              justifyContent={'space-between'}
              flexWrap={isMobile || isTablet ? 'wrap' : 'nowrap'}
            >
              {data?.defaultParameters?.delay && (
                <Controller
                  name="delay"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">{t('callsSchedule.draftCollection.minutes')}</InputAdornment>
                        ),
                      }}
                      disabled={insertDraftFile.isLoading || !data?.defaultParameters?.editable}
                      name="delay"
                      fullWidth={isMobile || isTablet}
                      type="text"
                      focused={true}
                      value={value || ''}
                      onChange={(e) => {
                        const regex = /^$|^[1-9]\d*$/;
                        if (regex.test(e.target.value)) {
                          onChange(e);
                        } else {
                          return;
                        }
                      }}
                      onBlur={() => handleUpdateBatchDelay(value)}
                      label={t('callsSchedule.draftCollection.delay')}
                    />
                  )}
                />
              )}
              {data?.defaultParameters?.repeat && (
                <Controller
                  name="repeat"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth={isMobile || isTablet}
                      disabled={insertDraftFile.isLoading || !data?.defaultParameters?.editable}
                      name="delay"
                      type="number"
                      inputMode="numeric"
                      value={value || ''}
                      onChange={(e) => {
                        const regex = /^$|^[1-9]\d*$/;
                        if (regex.test(e.target.value)) {
                          onChange(e);
                        } else {
                          return;
                        }
                      }}
                      focused={true}
                      onBlur={() => handleUpdateBatchRepeat(value)}
                      label={t('callsSchedule.draftCollection.repeat')}
                    />
                  )}
                />
              )}
            </Stack>

            <Stack
              flexDirection="column"
              justifyContent="start"
              alignItems="center"
              sx={{
                overflowY: 'auto',
                maxHeight: 300,
                width: '100%',
              }}
            >
              <Typography
                variant={'h5'}
                textAlign="start"
                sx={{
                  width: '100%',
                  fontSize: '14px',
                  lineHeight: '17.57px',
                  fontWeight: 700,
                }}
              >
                {t('callsSchedule.daysOfCalls')}
              </Typography>
              {watch('rangeDate')?.map((value, index) => {
                return (
                  <RangeTime
                    isEditable={data?.defaultParameters?.editable}
                    onRemove={() =>
                      removeCallsDateTime.mutate(
                        {
                          scenarioId: id,
                          batchId: draftId.value || '',
                          start: value.start,
                          stop: value.stop,
                        },
                        {
                          onSuccess: () => {
                            batchDraft.refetch().then((data) => {
                              setValue('rangeDate', data?.data?.parameters?.callsDateTimes || []);
                            });
                          },
                          onError: (err) => {
                            errorHandler(err);
                          },
                        },
                      )
                    }
                    key={index}
                    date={value}
                    sx={{
                      backgroundColor: index % 2 === 0 ? 'transparent' : 'rgba(0, 0, 0, 0.02)',
                    }}
                  />
                );
              })}
            </Stack>
            {data?.defaultParameters?.firstAvailableCallsDateTimes && (
              <Button
                disabled={insertDraftFile.isLoading || !data?.defaultParameters?.editable}
                onClick={rangeDateModal.open}
                type="button"
                variant="contained"
                fullWidth
              >
                {t('callsSchedule.draftCollection.addDateRange')}
              </Button>
            )}
          </Stack>
        </Box>
      </form>
      <AddRecordModal modalOpen={addRecordModal.isOpen} handleModalClose={addRecordModal.close} />
      <FileErrorsModal
        modalOpen={errorInsertFileModal.isOpen}
        handleModalClose={errorInsertFileModal.close}
        errors={insertDraftFile.error}
      />
      <RangeDateModal
        handleModalClose={() => {
          rangeDateModal.close();
          batchDraft?.refetch().then((data) => {
            setValue('rangeDate', data?.data?.parameters?.callsDateTimes || []);
          });
        }}
        refetch={() => batchDraft.refetch()}
        modalOpen={rangeDateModal.isOpen}
        scenarioId={id}
        batchId={batchDraft.data?.batchId || ''}
      />
    </Stack>
  );
};
