import React, { ReactNode } from 'react';
import { DeleteOutline } from '@mui/icons-material';
import { TableCell, TableRow, Typography } from '@mui/material';
import { useBatchDraft, useDraftRecords, useRemoveDraftRecord } from '../../hooks';
import { DraftRecordType } from '../../types';

type Props = DraftRecordType & { columns: string[] };

export const DraftPreviewItem: React.FC<Props> = ({ id, phoneNumber, parameters, columns }) => {
  const draftRecords = useDraftRecords();
  const batchDraft = useBatchDraft();
  const { mutate } = useRemoveDraftRecord();

  const handleRemoveDraftRecord = (id: string) => {
    mutate(
      { recordId: id },
      {
        onSuccess: () => {
          draftRecords.refetch();
          batchDraft.refetch();
        },
      },
    );
  };

  return (
    <TableRow
      sx={{
        '&:last-of-type': {
          '& .MuiTableCell-root': {
            borderBottom: 'none',
          },
        },
      }}
    >
      <TableCell
        sx={{
          padding: '3px 5px',
        }}
      >
        {phoneNumber}
      </TableCell>
      {columns.map((column, index) => {
        if (!parameters[column])
          return (
            <TableCell
              sx={{
                padding: '3px 5px',
              }}
              key={index}
            />
          );

        return (
          <TableCell
            sx={{
              padding: '3px 5px',
            }}
            key={index}
          >
            {parameters[column] as ReactNode}
          </TableCell>
        );
      })}
      <TableCell
        sx={{
          padding: '3px 5px',
        }}
      >
        <Typography
          component={'span'}
          onClick={() => handleRemoveDraftRecord(id)}
          sx={{
            cursor: 'pointer',
          }}
        >
          <DeleteOutline />
        </Typography>
      </TableCell>
    </TableRow>
  );
};
