import React from 'react';
import { FieldError } from 'react-hook-form';
import { TextField } from '@mui/material';

type InputTextProps = {
  name: string;
  value: string;
  label?: string;
  type?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  error: FieldError | undefined;
  margin?: 'none' | 'dense' | 'normal' | undefined;
  fullWidth?: boolean;
  width?: string;
  multiline?: boolean;
  shrink?: boolean;
  required?: boolean;
  disabled?: boolean;
};

export const InputText: React.FC<InputTextProps> = (props) => {
  return (
    <TextField
      disabled={props.disabled}
      fullWidth={!!props.fullWidth}
      multiline={props.multiline}
      label={props.label}
      value={props.value}
      variant={'outlined'}
      type={props.type ?? 'text'}
      margin={props.margin}
      name={props.name}
      onChange={(event) => props.onChange(event.target.value || '')}
      InputLabelProps={props.shrink ? { shrink: true } : undefined}
      onBlur={props.onBlur}
      error={!!props.error}
      helperText={props.error ? props.error.message : null}
      sx={{
        width: !props.fullWidth ? props.width : undefined,
        textFieldStyles: {
          '& label': { zIndex: 1 },
          '& label + div': { backgroundColor: 'common.white' },
        },
      }}
    />
  );
};
