import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoaderComponent } from '../../../../components/loader-component';
import { useDraftRecords } from '../../hooks';
import { DraftPreviewItem } from './draft-preview-item';
import { NewDraftButton } from './new-draft-button';
import { useScenariosParameters } from '../../../scenarios/hooks';

export const DraftCollectionPreview: React.FC = () => {
  const { t } = useTranslation();
  const draftRecords = useDraftRecords();
  const { data: scenarioParameters, isLoading: isScenarioParamsLoading } = useScenariosParameters();

  const parametersColumns =
    draftRecords.data?.records?.reduce<string[]>((acc, v) => {
      const a = new Set([...acc, ...(v.parameters ? Object.keys(v.parameters) : [])]);
      return Array.from(a) as string[];
    }, []) || [];

  return (
    <Stack
      sx={{
        marginTop: {
          xs: '10px',
          md: '69px',
        },
        width: '100%',
        maxWidth: {
          md: '58%',
          lg: '60%',
          xl: '70%',
        },
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        sx={{
          marginBottom: 4,
        }}
      >
        <NewDraftButton />
      </Stack>
      {draftRecords.isLoading || isScenarioParamsLoading || draftRecords.isFetching ? (
        <LoaderComponent />
      ) : (
        <TableContainer
          sx={{
            margin: 0,
            padding: 0,
            maxHeight: '450px',
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    padding: '3px 5px',
                  }}
                >
                  {t('callsSchedule.draftCollection.phoneNumber')}
                </TableCell>
                {parametersColumns.map((key, index) => (
                  <TableCell
                    sx={{
                      padding: '3px 5px',
                    }}
                    key={index}
                  >
                    <Tooltip title={scenarioParameters?.parameters[key].readableName || ''}>
                      <Box>
                        {(scenarioParameters?.parameters[key].readableName?.length || key) > 20
                          ? scenarioParameters?.parameters[key].readableName?.slice(0, 20) + '...'
                          : scenarioParameters?.parameters[key].readableName}
                      </Box>
                    </Tooltip>
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    padding: '3px 5px',
                  }}
                >
                  {t('callsSchedule.draftCollection.options')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {draftRecords.data?.records.map((record) => (
                <DraftPreviewItem
                  key={record.id}
                  id={record.id}
                  phoneNumber={record.phoneNumber}
                  parameters={record.parameters}
                  columns={parametersColumns}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        spacing={1}
        sx={{
          width: '100%',
          marginTop: 4,
          flexWrap: 'wrap',
        }}
      >
        <Typography
          component={'span'}
          sx={{
            fontWeight: 'bold',
            width: '100%',
            marginBottom: '2rem',
          }}
        >
          {t('callsSchedule.draftCollection.numberOfAddedRecords')}: {draftRecords.data?.totalCount}
        </Typography>
      </Stack>

      <Button
        disabled={!draftRecords.data?.records.length}
        variant="contained"
        color="primary"
        type="submit"
        form="draft-collection-form"
        id="saveAndStartBatch"
        sx={{
          width: '40%',
          marginLeft: '60%',
        }}
      >
        {t('callsSchedule.draftCollection.startCollection')}
      </Button>
    </Stack>
  );
};
