import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { callingHttpClient } from '../../../common/utils';

type Data = {
  BatchId: string;
  scenarioId: string;
  newValue: number;
};

type Response = unknown;

export const useUpdateRepeatParameter = (): UseMutationResult<Response, MutationErrorType, Data> => {
  return useMutation(async (body) => {
    const response = await callingHttpClient.patch(`/Batch/${body.BatchId}/callsScheduleParameters/Repeat/Update`, {
      scenarioId: body.scenarioId,
      newValue: body.newValue,
    });
    return response.data;
  });
};
