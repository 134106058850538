import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { phoneHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';
import { HistoryContext } from '../provider/history-context.provider';
import { HistoryCallRecordType } from '../types';
import { getFiltersURLString } from '../utils/filters';

type ResponseType = {
  totalCount: number;
  callHistory?: HistoryCallRecordType[];
};

export const useCallHistory = (): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const { id: scenarioId } = useScenario();
  const historyContext = useContext(HistoryContext);

  return useQuery<ResponseType, Error>(
    [
      'call-history',
      scenarioId,
      historyContext.directions.value,
      historyContext.dateRange.value,
      historyContext.pageNumber.value,
    ],
    async () => {
      const filtersString = getFiltersURLString(historyContext);

      const response = await phoneHttpClient.get<ResponseType>(
        `/CallsHistories/${scenarioId}/getcallshistory?PageNumber=${
          historyContext.pageNumber.value
        }&PageSize=${10}${filtersString}`,
      );
      return response.data;
    },
    {
      enabled: authenticated,
    },
  );
};
