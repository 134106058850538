import { useTranslation } from 'react-i18next';

export enum CallsScheduleCollectionStatusEnum {
  ALL = 'All',
  PLANNED = 'Saved',
  IN_PROGRESS = 'Processing',
  DONE = 'Completed',
  CANCELED = 'Canceled',
}

export const CallsScheduleCollectionStatus: { value: CallsScheduleCollectionStatusEnum; name: string }[] = [
  {
    value: CallsScheduleCollectionStatusEnum.PLANNED,
    name: 'Zaplanowane dzwonienie',
  },
  {
    value: CallsScheduleCollectionStatusEnum.IN_PROGRESS,
    name: 'Obecnie realizowane',
  },
  {
    value: CallsScheduleCollectionStatusEnum.DONE,
    name: 'Zrealizowane dzwonienie',
  },
  {
    value: CallsScheduleCollectionStatusEnum.CANCELED,
    name: 'Anulowane dzwonienie',
  },
  {
    value: CallsScheduleCollectionStatusEnum.ALL,
    name: 'Wszystkie kolekcje',
  },
];

export const CallsScheduleSelectOptions = (): typeof CallsScheduleCollectionStatus => {
  const { t } = useTranslation();

  return [
    {
      value: CallsScheduleCollectionStatusEnum.PLANNED,
      name: t('callsSchedule.bar.selectOptions.planned'),
    },
    {
      value: CallsScheduleCollectionStatusEnum.IN_PROGRESS,
      name: t('callsSchedule.bar.selectOptions.inProgress'),
    },
    {
      value: CallsScheduleCollectionStatusEnum.DONE,
      name: t('callsSchedule.bar.selectOptions.done'),
    },
    {
      value: CallsScheduleCollectionStatusEnum.CANCELED,
      name: t('callsSchedule.bar.selectOptions.canceled'),
    },
    {
      value: CallsScheduleCollectionStatusEnum.ALL,
      name: t('callsSchedule.bar.selectOptions.all'),
    },
  ];
};
