import { useContext, useMemo } from 'react';
import { ScenarioContext } from '../provider';

type ReturnType = {
  id: string;
  name: string;
};

export const useScenario = (): ReturnType => {
  const scenarioContent = useContext(ScenarioContext);

  return useMemo(
    () => ({
      id: scenarioContent.id,
      name: scenarioContent.name,
    }),
    [scenarioContent.id, scenarioContent.name],
  );
};
