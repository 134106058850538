import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { BasicModal } from '../basic-modal';

type PropsType = {
  handleModalClose: () => void;
  handleConfirm: () => void;
  modalOpen: boolean;
  message: string;
  header: string;
};

export const ConfirmModal: React.FC<PropsType> = (props) => {
  const { t } = useTranslation();

  const submitAndReturn = () => {
    props.handleConfirm();
    props.handleModalClose();
  };

  const modalBody = (
    <Box>
      <Typography component={'p'}>{props.message}</Typography>
      <Stack direction={'row'} justifyContent={'flex-end'}>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          onClick={submitAndReturn}
          sx={{
            mt: 2,
          }}
        >
          {t('download')}
        </Button>
      </Stack>
    </Box>
  );

  return (
    <BasicModal
      title={props.header}
      modalBody={modalBody}
      handleModalClose={props.handleModalClose}
      modalOpen={props.modalOpen}
    />
  );
};
