import { RoutePathLink } from './utils';

export const routes = {
  home: new RoutePathLink('/'),
  users: new RoutePathLink('/users'),
  user: new RoutePathLink('/users/:id/'),
  scenario: {
    dashboard: new RoutePathLink('/scenario/:id/'),
    reports: new RoutePathLink('/scenario/:id/reports'),
    history: new RoutePathLink('/scenario/:id/history'),
    callsSchedule: {
      collectionList: new RoutePathLink('/scenario/:id/calls-schedule'),
      addCollection: new RoutePathLink('/scenario/:id/calls-schedule/new-collection'),
    },
    call: new RoutePathLink('/scenario/:id/call'),
    scenarioConfigurator: new RoutePathLink('/scenario/:id/configurator'),
    users: new RoutePathLink('/scenario/:id/users'),
  },
  recordingDownload: new RoutePathLink('/recordings/:id'),
};
