import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useScenario, useScenariosParameters } from '../../../scenarios/hooks';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BlockIcon from '@mui/icons-material/Block';
import { useCancelCallingRecord } from '../../hooks/use-start-immediate-calling-in-record';
import { useDeviceQuery } from '../../../../common/hooks';
import { useBatchRecordsQuery } from '../../hooks';

type Props = {
  recordId: string;
  phoneNumber: string;
  disabled: boolean;
  batchId?: string;
};

export const CancelCallingInRecord: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { id } = useScenario();
  const { mutate } = useCancelCallingRecord();
  const { isMobile, isDesktop } = useDeviceQuery();
  const scenarioParams = useScenariosParameters();
  const batchRecords = useBatchRecordsQuery(props.batchId || '');

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    mutate(
      {
        recordId: props.recordId,
        scenarioId: id,
      },
      {
        onSuccess: () => {
          batchRecords.refetch();
          scenarioParams.refetch();
          handleClose();
        },
      },
    );
  };

  return (
    <>
      <Button
        disabled={props.disabled}
        endIcon={<BlockIcon />}
        onClick={handleClickOpen}
        variant="outlined"
        sx={{
          minWidth: isDesktop ? 175 : isMobile ? 50 : 0,
          '& .MuiButton-startIcon': {
            margin: 0,
          },
          marginBottom: 0.5,
          marginLeft: 1,
        }}
      >
        {isDesktop ? t('callsSchedule.records.immediateCalling.cancelSession') : ''}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 900 }}>
          {t('callsSchedule.records.cancelSession.title', { phoneNumber: props.phoneNumber })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('callsSchedule.records.cancelSession.description', {
              phoneNumber: props.phoneNumber,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <br />
          <Button onClick={handleClose}>{t('callsSchedule.records.cancelSession.cancel')}</Button>
          <Button onClick={handleConfirm} autoFocus>
            {t('callsSchedule.records.cancelSession.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
