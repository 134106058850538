import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { callingHttpClient } from '../../../common/utils';
import { DateRangeType } from '../types/default-parameters';

type Data = {
  batchId: string;
  ranges: DateRangeType[];
  scenarioId: string;
};

type Response = unknown;

export const useAddCalsDateTime = (): UseMutationResult<Response, MutationErrorType, Data> => {
  return useMutation(async (body) => {
    const response = await callingHttpClient.post(
      `/Batch/${body.batchId}/callsScheduleParameters/CallsDateTime/AddMany`,
      {
        scenarioId: body.scenarioId,
        ranges: body.ranges,
      },
    );
    return response.data;
  });
};
