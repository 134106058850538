import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { callingHttpClient } from '../../../common/utils';
import { DraftRecordType } from '../types';

type Response = null;

type Body = {
  scenarioId: string;
  records: DraftRecordType[];
};

export const useRecordInsertDraft = (): UseMutationResult<Response, MutationErrorType, Body> => {
  return useMutation(async (body) => {
    const response = await callingHttpClient.post('/Record/InsertDraft', body);
    return response.data;
  });
};
