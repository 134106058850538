export const pl = {
  yes: 'Tak',
  no: 'Nie',
  of: 'z',
  clear: 'Wyczyść',
  confirm: 'Potwierdź',
  apply: 'Zatwierdź',
  copy: 'Kopiuj',
  copied: 'Skopiowano',
  saveChanges: 'Zapisz zmiany',
  ok: 'OK',
  cancel: 'Anuluj',
  noData: 'Brak danych',
  download: 'Pobierz',
  key: 'Klucz',
  value: 'Wartość',
  noAccess: 'Brak dostępu',
  noPermission: 'Brak uprawnień dostępu do tej sekcji',
  edit: 'Edytuj',
  delete: 'Usuń',
  search: 'Szukaj',
  add: 'Dodaj',
  added: 'Dodano',
  from: 'Od',
  to: 'Do',
  skipWeekends: 'Pomiń weekendy',
  next: 'dalej',
  back: 'wstecz',

  dayOfWeek: {
    0: 'Niedziela',
    1: 'Poniedziałek',
    2: 'Wtorek',
    3: 'Środa',
    4: 'Czwartek',
    5: 'Piątek',
    6: 'Sobota',
  },

  datePicker: {
    dateFrom: 'Data od',
    dateTo: 'Data do',
  },

  home: {
    welcome: 'Cześć,',
    chooseScenario: 'Wybierz scenariusz, aby rozpocząć',
  },

  users: {
    title: 'Użytkownicy',
    link: 'Zarządzanie użytkownikami',
    options: 'Opcje',
    addUser: 'Stwórz nowego użytkownika',
    addExistingScenarioUser: 'Przypisz istniejącego użytkownika',
    addExistingUserToScenario: 'Przypisz istniejącego użytkownika do scenariusza',
    chooseRole: 'Rola użytkownika w scenariuszu',
    user: {
      userData: 'Dane użytkownika',
      scenarioGroups: 'Grupy scenariuszy',
      profile: 'Profil użytkownika',
      active: 'Aktywny',
      inactive: 'Nieaktywny',
      id: 'ID',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      username: 'Nazwa użytkownika',
      role: 'Rola',
      email: 'E-mail',
      enabled: 'Aktywne konto',
      emailVerified: 'Zweryfikowano',
      group: 'Rola użytkownika w scenariuszu',
      scenario: 'Scenariusz',
      scenarios: {
        showGroups: 'Kliknij w scenariusz żeby wyświetlić grupy',
      },
      clickToChangeStatus: 'Kliknij, żeby zmienić status',
    },
    roles: {
      user: 'Użytkownik',
      admin: 'Administrator',
    },
    noResults: 'Brak wyników',
    error: 'Wystąpił błąd podczas pobierania danych',
    deleteUserTitle: 'Usuń użytkownika',
    deleteUserMessage: 'Czy na pewno chcesz usunąć tego użytkownika? Ta operacja jest nieodwracalna.',
    deleteUserFromGroupTitle: 'Usuń użytkownika ze scenariusza',
    deleteUserFromGroupMessage: 'Czy na pewno chcesz usunąć tego użytkownika z danego scenariusza?',
    details: 'Szczegóły',
    deleteFromGroup: 'Usuń ze scenariusza',
    edit: 'Edytuj',
    selectScenario: 'Wybierz scenariusz',
    allUsers: 'Wszyscy użytkownicy',
    snackbar: {
      successfullyCreated: 'Pomyślnie dodano użytkownika',
      successfullyUpdated: 'Pomyślnie zaktualizowano dane użytkownika',
      successfullyDeleted: 'Pomyślnie usunięto użytkownika',
      successfullyDeletedFromGroup: 'Pomyślnie usunięto użytkownika ze scenariusza',
      successfullyEnabledDisabled: 'Pomyślnie zmieniono status użytkownika',
      successfullyEditedGroup: 'Pomyślnie edytowano przypisanie użytkownika do grupy',
      failedToCreate: 'Nie udało się dodać użytkownika',
      failedToUpdate: 'Nie udało się zaktualizować danych użytkownika',
      failedToDelete: 'Nie udało się usunąć użytkownika',
      failedToDeleteFromGroup: 'Nie udało się usunąć użytkownika ze scenariusza',
      failedToEnableDisable: 'Nie udało się zmienić statusu użytkownika',
      failedToEditGroup: 'Nie udało się zmienić grupy użytkownika',
      resetPasswordRequestSuccess: 'Pomyślnie wysłano żądanie zmiany hasła',
      resetPasswordRequestError: 'Nie udało się wysłać żądania',
    },
    resetPassword: 'Zażądaj zmiany hasła',
  },

  dashboard: {
    heading: 'Dashboard',
    show: 'Pokaż',
    today: 'Ostatnie 24h',
    lastWeek: 'Ostatni tydzień',
    last7Days: 'Ostatnie 7 dni',
    lastMonth: 'Ostatni miesiąc',
    currentMonth: 'Aktualny miesiąc',
  },

  reports: {
    heading: 'Raporty',
    selectReport: 'Wybierz raport',
    selectBtn: 'Wybierz',
    chooseFileFormat: 'Wybierz format pliku',
    downloadBtn: 'Pobierz raport',
    defaultTitle: 'Uwaga!',
    defaultDateRange: 'Brak wybranego zakresu dat. Zostanie wygenerowany raport dla bieżącego dnia.',
    changedDateRange:
      'Wybrany zakres dat jest większy niż {{maxDateRange}} dni. Plik będzie zawierał raport z danymi z {{maxDateRange}} dni.',
    details: 'Szczególy',
    noResults: 'Brak wyników',
    noReports: 'Brak dostępnych raportów',
  },

  history: {
    heading: 'Historia połączeń',
    downloadBtn: 'Pobierz rekordy',
    connectionId: 'Id połączenia',
    direction: 'Kierunek',
    directionValues: {
      InboundCall: 'Przychodzące',
      OutboundCall: 'Wychodzące',
    },
    numberFrom: 'Numer od',
    numberTo: 'Numer do',
    status: 'Status',
    statusValues: {
      ReceivedCall: 'Odebrane',
      MissedCall: 'Nieodebrane',
      Unspecified: 'Nieznany',
    },
    reasonForNoResponse: 'Przyczyna nieodebrania',
    reasonForNoResponseValues: {
      Machine: 'Sekretarka',
      Missed: 'Odrzucone',
      Unknown: 'Inny (nieznany)',
    },
    duration: 'Czas trwania',
    recording: 'Nagranie',
    date: 'Data',
    hour: 'Godzina',
    recordingModal: {
      description: 'Odsłuchaj nagranie z połączenia',
      downloadRecording: 'Pobierz nagranie',
      noData: 'Brak nagrania do odsłuchania',
    },
    transcription: {
      heading: 'Transkrypcja z nagrania połączenia',
      noData: 'Transkrypcja z połączenia jest niedostępna',
      botsaid: 'HelloBot',
      humansaid: 'Rozmówca',
      noAnswer: 'Brak odpowiedzi',
      closeBtn: 'Zamknij',
      startedLabel: 'Rozpoczęto: ',
      endedLabel: 'Zakończono: ',
    },
    call: {
      details: 'Szczegóły połączenia',
      noDetails: 'Brak szczegółów połączenia',
    },
    defaultTitle: 'Uwaga!',
    defaultDateRange: 'Brak wybranego zakresu dat. Zostanie wygenerowana historia dla bieżącego dnia.',
    changedDateRange:
      'Wybrany zakres dat jest większy niż {{maxDateRange}} dni. Plik będzie zawierał historię z danymi z {{maxDateRange}} dni.',
    noResults: 'Brak wyników',
  },

  callsSchedule: {
    daysOfCalls: 'daty wydzwonień',
    heading: 'Plan wydzwonień',
    bar: {
      selectCollection: 'Wybierz kolekcje',
      selectOptions: {
        all: 'Wszystkie kolekcje',
        planned: 'Zaplanowane dzwonienie',
        inProgress: 'Obecnie realizowane',
        done: 'Zrealizowane dzwonienie',
        canceled: 'Anulowane dzwonienie',
      },
      showBtn: 'Pokaż',
      addBtn: 'Dodaj kolekcję',
    },
    list: {
      selectCollections: 'Wybierz kolekcje do wyświetlenia',
      noCollections: 'Brak odpowiadających kolekcji',
    },
    collection: {
      status: 'Status',
      nextCallDate: 'Najbliższy termin dzwonienia',
      calledRecords: 'Ilość rekordów',
      start: 'Rozpocznij',
      stop: 'Zatrzymaj ',
      calling: 'przetwarzanie',
      immediateCallings: {
        title: 'Uwaga! Czy na pewno chcesz natychmiast zadzwonić do wszystkich rekordów tej kolekcji?',
        information:
          'Potwierdzenie spowoduje wykonanie połączenia telefoniczonego do {{immediateCallingsCount}} z ' +
          '{{recordsCount}} numerów tej kolekcji. Połączenia zostaną wykonane natychmiast!',
        description:
          'Osiągnięcie sukcesu w rozmowie spowoduje pomyślną realizację scenariusza i brak kolejnych połączeń. ' +
          'Jeżeli sukces nie zostanie osiągnięty, kolekcja będzie pracowała według swojej standardowej konfiguracji.',
        cancel: 'Anuluj',
        confirm: 'Tak, zadzwoń',
        tooltip: 'Zadzwoń natychmiast',
      },
      collectionHistory: {
        title: 'Historia kolekcji',
        tooltip: 'Historia kolekcji',
        unknown: 'Status nieznany',
        unknownDescription: 'coś poszło nie tak.. Zgłoś sprawę do supportu HelloBot!',
        drafted: 'Utworzono szkic',
        draftedDescription: 'kolekcja została utworzona i oczekuje na zapisanie',
        saved: 'Zapisano',
        savedDescription: 'kolekcja została zapisana i oczekuje na uruchomienie',
        started: 'Włączono',
        startedDescription: 'kolekcja została uruchomiona, rekordy zleciły utworzenie sesji',
        processing: 'Przetwarzanie rekordów',
        processingDescription: 'sesje rekordów rozpoczęły działanie, trwa wydzwanianie',
        completed: 'Przetwarzanie zakończone',
        completedDescription: 'sukces! Wszystkie rekordy zakończyły działanie',
        canceled: 'Anulowano',
        canceledDescription: 'kolekcja została anulowana na życzenie użytkownika',
        faulted: 'Błąd!',
        faultedDescription: 'wystąpił nieoczekiwany błąd! Zgłoś sprawę do supportu HelloBot!',
      },
      recordHistory: {
        buttonTitle: 'Historia',
        title: 'Historia rekordu - {{phoneNumber}}',
        tooltip: 'Historia rekordu',
        unknown: 'Status nieznany',
        unknownDescription: 'coś poszło nie tak.. Zgłoś sprawę do supportu HelloBot!',
        drafted: 'Utworzono szkic',
        draftedDescription: 'rekord został utworzony i oczekuje na zapisanie kolekcji',
        saved: 'Zapisano',
        savedDescription: 'rekord został zapisany i oczekuje na uruchomienie',
        processingrequested: 'Włączono',
        processingrequestedDescription: 'rekord został uruchomiony i zlecił utworzenie sesji',
        processing: 'Przetwarzanie',
        processingDescription: 'sesje rozpoczęła działanie, scenariusz trwa',
        incall: 'Rozmowa telefoniczna',
        incallDescription: 'połączenie telefoniczne zostało zrealizowane',
        waiting: 'Przetwarzanie',
        waitingDescription: 'oczekiwanie na kolejne wydzwonienia',
        completed: 'Przetwarzanie zakończone',
        completedDescription: 'sukces! Rekord zakończył działanie',
        cancelingrequested: 'Anulowano',
        cancelingrequestedDescription: 'rekord zostanie anulowany na życzenie użytkownika',
        canceled: 'Rekord anulowany',
        canceledDescription: 'pomyślnie anulowano przetwarzanie rekordu',
        faulted: 'Błąd!',
        faultedDescription: 'wystąpił nieoczekiwany błąd! Zgłoś sprawę do supportu HelloBot!',
      },
      editOptions: {
        editOptionsTooltip: 'Dodatkowe opcje',
        changeName: 'Zmień nazwę',
        cancelCollection: 'Anuluj kolekcję',
        duplicateCollection: 'Zduplikuj kolekcję',
      },
      cancelCollectionModal: {
        title: 'Czy na pewno chcesz anulować kolekcję?',
        info: 'Kolekcja nie zostanie wydzwoniona',
      },
      editCollectionModal: {
        title: 'Zmień nazwę kolekcji',
      },
      duplicateCollectionModal: {
        title: 'Czy na pewno chcesz zduplikować kolekcję?',
        info: 'Utworzenie duplikatu spowoduje usunięcie zapisanej kopii roboczej kolekcji',
      },
      startCallingModal: {
        title: 'Czy na pewno chcesz rozpocząć dzwonienie?',
      },
      import: {
        title: 'Wystąpił problem podczas importu pliku',
        numberErrors: 'Błędy numerów telefonów',
        id: 'Identyfikator',
        column: 'Kolumna',
        index: 'Index',
        error: 'Błąd',
        errorDictionary: {
          noValues: 'Brak wartości',
          incorrectType: 'Błędny typ',
          incorrectValue: 'Błędna wartość',
        },
        additionalParametersErrors: 'Błędy dodatkowych parametrów',
      },
    },
    collectionStatus: {
      unknown: 'Status nieznany',
      drafted: 'Szkic - kolekcja gotowa do zapisania',
      saved: 'Zapisano - czeka na uruchomienie',
      started: 'Kolekcja w trakcie uruchomienia',
      processing: 'Kolekcja przetwarzana, proces w toku',
      completed: 'Przetwarzanie zakończone pomyślnie ',
      canceled: 'Anulowano przetwarzanie kolekcji',
      faulted: 'Błąd!',
    },
    draftCollection: {
      successSavedAndStartAlert: 'Kolekcja {{batchName}} została zapisana i uruchomiona.',
      schedule: 'Harmonogram wydzwonień',
      addNewDateRangeError: 'Nie można dodać nowego zakresu dat. Aktualny dzień musi być z 30 minutowym wyprzedzeniem.',
      delay: 'Czas pomiedzy wydzwonieniami',
      minutes: 'minuty',
      repeat: 'Ilość prób wydzwonień',
      addDateRange: 'Dodaj zakres dat',
      settings: 'Ustawienia parametrów',
      addNewDateRange: 'Dodaj nowy zakres wydzwonień',
      addNewDateRangeDescription: 'Ustaw zakres dat i godzin w których mają być wykonywane połączenia',
      addCollection: 'Dodaj kolekcję',
      collectionName: 'Nazwa kolekcji',
      addRecords: 'Dodaj rekordy',
      importFile: 'Importuj plik',
      downloadTemplate: 'Pobierz szablon',
      addRecordsManually: 'Dodaj rekordy ręcznie',
      createCollection: 'Zapisz kolekcję',
      startCollection: 'Uruchom kolekcję',
      clearCollection: 'Wyczyść kolekcję',
      numberOfAddedRecords: 'Liczba dodanych rekordów',
      phoneNumber: 'Numer telefonu',
      options: 'Opcje',
      errors: {
        saveCollectionError: 'Wystąpił błąd podczas wyboru opcji zapisu.',
        successSaved: 'Kolekcja została zapisana',
        requiredParameterField: 'Pole wymagane',
        cannotRemoveLastDateRange: 'Kolekcja musi mieć przynajmniej jeden zakres dat',
        enterCollectionName: 'Podaj nazwę kolekcji',
        maximumRecordExceeded: 'Przekroczono limit rekordów (250)',
        wrongFileFormat: 'Zły format pliku',
        fileReadingError: 'Błąd odczytu pliku',
      },
    },
    records: {
      noRecords: 'Brak rekordów do wyświetlenia',
      phone: 'Nr telefonu',
      registration: 'Nr rejestracji',
      name: 'Nazwa firmy',
      visitDate: 'Termin wizyty',
      status: 'Status',
      preview: 'Podgląd',
      edit: 'Edytuj',
      cancel: 'Anuluj',
      options: 'Opcje',
      parameters: 'Parametry',
      parametersTooltip: 'Pokaż szczegóły parameterów rekordu',
      cancelSession: {
        title: 'Anuluj sesję rekordu - {{phoneNumber}}',
        description: 'Czy na pewno chcesz anulować sesję rekordu {{phoneNumber}}?',
        cancel: 'Nie',
        confirm: 'Tak, anuluj',
      },
      immediateCalling: {
        title: 'Uwaga! Czy na pewno chcesz natychmiast zadzwonić na numer {{phoneNumber}}?',
        description:
          'Potwierdzenie spowoduje wykonanie połączenia telefonicznego. Połączenie zostanie wykonane natychmiast! ' +
          'Osiągnięcie sukcesu w rozmowie spowoduje pomyślną realizację scenariusza i brak kolejnych połączeń. ' +
          'Jeżeli sukces nie zostanie osiągnięty, rekord będzie pracowała według swojej standardowej konfiguracji.',
        cancel: 'Anuluj',
        confirm: 'Tak, zadzwoń',
        tooltip: 'Zadzwoń natychmiast',
        callNow: 'Zadzwoń teraz',
        cancelSession: 'Anuluj sesję',
      },
    },
    record: {
      requiredParameters: 'Parametry wymagane',
      additionalParameters: 'Parametry dodatkowe',
      schedule: 'Harmonogram',
      details: 'Parametry rekordu',
      phoneNumber: 'Numer telefonu',
      addRecordToCollection: 'Dodaj rekord do kolekcji',
      addNextRecord: 'Dodaj następny',
      addRecordAndGoToList: 'Dodaj i przejdź do listy',
    },
    recordStatus: {
      unknown: 'Nieznany',
      drafted: 'Szkic - gotowy do zapisania',
      saved: 'Zapisany - gotowy do uruchomienia',
      processingrequested: 'Przetwarzanie zakolejkowane',
      processing: 'Przetwarzany',
      incall: 'Trwa połączenie telefoniczne',
      waiting: 'Oczekiwanie na wydzownienie',
      completed: 'Zakończony',
      cancelingrequested: 'Anulowanie zakolejkowane',
      canceled: 'Anulowany',
      faulted: 'Błąd!',
    },
  },

  call: {
    heading: 'Wydzwonienie natychmiastowe',
    add: 'Dodaj wydzwonienie',
    call: 'Zadzwoń',
    pastCalls: 'Historia wydzwonień',
    callId: 'Id',
    callDate: 'Data',
    callStatus: 'Status',
    phoneNumber: 'Numer telefonu',
    details: 'Szczegóły',
  },

  scenarioConfigurator: {
    heading: 'Konfigurator scenariuszy',
    collectionType: {
      editable: 'Całkowita edycja',
      editableValues: 'Edycja wartości',
      static: 'Tylko do odczytu',
      hidden: 'Ukryte',
    },
    save: 'Zapisz',
    edit: 'Edytuj',
    delete: 'Usuń',
    noValues: 'Brak wyników',
    noCollections: 'Brak kolekcji dla scenariusza',
    createFeedback: {
      success: 'Pomyślnie dodano nowy element',
      error: 'Nie udało się dodać nowego elementu',
    },
    editFeedback: {
      success: 'Pomyślnie zmodyfikowano scenariusz',
      error: 'Nie udało się zmodyfikować konfiguracji scenariusza',
    },
    deleteFeedback: {
      success: 'Pomyślnie usunięto element',
      error: 'Nie udało się usunąć elementu',
    },
    newDocument: 'Nowy dokument',
    addNewDocument: 'Dodaj nowy dokument',
  },

  scenarioUsers: {
    heading: 'Użytkownicy scenariusza',
    createNewScenarioUser: 'Stwórz nowego użytkownika',
    noScenarioGroupsError:
      'Nieskonfigurowany scenariusz. Żeby dodać użytkownika muszą zostać zdefiniowane role scenariusza.',
    editUser: 'Edycja użytkownika',
  },

  navbar: {
    change: 'Zmień',
    logout: 'Wyloguj',
  },

  footer: {
    contact: 'Kontakt',
  },

  selectLang: 'Wybierz język',

  validation: {
    required: 'Pole wymagane',
    invalidEmail: 'Błędny adres e-mail. Przykład: example@example.com',
    minLength: 'Minimalna ilość znaków nie została spełniona',
  },
  errorCode: {
    start_stop_range_already_exists: 'Nie można dodać zakresu dat, który już istnieje',
    something_went_wrong: 'Coś poszło nie tak',
    entity_not_found: 'Nie znaleziono zasobu',
    columns_type_mismatch: 'Błąd typu kolumny',
    report_id_and_mapping_mismatch: 'Błąd mapowania',
    email_notification_failure: 'Błąd wysyłania powiadomienia',
    report_view_previous_version_not_found: 'Nie znaleziono poprzedniej wersji raportu',
    object_already_exists: 'Obiekt już istnieje',
    object_not_found: 'Nie znaleziono obiektu',
    object_access_denied: 'Brak wymaganych uprawnień',
    report_view_already_exists: 'Widok raportu już istnieje',
    column_name_mismatch: 'Błąd nazwy kolumny',
    call_orchestrator_not_found: 'Nie znaleziono orchestratora',
    call_already_exists: 'Połączenie już istnieje',
    call_orchestrator_already_exists: 'Orchestrator połączeń już istnieje',
    call_orchestrator_has_active_calls: 'Orchestrator połączeń ma aktywne połączenia',
    max_calls_too_low: 'Przekroczono maksymalną ilość połączeń',
    min_inbound_calls_too_low: 'Przekroczono minimalną ilość połączeń przychodzących',
    max__redirect_calls_too_low: 'Przekroczono maksymalną ilość przekierowań',
    redirect_inbound_calls_sum_max_mismatch: 'Błąd sumy maksymalnej ilości połączeń przychodzących i przekierowań',
    call_date_times_missing_dates: 'Brak daty połączenia',
    value_null_or_empty: 'Wartość nie może być pusta',
    created_by_value_mismatch: 'Błąd wartości twórcy',
    daily_calls_plan_missing_days: 'Brak dni planu połączeń',
    delay_out_of_range: 'Błąd zakresu opóźnienia',
    guid_null_or_empty: 'GUID nie może być pusty',
    invalid_time_zone_id: 'Błędny identyfikator strefy czasowej',
    phone_number_column_null_or_empty: 'Numer telefonu nie może być pusty',
    phone_number_null_or_empty: 'Numer telefonu nie może być pusty',
    record_status_mismatch: 'Błąd statusu rekordu',
    record_parameters_null_or_empty: 'Parametry rekordu nie mogą być puste',
    repeat_value_out_of_range: 'Błąd zakresu ilości prób wydzwonienia',
    start_stop_date_mismatch: 'Błąd zakresu daty rozpoczęcia i zakończenia',
    start_stop_date_close_range_mismatch: 'Czas rozpoczęcia musi być z przynajmniej 30 minutowym wyprzedzeniem',
    records_to_start_not_found: 'Nie znaleziono rekordów do rozpoczęcia',
    batch_cancellation_status_mismatch: 'Błąd statusu anulowania kolekcji',
    batch_immediate_start_status_mismatch: 'Błąd statusu natychmiastowego rozpoczęcia kolekcji',
    batch_for_record_not_found: 'Nie znaleziono kolekcji dla rekordu',
    parameter_parsing_failure: 'Błąd parsowania parametrów',
    default_parameters_null_or_empty: 'Parametry domyślne nie mogą być puste',
    draft_batch_already_exists: 'Szkic kolekcji już istnieje',
    draft_batch_not_found: 'Nie znaleziono szkicu kolekcji',
    immediate_call_not_supported: 'Natychmiastowe wydzwonienie nie jest obsługiwane',
    record_immediate_calling: 'Rekord jest w trakcie natychmiastowego wydzwonienia',
    records_number_out_of_range: 'Błąd zakresu ilości rekordów',
    start_time_out_of_range: 'Błąd zakresu godziny rozpoczęcia',
    file_records_not_found: 'Nie znaleziono rekordów w pliku',
    batch_update_failure: 'Błąd aktualizacji kolekcji',
    file_upload_failure: 'Błąd przesyłania pliku',
    validation_error: 'Błąd walidacji',
  },
};
