import React from 'react';
import { Grid, Skeleton } from '@mui/material';

export const ScenariosLoadingSkeleton: React.FC = () => {
  const gridItemsPlaceholder = () => {
    const items = [];

    for (let i = 0; i <= 12; i++) {
      items.push(
        <Grid
          key={i}
          item
          xs={12}
          sm={6}
          lg={4}
          xl={3}
          sx={{
            minHeight: '120px',
          }}
        >
          <Skeleton height={144} />
        </Grid>,
      );
    }

    return items;
  };

  return <>{gridItemsPlaceholder()}</>;
};
