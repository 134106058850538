import { BackdropClasses, BackdropProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const backdropStyles: {
  defaultProps?: Partial<BackdropProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof BackdropClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      backdropFilter: 'blur(2px)',
      transition: '0.1s',
    },
  },
};
