import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Legend, Pie, PieChart, PieLabel, ResponsiveContainer } from 'recharts';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { useDeviceQuery } from '../../../common/hooks';
import { theme } from '../../../theme';
import { useCallGetAllWidgets } from '../hooks/use-call-getallwidgets.query';
import { DashboardChartDataType } from '../types';

const COLORS = [
  theme.palette.primary.main,
  theme.palette.secondary.main,
  theme.palette.error.main,
  theme.palette.warning.main,
  theme.palette.info.main,
  theme.palette.success.main,
];

const RADIAN = Math.PI / 180;

const customLabel: PieLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent === 0) {
    return null;
  }

  return (
    <text x={x} y={y} fill={theme.palette.common.white} textAnchor="middle" dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const mapWidget = (widgetData: DashboardChartDataType): ChartDataType => ({
  name: widgetData.label,
  value: Number(widgetData.value),
});
type ChartDataType = {
  name: string;
  value: number;
};

export const ChartWidget: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceQuery();
  const { data } = useCallGetAllWidgets();

  return (
    <Grid item xs={12} lg={5}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          backgroundColor: 'background.paper',
          padding: 3,
        }}
      >
        <Typography fontSize={'4rem'} lineHeight={'5rem'} component="h4">
          {data?.chart.value}
        </Typography>
        <Typography mt={'1.5rem'} fontSize={'1.5rem'} lineHeight={'1.89rem'} component="h4">
          {data?.chart.label}
        </Typography>
        <Stack
          direction={'row'}
          justifyContent={'center'}
          sx={{
            mt: 2,
            height: {
              xs: 320,
              sm: 208,
            },
            width: {
              xs: 200,
              sm: 500,
            },
          }}
        >
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={
                  data?.chart.data.length
                    ? data?.chart.data.map(mapWidget)
                    : [
                        {
                          name: t('noData'),
                          value: 100,
                        },
                      ]
                }
                labelLine={false}
                isAnimationActive={false}
                label={data?.chart.data.length ? customLabel : false}
                fill="#8884d8"
                dataKey="value"
                innerRadius={Math.round(52 / (isMobile ? 1.3 : 1))}
                outerRadius={Math.round(104 / (isMobile ? 1.3 : 1))}
              >
                {data?.chart.data.length ? (
                  data?.chart.data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))
                ) : (
                  <Cell key={`cell-0`} fill={theme.palette.grey.A200} />
                )}
              </Pie>
              <Legend
                layout="vertical"
                verticalAlign={isMobile ? 'bottom' : 'middle'}
                align={isMobile ? 'center' : 'right'}
                formatter={(value) => (
                  <Box component="span" sx={{ color: 'primary.main', verticalAlign: 'middle' }}>
                    {value}
                  </Box>
                )}
                iconType="square"
                iconSize={15}
              />
            </PieChart>
          </ResponsiveContainer>
        </Stack>
      </Card>
    </Grid>
  );
};
