import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, Stack, SxProps, Typography } from '@mui/material';

type Props = {
  date: Date;
  onRemove?: () => void;
  sx?: SxProps;
  time: {
    startTime: string;
    endTime: string;
  };
};

export const RangeDateItem: FC<Props> = (props: Props) => {
  const dayOfWeek = props.date.getDay();
  const { t } = useTranslation();
  const dayOfWeekName = t(`dayOfWeek.${dayOfWeek}`);
  return (
    <Stack
      sx={{
        width: 490,
        ...props.sx,
      }}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack flexDirection="row" gap={1}>
        <Typography variant="body2" color="textSecondary">
          {dayOfWeekName} {props.date.toLocaleDateString()}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {props.time.startTime} - {props.time.endTime}
        </Typography>
      </Stack>
      <IconButton onClick={props.onRemove}>
        <DeleteOutlineIcon />
      </IconButton>
    </Stack>
  );
};
