import { useTranslation } from 'react-i18next';
import { MutationErrorType } from '../types';
import { useAlert } from './use-alert';
import { InsertFileErrorBody } from '../../modules/calls-schedule/types';

export const useErrorHandler = () => {
  const { t } = useTranslation();
  const { errorAlert } = useAlert();

  const handleError = (err: MutationErrorType | InsertFileErrorBody) => {
    const errorCode = err.response?.data?.errorCode || 'something_went_wrong';
    errorAlert(t(`errorCode.${errorCode}`) || t('callsSchedule.draftCollection.errors.saveCollectionError'));
  };

  return handleError;
};
