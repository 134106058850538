import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { callingHttpClient } from '../../../common/utils';
import { DraftRecordType } from '../types';

type ResponseType = {
  records: RecordType[];
};

export type RecordType = DraftRecordType & {
  batchId: string;
  status: string;
  immediateCalling: boolean;
  recordStatusHistory: RecordStatusHistory[];
};

export type RecordStatusHistory = {
  modifiedOn: string;
  status: string;
};

export const useBatchRecordsQuery = (batchId?: string): UseQueryResult<ResponseType, Error> => {
  return useQuery<ResponseType, Error>(
    ['batch-records', batchId],
    async () => {
      const response = await callingHttpClient.get<ResponseType>(`/Record/Batch/${batchId}`);
      return response.data;
    },
    {
      enabled: !!batchId,
      staleTime: 5000,
      refetchInterval: 10000,
    },
  );
};
