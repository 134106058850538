import { endOfDay, isToday, startOfDay } from 'date-fns';
import { FilterType } from '../../../common/enums/filters.enum';
import { ReportsContextType } from '../provider/reports-context.provider';

export const getFiltersURLString = ({
  dateRange,
  dateColumnName,
  distinctValues,
}: Partial<ReportsContextType>): string => {
  const searchParams = new URLSearchParams();

  if (dateRange?.value.from || dateRange?.value.to) {
    searchParams.append('filters[0].type', FilterType.DatePicker.toString());
    searchParams.append('filters[0].columnName', dateColumnName?.value || '');
  }

  if (dateRange?.value.from) {
    searchParams.append('filters[0].value.from', startOfDay(dateRange?.value.from || 0).toISOString());
  }

  if (dateRange?.value.to) {
    if (isToday(dateRange?.value.to)) {
      searchParams.append('filters[0].value.to', new Date().toISOString());
    } else {
      searchParams.append('filters[0].value.to', endOfDay(dateRange.value.to).toISOString());
    }
  }

  if (distinctValues?.value) {
    const index = searchParams.get('filters[0].type') ? 1 : 0;
    searchParams.append(`filters[${index}].type`, FilterType.Distinct.toString());
    searchParams.append(`filters[${index}].columnName`, 'status'); //TODO: dynamic
    searchParams.append(`filters[${index}].value.distinctValues`, `${distinctValues.value.join(',')}`);
  }

  return searchParams.toString() ? '&' + searchParams.toString() : '';
};
