import React from 'react';
import { Skeleton } from '@mui/material';

export const CollectionValuesJsonSkeletons: React.FC = () => {
  return (
    <>
      {Array.from(Array(5).keys()).map((key) => (
        <Skeleton key={key} height={56} />
      ))}
    </>
  );
};
