import React from 'react';
import { Skeleton, TableCell } from '@mui/material';

export const CollectionValuesTableSkeletons: React.FC = () => {
  return (
    <>
      {Array.from(Array(3).keys()).map((key) => (
        <TableCell
          key={key}
          sx={{
            '& .MuiTableCell-stickyHeader': {
              backgroundColor: 'background.paper',
            },
          }}
        >
          <Skeleton height={50} />
        </TableCell>
      ))}
    </>
  );
};
