import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, Stack } from '@mui/material';
import { SetValueType } from '../../../../common/hooks';
import { PopoverFilter } from '../../../../components/popover-filter';

type PropsType = {
  distinctValuesOptions: string[];
  distinctValues: SetValueType<string[] | undefined>;
};

const DistinctValuesFilter: React.FC<PropsType> = ({ distinctValuesOptions, distinctValues }) => {
  const stringArrayToObject = useCallback(
    (array: string[]) => {
      const initialObject = {};
      return array.reduce((obj: { [key: string]: boolean }, item) => {
        return {
          ...obj,
          [`${item}`]: true,
        };
      }, initialObject);
    },
    [distinctValuesOptions],
  );

  const [options, setOptions] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    setOptions(stringArrayToObject(distinctValuesOptions));
  }, [distinctValuesOptions]);

  const onAccept = () => {
    const filterOptions = Object.keys(options).filter((key) => options[key]);
    distinctValues.set(filterOptions.length === distinctValuesOptions.length ? undefined : filterOptions);
  };

  return (
    <PopoverFilter onAcceptClick={() => onAccept()}>
      <FormControl>
        <Stack>
          {distinctValuesOptions.map((option) => {
            return (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={options[option]}
                    onChange={() => setOptions({ ...options, [option]: !options[option] })}
                    name={option}
                  />
                }
                label={option}
              />
            );
          })}
        </Stack>
      </FormControl>
    </PopoverFilter>
  );
};

export default DistinctValuesFilter;
