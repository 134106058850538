import { useScenario } from './use-scenario';

import { useQuery, UseQueryResult } from 'react-query';
import { useAuth } from '../../auth';
import { callingHttpClient } from '../../../common/utils';

type TimeZone = {
  id: string;
  hasIanaId: boolean;
  displayName: string;
  standardName: string;
  daylightName: string;
  baseUtcOffset: string;
  supportsDaylightSavingTime: boolean;
};

type ResponseType = {
  timeZone: TimeZone;
  ianaTimeZoneId: string;
};

export const useTimezoneQuery = (): UseQueryResult<ResponseType, Error> => {
  const { id: scenarioId } = useScenario();
  const { tokenParsed } = useAuth();
  const tenantId = tokenParsed?.tenant_id ? tokenParsed?.tenant_id[0] : '';
  return useQuery<ResponseType, Error>(
    [scenarioId],
    async () => {
      const response = await callingHttpClient.get<ResponseType>(
        `/ScenarioConfiguration/${tenantId}/${scenarioId}/Timezone`,
      );
      return response.data;
    },
    {
      enabled: !!scenarioId,
      staleTime: 10000,
    },
  );
};
