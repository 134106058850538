import React from 'react';
import { SetValueType, useSetValue } from '../../../common/hooks/set-value.hook';

type BatchListPaginationContextType = {
  pageNumber: SetValueType<number>;
};

const initialData: BatchListPaginationContextType = {
  pageNumber: { set: () => null, value: 1 },
};
export const BatchListPaginationContext = React.createContext(initialData);

export const BatchListPaginationContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const pageNumber = useSetValue(initialData.pageNumber.value);

  const value: BatchListPaginationContextType = {
    pageNumber,
  };

  return <BatchListPaginationContext.Provider value={value}>{props.children}</BatchListPaginationContext.Provider>;
};
