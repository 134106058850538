import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { BasicModal } from '../../../../components/basic-modal';
import { IdCellContent } from '../../../../components/id-cell-content';
import { InsertFileErrorBody } from '../../types';

type PropsType = {
  handleModalClose: () => void;
  modalOpen: boolean;
  errors: InsertFileErrorBody | null;
};

export const FileErrorsModal: React.FC<PropsType> = (props) => {
  const { t } = useTranslation();
  const desktopFormWidth = 520;

  const errorDictionary: { [key: number]: string } = {
    0: t('callsSchedule.collection.import.errorDictionary.noValues'),
    1: t('callsSchedule.collection.import.errorDictionary.incorrectType'),
    2: t('callsSchedule.collection.import.errorDictionary.incorrectValue'),
  };

  const modalBody = (
    <Box
      sx={{
        padding: 2,
        width: {
          sm: desktopFormWidth,
        },
      }}
    >
      {!!props.errors?.response?.data?.phoneNumberErrors?.length && (
        <Box sx={{ marginBottom: 20 }}>
          <Box sx={{ marginBottom: 5 }}>
            <Typography variant="h6">{t('callsSchedule.collection.import.numberErrors')}</Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size="small">{t('callsSchedule.collection.import.id')}</TableCell>
                <TableCell size="small">{t('callsSchedule.collection.import.index')}</TableCell>
                <TableCell size="small">{t('callsSchedule.collection.import.error')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.errors?.response?.data?.phoneNumberErrors.map((x) => (
                <TableRow key={x.RecordId}>
                  <TableCell size="small">
                    <IdCellContent callId={x.RecordId} />
                  </TableCell>
                  <TableCell size="small">{x.RecordIndex}</TableCell>
                  <TableCell size="small">{errorDictionary[x.Error] || x.Error}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
      {!!props.errors?.response?.data?.parametersErrors?.length && (
        <Box>
          <Box sx={{ marginBottom: 5 }}>
            <Typography variant="h6">{t('callsSchedule.collection.import.additionalParametersErrors')}</Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size="small">{t('callsSchedule.collection.import.id')}</TableCell>
                <TableCell size="small">{t('callsSchedule.collection.import.column')}</TableCell>
                <TableCell size="small">{t('callsSchedule.collection.import.index')}</TableCell>
                <TableCell size="small">{t('callsSchedule.collection.import.error')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.errors?.response?.data?.parametersErrors.map((x) => (
                <TableRow key={x.RecordId}>
                  <TableCell size="small">
                    <IdCellContent callId={x.RecordId} />
                  </TableCell>
                  <TableCell size="small">{x.Column}</TableCell>
                  <TableCell size="small">{x.RecordIndex}</TableCell>
                  <TableCell size="small">{errorDictionary[x.Error] || x.Error}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );

  return (
    <BasicModal
      title={t('callsSchedule.collection.import.title')}
      modalBody={modalBody}
      handleModalClose={props.handleModalClose}
      modalOpen={props.modalOpen}
    />
  );
};
