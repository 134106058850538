import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { callingHttpClient } from '../../../common/utils';

type Body = {
  scenarioId: string;
};

type Response = unknown;

export const useRemoveDraft = (): UseMutationResult<Response, MutationErrorType, Body> => {
  return useMutation(async (body) => {
    const response = await callingHttpClient.delete('/Batch/RemoveDraft', { data: body });
    return response.data;
  });
};
