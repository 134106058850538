import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Button, FormGroup } from '@mui/material';
import { BasicModal } from '../../../../components/basic-modal';
import { BasicSkeletons } from '../../../../components/basic-skeletons';
import { InputText } from '../../../../components/inputs';
import { useScenario, useScenariosParameters } from '../../../scenarios/hooks';
import { useDraftRecords, useRecordInsertDraft } from '../../hooks';
import { v4 as uuid } from 'uuid';
import { ScenarioParameters } from '../../../../components/scenario-parameters/scenario-parameters';

type PropsType = {
  handleModalClose: () => void;
  modalOpen: boolean;
};

type FormData = {
  phoneNumber?: string;
  [key: string]: string | number | boolean | undefined | Date;
};

export const AddRecordModal: React.FC<PropsType> = (props) => {
  const { t } = useTranslation();
  const { id: scenarioId } = useScenario();
  const { data: scenarioParameters, isLoading: isScenarioParamsLoading } = useScenariosParameters();
  const form = useForm<FormData>();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isLoading: isRecordInsertLoading } = useRecordInsertDraft();
  const draftRecords = useDraftRecords();

  const resetFormData = () => {
    form.reset();
  };

  const submitAndAddNext = (formData: FormData) => {
    const phoneNumber = formData.phoneNumber || '';
    const convertedAllTypesToSting = Object.entries(formData).reduce((acc, [key, value]) => {
      acc[key] = value?.toString();
      return acc;
    }, {} as FormData);

    if (convertedAllTypesToSting.phoneNumber) {
      delete convertedAllTypesToSting.phoneNumber;
    }

    mutate(
      {
        scenarioId,
        records: [
          {
            id: uuid(),
            phoneNumber: phoneNumber,
            parameters: {
              ...(convertedAllTypesToSting as Record<string, string>),
            },
          },
        ],
      },
      {
        onSuccess: () => {
          resetFormData();
          draftRecords.refetch();
        },
        onError: async (error) => {
          enqueueSnackbar('Nie udało się dodać rekordu do kolekcji', { variant: 'error' });
          if (error.response.status === 400) {
            form.setError('phoneNumber', {
              message: 'Wpisane pole jest nieprawidłowe',
            });
          }
        },
      },
    );
  };

  const submitAndReturn = (formData: FormData) => {
    const convertedAllTypesToSting = Object.entries(formData).reduce((acc, [key, value]) => {
      acc[key] = value?.toString();
      return acc;
    }, {} as FormData);
    console.log(convertedAllTypesToSting);
    form.reset();
    submitAndAddNext(convertedAllTypesToSting);
    props.handleModalClose();
  };

  const modalBody = (
    <FormProvider {...form}>
      <form>
        {isScenarioParamsLoading || isRecordInsertLoading || !scenarioParameters ? (
          <BasicSkeletons number={3} />
        ) : (
          <Controller
            name="phoneNumber"
            control={form.control}
            defaultValue=""
            rules={{ required: t('validation.required') as string }}
            render={({ field, fieldState: { error } }) => (
              <InputText
                fullWidth
                margin={'dense'}
                label={t('callsSchedule.record.phoneNumber')}
                error={error}
                name={field.name}
                onChange={field.onChange}
                value={field.value || ''}
              />
            )}
          />
        )}
        <ScenarioParameters parameters={scenarioParameters?.parameters || null} fullWidth />

        <FormGroup
          row
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={form.handleSubmit(submitAndAddNext)}
            sx={{
              marginTop: 3,
            }}
          >
            {t('callsSchedule.record.addNextRecord')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            onClick={form.handleSubmit(submitAndReturn)}
            sx={{
              marginTop: {
                xs: 1,
                sm: 3,
              },
            }}
          >
            {t('callsSchedule.record.addRecordAndGoToList')}
          </Button>
        </FormGroup>
      </form>
    </FormProvider>
  );

  return (
    <BasicModal
      title={t('callsSchedule.record.addRecordToCollection')}
      modalBody={modalBody}
      handleModalClose={props.handleModalClose}
      modalOpen={props.modalOpen}
    />
  );
};
