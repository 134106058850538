import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { PlayCircleOutline } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useModal } from '../../common/hooks';
import { useCallDetails } from '../../common/hooks/use-call-details.query';
import { downloadFileWithToken } from '../../common/utils';
import { AudioStreamPlayer } from '../audio-stream-player';
import { BasicModal } from '../basic-modal';
import { RoundedButton } from '../rounded-button';

type Props = {
  callId: string;
  disabled?: boolean;
};

export const RecordingModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const modal = useModal();

  const callDetails = useCallDetails(modal.isOpen ? props.callId : undefined);

  const recording = callDetails.data?.recordingUrl || '';
  const stream = callDetails.data?.streamUrl || '';

  const handleDownloadClick = useCallback(() => {
    const fileName = `${callDetails.data?.calleePhoneNumber}_${callDetails.data?.callerPhoneNumber}_${format(
      new Date(callDetails.data?.callStartDateTime || ''),
      'yyyy_MM_dd_HH_mm_ss',
    )}.wav`;
    downloadFileWithToken(recording, fileName);
  }, [callDetails.data]);

  const modalBody = (
    <Stack
      justifyContent={'space-between'}
      sx={{
        height: '100%',
      }}
    >
      <Stack rowGap={2}>
        <Typography variant="body1">{`ID: ${props.callId}`}</Typography>
      </Stack>

      {!stream && (
        <Box
          sx={{
            my: 4,
            mx: 0,
            textAlign: 'center',
          }}
        >
          <Typography variant="body2">{t('history.recordingModal.noData')}</Typography>
        </Box>
      )}

      {stream && <AudioStreamPlayer stream={stream} />}

      {recording && (
        <Stack direction={'row'} justifyContent={'flex-end'}>
          <Button
            onClick={handleDownloadClick}
            color="primary"
            variant="contained"
            sx={{
              color: 'common.white',
            }}
          >
            {t('history.recordingModal.downloadRecording')}
          </Button>
        </Stack>
      )}
    </Stack>
  );

  return (
    <>
      <RoundedButton icon={<PlayCircleOutline />} disabled={props.disabled} onClick={modal.open} />
      <BasicModal
        modalBody={modalBody}
        handleModalClose={modal.close}
        modalOpen={modal.isOpen}
        title={t('history.recordingModal.description')}
      />
    </>
  );
};
