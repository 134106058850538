import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup } from '@mui/material';
import { BasicModal } from '../../../../components/basic-modal';
import { useScenario } from '../../../scenarios/hooks';
import { useBatchesQuery, useCancelBatch } from '../../hooks';
import { BatchContext } from '../../provider';

type PropsType = {
  handleModalClose: () => void;
  modalOpen: boolean;
};

export const CancelCollectionModal: React.FC<PropsType> = (props) => {
  const { mutate } = useCancelBatch();
  const { id } = useScenario();
  const { t } = useTranslation();
  const batches = useBatchesQuery();
  const { batchId } = useContext(BatchContext);

  const handleClose = () => {
    props.handleModalClose();
  };

  const handleCancelCollection = () => {
    mutate(
      {
        batchId: batchId.value,
        scenarioId: id,
      },
      {
        onSuccess: () => {
          batches.refetch();
          props.handleModalClose();
        },
      },
    );
  };

  const modalBody = (
    <>
      <p>{t('callsSchedule.collection.cancelCollectionModal.info')}</p>
      <FormGroup
        row
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          columnGap: '2rem',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleCancelCollection}
          sx={{
            marginTop: 2,
            flexGrow: 1,
          }}
        >
          {t('yes')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          sx={{
            marginTop: 2,
            flexGrow: 1,
          }}
        >
          {t('no')}
        </Button>
      </FormGroup>
    </>
  );

  return (
    <BasicModal
      title={t('callsSchedule.collection.cancelCollectionModal.title')}
      modalBody={modalBody}
      handleModalClose={props.handleModalClose}
      modalOpen={props.modalOpen}
    />
  );
};
