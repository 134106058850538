import React from 'react';
import { SetValueType, useSetValue } from '../../../common/hooks/set-value.hook';

type BatchContextType = {
  batchId: SetValueType<string>;
  batchName: SetValueType<string>;
  callingInProgress: SetValueType<boolean>;
};

const initialData: BatchContextType = {
  batchId: { set: () => null, value: '' },
  batchName: { set: () => null, value: '' },
  callingInProgress: { set: () => null, value: false },
};
export const BatchContext = React.createContext(initialData);

export const BatchContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const batchId = useSetValue(initialData.batchId.value);
  const batchName = useSetValue(initialData.batchName.value);
  const callingInProgress = useSetValue(initialData.callingInProgress.value);

  const value: BatchContextType = {
    batchId: batchId,
    batchName: batchName,
    callingInProgress: callingInProgress,
  };

  return <BatchContext.Provider value={value}>{props.children}</BatchContext.Provider>;
};
