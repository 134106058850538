import React, { useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import { useCallGetAllWidgets } from '../hooks/use-call-getallwidgets.query';
import { ChartWidget } from './chart-widget';
import { DashboardCurrentCalls } from './dashboard-current-calls';
import { WidgetsContainer } from './widgets-container';

type GridIndexedWidgetsType = {
  [key: number]: { value: string };
};

export const DashboardContent: React.FC = () => {
  const { data } = useCallGetAllWidgets();
  const mapIndex = useCallback((index: number): number => (index - 1) * 2 - (index > 4 ? 7 : 0), []);
  const filteredWidgets = data?.widgets.filter((_, index) => index !== 0);
  const emptyArray = Array(8).fill({ value: '' });
  const gridIndexedWidgets = filteredWidgets?.reduce((acc, widget) => {
    acc[mapIndex(widget.index)] = widget;
    return acc;
  }, {} as GridIndexedWidgetsType);
  const widgets = emptyArray.map((item, index) => gridIndexedWidgets?.[index] || item);

  return (
    <Box
      sx={{
        my: 2,
        mx: {
          xs: 2.5,
          sm: 3,
        },
      }}
    >
      <DashboardCurrentCalls
        currentCallsAmount={Number(data?.widgets[0]?.value) || 0}
        label={data?.widgets[0]?.label || ''}
      />
      <Box>
        <Grid container spacing={2}>
          <ChartWidget />
          <WidgetsContainer widgets={widgets} />
        </Grid>
      </Box>
    </Box>
  );
};
