import { endOfDay, isToday, startOfDay } from 'date-fns';
import { Directions } from '../enums/directions.enum';
import { HistoryContextType } from '../provider/history-context.provider';

export const getFiltersURLString = ({ dateRange, directions }: Partial<HistoryContextType>): string => {
  let dateQueryFilter = '';

  if (dateRange?.value.from) {
    dateQueryFilter += `&DateTimeFrom=${startOfDay(dateRange.value.from).toISOString()}`;
  }

  if (dateRange?.value.to) {
    if (isToday(dateRange.value.to)) {
      dateQueryFilter += `&DateTimeTo=${new Date().toISOString()}`;
    } else {
      dateQueryFilter += `&DateTimeTo=${endOfDay(dateRange.value.to).toISOString()}`;
    }
  }

  if (directions?.value.incoming) {
    dateQueryFilter += `&Direction=${Directions.Incoming}`;
  }

  if (directions?.value.outgoing) {
    dateQueryFilter += `&Direction=${Directions.Outcoming}`;
  }

  return dateQueryFilter;
};
