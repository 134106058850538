import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { useAuth } from '../../modules/auth';
import { useScenario } from '../../modules/scenarios/hooks';
import { CallType } from '../types';
import { phoneHttpClient } from '../utils';

type ResponseType = CallType;

export const useCallDetails = (callId?: string): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const { id: scenarioId } = useScenario();

  return useQuery<ResponseType, Error>(
    ['call-details', callId],
    async () => {
      const response = await phoneHttpClient.get<ResponseType>(
        `/CallsHistories/${scenarioId}/getcallhistory?CallId=${callId}`,
      );

      return response.data;
    },
    {
      enabled: authenticated && !!callId,
      retry: false,
    },
  );
};
