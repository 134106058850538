type Environment = {
  PUBLIC_URL: string;
  CALLING_API_URL: string;
  REPORTING_API_URL: string;
  FOUNDATION_API_URL: string;
  GENERIC_API_URL: string;
  IDENTITY_API_URL: string;
  PHONE_API_URL: string;
  INTERCOM_APP_ID: string;
  API_URL: string;
  KEYCLOAK_URL: string;
  KEYCLOAK_CLIENT_ID: string;
  KEYCLOAK_REALM: string;
};

export const environment: Environment = {
  INTERCOM_APP_ID: window._env_.REACT_APP_INTERCOM_APP_ID || '',
  PUBLIC_URL: process.env.PUBLIC_URL,
  CALLING_API_URL: window._env_.REACT_APP_CALLING_API_URL || '',
  REPORTING_API_URL: window._env_.REACT_APP_REPORTING_API_URL || '',
  GENERIC_API_URL: window._env_.REACT_APP_GENERIC_API_URL || '',
  FOUNDATION_API_URL: window._env_.REACT_APP_FOUNDATION_API_URL || '',
  IDENTITY_API_URL: window._env_.REACT_APP_IDENTITY_API_URL || '',
  PHONE_API_URL: window._env_.REACT_APP_PHONE_API_URL || '',
  API_URL: window._env_.REACT_APP_API_URL || '',
  KEYCLOAK_URL: window._env_.KEYCLOAK_URL || '',
  KEYCLOAK_CLIENT_ID: window._env_.KEYCLOAK_CLIENT_ID || '',
  KEYCLOAK_REALM: window._env_.KEYCLOAK_REALM || '',
};
