import { PaperClasses, PaperProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const paperStyles: {
  defaultProps?: Partial<PaperProps<'div'>> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof PaperClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      color: '#676984',
    },
  },
};
