import React, { ReactNode } from 'react';
import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  hidePadding?: boolean;
  onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose } = props;

  return (
    <MuiDialogTitle
      sx={{
        margin: 0,
        paddingTop: 4,
        paddingLeft: 4,
        paddingRight: {
          xs: 6,
          sm: 8,
        },
        paddingBottom: props.hidePadding ? 0 : 4,
      }}
    >
      <Typography
        sx={{
          fontSize: {
            xs: 18,
            sm: 24,
          },
          lineHeight: {
            xs: '22px',
            sm: '30px',
          },
          fontWeight: 700,
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(1),
            top: (theme) => theme.spacing(1),
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

type PropsType = {
  modalBody: JSX.Element | React.ElementType;
  handleModalClose: () => void;
  modalOpen: boolean;
  title: string;
  description?: string;
};

export const BasicModal: React.FC<PropsType> = (props) => {
  const body = props.modalBody;

  return (
    <Box>
      <Dialog onClose={props.handleModalClose} aria-labelledby={props.title} open={props.modalOpen}>
        <DialogTitle id={props.title} onClose={props.handleModalClose} hidePadding={!!props.description}>
          {props.title}
        </DialogTitle>
        <DialogContentText
          sx={{
            px: 4,
            pb: props.description ? 4 : 0,
          }}
        >
          {props.description}
        </DialogContentText>

        <MuiDialogContent
          sx={{
            padding: 4,
            paddingTop: 2,
          }}
        >
          {body as ReactNode}
        </MuiDialogContent>
      </Dialog>
    </Box>
  );
};
