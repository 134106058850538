import React from 'react';
import { useTranslation } from 'react-i18next';
import { TablePagination } from '@mui/material';
import { SetValueType } from '../../common/hooks';
import { paginationLabel } from '../../common/utils';

type Props = {
  count: number;
  page: number;
  pageNumber: SetValueType<number>;
  rowsPerPage: number;
};

const PaginationComponent: React.FC<Props> = ({ count, page, pageNumber, rowsPerPage }) => {
  const { t } = useTranslation();

  return (
    <TablePagination
      component={'div'}
      count={count}
      page={page}
      onPageChange={(event, newPage) => pageNumber.set(newPage + 1)}
      labelRowsPerPage={''}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[]}
      labelDisplayedRows={({ from, to, count }) => paginationLabel(from, to, count, t('of'))}
      size={'small'}
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
        paddingBottom: 0,
        marginX: 1,
        minWidth: 190,
      }}
    />
  );
};

export default PaginationComponent;
