import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { callingHttpClient } from '../../../common/utils';

type Data = {
  key: string;
  value: string | Date | number | boolean | null;
  scenarioId: string;
  batchId: string;
};

type Response = unknown;

export const useUpdateAdditionalParameter = (): UseMutationResult<Response, MutationErrorType, Data> => {
  return useMutation(async (body) => {
    const response = await callingHttpClient.patch(
      `/Batch/${body.batchId}/callsScheduleParameters/AdditionalParameter/Update`,
      {
        scenarioId: body.scenarioId,
        key: body.key,
        value: body.value,
      },
    );
    return response.data;
  });
};
