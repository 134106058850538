import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ScenarioCollectionType } from '../../enums';
import { useCollectionValuesQuery } from '../../hooks';
import {
  CollectionValuesForm,
  CollectionValuesJson,
  CollectionValuesJsonSkeletons,
  CollectionValuesNewJson,
  CollectionValuesTableSkeletons,
} from './index';

type Props = {
  collectionKeyName?: string;
  collectionType: ScenarioCollectionType;
  isValuesVisible: boolean;
};

export const CollectionValues: React.FC<Props> = ({ collectionKeyName, collectionType, isValuesVisible }) => {
  const { t } = useTranslation();
  const { data, isLoading, refetch, isFetched } = useCollectionValuesQuery(collectionKeyName);
  const [isNewDocumentJsonOpen, setIsNewDocumentJsonOpen] = useState(false);

  const collectionValues = useMemo(() => data?.values, [data?.values]);

  if (!isValuesVisible) return null;

  const collectionJsonContent = (
    <Box>
      {!isFetched && isLoading ? (
        <CollectionValuesJsonSkeletons />
      ) : (
        <>
          {isNewDocumentJsonOpen ? (
            <CollectionValuesNewJson
              collectionKeyName={collectionKeyName}
              refetch={refetch}
              setIsNewDocumentJsonOpen={setIsNewDocumentJsonOpen}
            />
          ) : (
            <Button
              onClick={() => setIsNewDocumentJsonOpen(true)}
              color="primary"
              variant="contained"
              sx={{
                mt: 1,
                ml: 1,
              }}
            >
              {t('scenarioConfigurator.addNewDocument')}
            </Button>
          )}
          {collectionValues?.length ? (
            <List
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'common.white',
              }}
            >
              {collectionValues.map((documentValue) => (
                <CollectionValuesJson
                  key={documentValue._id}
                  documentValue={documentValue}
                  collectionKeyName={collectionKeyName}
                  refetch={refetch}
                />
              ))}
            </List>
          ) : null}
        </>
      )}
    </Box>
  );

  const collectionTableContent = (
    <TableContainer
      sx={{
        maxHeight: '60vh',
      }}
    >
      <Table
        stickyHeader
        sx={{
          maxWidth: '100%',
        }}
      >
        {isLoading ? (
          <>
            <TableHead>
              <TableRow>
                <CollectionValuesTableSkeletons />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover>
                <CollectionValuesTableSkeletons />
              </TableRow>
            </TableBody>
          </>
        ) : (
          <>
            <TableHead>
              <TableRow
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
                  '& .MuiTableCell-root': {
                    '& .MuiTableCell-stickyHeader': {
                      backgroundColor: 'background.paper',
                    },
                  },
                }}
              >
                {collectionValues?.length ? (
                  <>
                    <TableCell>{t('key')}</TableCell>
                    <TableCell>{t('value')}</TableCell>
                  </>
                ) : (
                  <TableCell>{t('scenarioConfigurator.noValues')}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {collectionValues?.length ? (
                <>
                  {collectionValues.map((documentValue) => (
                    <CollectionValuesForm
                      key={documentValue._id}
                      documentValue={documentValue}
                      collectionType={collectionType}
                      collectionKeyName={collectionKeyName}
                      refetch={refetch}
                    />
                  ))}
                </>
              ) : null}
            </TableBody>
          </>
        )}
      </Table>
    </TableContainer>
  );

  return (
    <Paper
      elevation={0}
      sx={{
        width: '100%',
        padding: 1,
      }}
    >
      {collectionType === ScenarioCollectionType.Editable ? collectionJsonContent : collectionTableContent}
    </Paper>
  );
};
