import React from 'react';
import { endOfDay, startOfDay } from 'date-fns';
import { SetValueType, useSetValue } from '../../../common/hooks';

export type HistoryContextType = {
  pageNumber: SetValueType<number>;
  dateRange: SetValueType<{ from?: Date; to?: Date }>;
  hoursRange: SetValueType<{ from: Date; to: Date }>;
  directions: SetValueType<{ incoming: boolean; outgoing: boolean }>;
};

const initialData: HistoryContextType = {
  pageNumber: { set: () => null, value: 1 },
  dateRange: { set: () => null, value: { to: undefined, from: undefined } },
  hoursRange: {
    set: () => null,
    value: { from: startOfDay(new Date()), to: endOfDay(new Date()) },
  },
  directions: { set: () => null, value: { incoming: true, outgoing: true } },
};

export const HistoryContext = React.createContext<HistoryContextType>(initialData);

export const HistoryContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const pageNumber = useSetValue<number>(initialData.pageNumber.value);
  const dateRange = useSetValue<{ from?: Date; to?: Date }>(initialData.dateRange.value);
  const hoursRange = useSetValue<{ from: Date; to: Date }>(initialData.hoursRange.value);
  const directions = useSetValue<{ incoming: boolean; outgoing: boolean }>(initialData.directions.value);

  const value: HistoryContextType = {
    pageNumber,
    dateRange,
    hoursRange,
    directions,
  };

  return <HistoryContext.Provider value={value}>{props.children}</HistoryContext.Provider>;
};
