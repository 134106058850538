import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailOutlined, ExpandMore, LocalPhoneOutlined } from '@mui/icons-material';
import { Container, FormControl, Grid, MenuItem, Select, Theme, Typography, useMediaQuery } from '@mui/material';
import { TranslationContext } from '../../modules/translation';

export const Footer: React.FC<React.PropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const { currentLanguage, setCurrentLanguage } = useContext(TranslationContext);
  const smScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <>
      {props.children}
      <Container
        maxWidth={'xxl'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          minHeight: {
            xs: 148,
            sm: 64,
          },
          backgroundColor: 'secondary.main',

          boxShadow: (theme) => theme.shadows['3'],
        }}
      >
        <Grid
          container
          sx={{
            alignItems: 'center',
            justifyContent: {
              xs: 'center',
              sm: 'space-between',
            },
          }}
        >
          <Grid item>
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: {
                  xs: 'center',
                  sm: 'flex-start',
                },
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
              }}
            >
              <Grid item>
                <Typography variant="body1">{t('footer.contact')}:</Typography>
              </Grid>
              <Grid item>
                <Container
                  disableGutters
                  sx={{
                    display: 'flex',
                    columnGap: (theme) => theme.spacing(1),
                    alignItems: 'center',
                  }}
                >
                  <LocalPhoneOutlined />
                  <Typography variant="body1">+48 699 607 846</Typography>
                </Container>
              </Grid>
              <Grid item>
                <Container
                  disableGutters
                  sx={{
                    display: 'flex',
                    columnGap: (theme) => theme.spacing(1),
                    alignItems: 'center',
                  }}
                >
                  <EmailOutlined />
                  <Typography variant="body1">hello@hellobot.pl</Typography>
                </Container>
              </Grid>
            </Grid>
          </Grid>
          {smScreen && (
            <Grid item>
              <FormControl>
                <Select
                  disableUnderline
                  variant="standard"
                  labelId="lang-select-label"
                  id="lang-select"
                  label={t('selectLang')}
                  value={currentLanguage}
                  onChange={(e) => setCurrentLanguage(`${e.target.value}`)}
                  IconComponent={ExpandMore}
                  sx={{
                    paddingRight: 0,
                    '& .MuiSelect-icon': {},
                  }}
                >
                  {['en', 'pl', 'es'].map((lang, index) => (
                    <MenuItem key={index} value={lang}>
                      {lang}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};
