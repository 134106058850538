import { routes } from '../../routes';
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import React from 'react';

type NavLinkModel = {
  getLink: (scenarioId: string) => string;
  icon?: React.ReactNode;
  key: string;
  name: string;
};
export const navLinks: NavLinkModel[] = [
  {
    getLink: (id: string) => routes.scenario.dashboard.link({ id }),
    key: 'dashboard',
    icon: <DonutLargeOutlinedIcon />,
    name: 'dashboard.heading',
  },
  {
    getLink: (id: string) => routes.scenario.callsSchedule.collectionList.link({ id }),
    key: 'callsSchedule',
    icon: <SendOutlinedIcon />,
    name: 'callsSchedule.heading',
  },
  {
    getLink: (id: string) => routes.scenario.call.link({ id }),
    key: 'calls',
    icon: <LocalPhoneOutlinedIcon />,
    name: 'call.heading',
  },
  {
    getLink: (id: string) => routes.scenario.history.link({ id }),
    key: 'history',
    icon: <HistoryOutlinedIcon />,
    name: 'history.heading',
  },
  {
    getLink: (id: string) => routes.scenario.reports.link({ id }),
    key: 'reports',
    icon: <TableChartOutlinedIcon />,
    name: 'reports.heading',
  },
  {
    getLink: (id: string) => routes.scenario.scenarioConfigurator.link({ id }),
    key: 'scenarioConfigurator',
    icon: <SettingsOutlinedIcon />,
    name: 'scenarioConfigurator.heading',
  },
];
