import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { reportingHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';
import { ReportListType } from '../types';

type ResponseType = ReportListType;

export const useReportList = (): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const { id: scenarioId } = useScenario();

  return useQuery<ResponseType, Error>(
    ['report-list', scenarioId],
    async () => {
      const response = await reportingHttpClient.get<ResponseType>(`/BusinessReports/${scenarioId}/allreports`);
      return response.data;
    },
    {
      enabled: authenticated,
      staleTime: 3000,
    },
  );
};
