import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHead, TableRow } from '@mui/material';
import { ReportsContext } from '../../provider/reports-context.provider';
import { TableHeaderCell } from '../table-header-cell';

type PropsType = {
  reportId: string;
};

export const ReportTableHead: React.FC<PropsType> = ({ reportId }) => {
  const { t } = useTranslation();
  const { columns, callDetails } = useContext(ReportsContext);

  return (
    <TableHead>
      <TableRow>
        {columns.value.map((column, index) => (
          <TableHeaderCell
            reportId={reportId}
            key={`${column.dbColumnName}-${index}`}
            columnName={column.dbColumnName}
            displayName={column.displayName}
            filterType={column.filterType}
            lastCellSticky={!callDetails.value && index === columns.value.length - 1}
          />
        ))}
        {callDetails.value ? (
          <TableHeaderCell
            reportId={reportId}
            columnName={'call_details'}
            displayName={t('reports.details')}
            lastCellSticky={callDetails.value}
          />
        ) : null}
      </TableRow>
    </TableHead>
  );
};
