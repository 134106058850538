import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { callingHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { CallsScheduleContext } from '../../calls-schedule/provider';
import { useScenario } from '../../scenarios/hooks';

type ResponseType = {
  acceptsImmediateCalls: boolean;
};

export const checkScenarioAcceptsImmediateCalls = (): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const { id: scenarioId } = useScenario();
  const { selectedStatusFilter } = useContext(CallsScheduleContext);

  return useQuery<ResponseType, Error>(
    [],
    async () => {
      const response = await callingHttpClient.get<ResponseType>(
        `/Scenarios/${scenarioId}/checkIfScenarioAcceptsImmediateCalls`,
      );

      return response.data;
    },
    {
      enabled: authenticated && !!selectedStatusFilter.value,
      staleTime: 5000,
    },
  );
};
