import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { ExpandMore } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Grid, ListItem, Stack, Typography } from '@mui/material';
import { useDeviceQuery } from '../../../../common/hooks';
import { JsonEditor } from '../../../../components/json-editor';
import { useDeleteCollectionValue, useUpdateCollectionValues } from '../../hooks';
import { CollectionValuesDocumentType } from '../../types';

type Props = {
  documentValue: CollectionValuesDocumentType;
  collectionKeyName?: string;
  refetch: () => void;
};

type FormData = {
  values: {
    [key: string]: unknown;
  };
};

export const CollectionValuesJson: React.FC<Props> = ({ documentValue, collectionKeyName, refetch }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: mutateUpdateCollectionValues, isLoading: isUpdateCollectionValuesLoading } =
    useUpdateCollectionValues();
  const { mutate: mutateDeleteCollectionValue, isLoading: isDeleteCollectionValueLoading } = useDeleteCollectionValue();
  const [isDocumentValuesVisible, setIsDocumentValuesVisible] = useState(false);
  const { isDesktop, isMobile } = useDeviceQuery();

  const form = useForm<FormData>();
  const { _id, ...documentWithoutId } = documentValue;

  const onSubmit = useCallback(
    (data: FormData) => {
      const { values } = data;
      mutateUpdateCollectionValues(
        {
          collectionKeyName: collectionKeyName ?? '',
          documents: [
            {
              documentId: _id ?? '',
              data: values,
            },
          ],
        },
        {
          onSuccess: async () => {
            enqueueSnackbar(t('scenarioConfigurator.editFeedback.success'), { variant: 'success' });
            await refetch();
            form.reset(data);
          },
          onError: async () => {
            enqueueSnackbar(t('scenarioConfigurator.editFeedback.error'), { variant: 'error' });
          },
        },
      );
    },
    [documentValue],
  );

  const handleDeleteCollectionValue = useCallback(() => {
    mutateDeleteCollectionValue(
      {
        collectionKeyName: collectionKeyName ?? '',
        documentId: _id ?? '',
      },
      {
        onSuccess: async () => {
          enqueueSnackbar(t('scenarioConfigurator.deleteFeedback.success'), { variant: 'success' });
          await refetch();
        },
        onError: async () => {
          enqueueSnackbar(t('scenarioConfigurator.deleteFeedback.error'), { variant: 'error' });
        },
      },
    );
  }, [documentValue]);

  return (
    <ListItem
      sx={{
        display: 'flex',
        flexDirection: 'column',
        px: 1,
        py: 0.5,
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        sx={{
          height: (theme) => theme.spacing(7),
          backgroundColor: 'background.default',
          borderRadius: 1,
          px: 2,
          py: 1,
          columnGap: (theme) => theme.spacing(isMobile ? 2 : 5),
          borderBottomRightRadius: isDocumentValuesVisible ? 0 : 1,
          borderBottomLeftRadius: isDocumentValuesVisible ? 0 : 1,
        }}
      >
        <Grid
          item
          md={isDesktop ? 4 : 12}
          zeroMinWidth
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: (theme) => theme.spacing(isMobile ? 2 : 3),
            marginRight: 2,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            startIcon={<ExpandMore />}
            onClick={() => setIsDocumentValuesVisible((prev) => !prev)}
            sx={{
              width: 32,
              height: 32,
              minHeight: 32,
              minWidth: 32,
              borderRadius: 50,
              '& .MuiButton-startIcon': {
                margin: 0,
                transform: isDocumentValuesVisible ? 'rotate(0.5turn)' : undefined,
              },
            }}
          />
          <Typography
            variant="h5"
            title={_id}
            noWrap
            sx={{
              fontWeight: 'bold',
              fontSize: 18,
            }}
          >
            {_id}
          </Typography>
        </Grid>
      </Grid>
      {isDocumentValuesVisible ? (
        <Box
          sx={{
            width: '100%',
            padding: 1,
          }}
        >
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Controller
              control={form.control}
              name="values"
              render={({ field: { onChange, value } }) => (
                <JsonEditor src={value ? value : documentWithoutId} setter={onChange} />
              )}
            />
            <Stack
              direction={'row'}
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              <LoadingButton
                disabled={!form.formState.isDirty}
                type="submit"
                variant="contained"
                color="primary"
                loading={isUpdateCollectionValuesLoading}
              >
                {t('scenarioConfigurator.edit')}
              </LoadingButton>
              <LoadingButton
                type="button"
                variant="contained"
                color="primary"
                loading={isDeleteCollectionValueLoading}
                onClick={handleDeleteCollectionValue}
              >
                {t('scenarioConfigurator.delete')}
              </LoadingButton>
            </Stack>
          </form>
        </Box>
      ) : null}
    </ListItem>
  );
};
