import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import { FileType } from '../../common/enums/file-types.enums';
import { useTranslation } from 'react-i18next';

type PropsType = {
  handleExport: () => void;
  text: string;
  selectedFile: FileType;
  onSelectedFileChanged: (value: FileType) => void;
  disabled?: boolean;
};

export const ExportToFile: React.FC<PropsType> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [t] = useTranslation();
  const handleChange = (event: SelectChangeEvent<FileType>) => {
    props.onSelectedFileChanged(event.target.value as FileType);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleOk = () => {
    setOpen(false);
    props.handleExport();
  };

  return (
    <div>
      <Button
        disabled={props.disabled}
        onClick={handleClickOpen}
        sx={{
          marginBottom: {
            xs: 1,
            sm: 0,
          },
          marginLeft: {
            xs: 0,
            sm: 1,
          },
          whiteSpace: 'nowrap',
          width: {
            xs: '100%',
            sm: 211,
          },
          height: 48,
        }}
      >
        {props.text}
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>{t('reports.chooseFileFormat')}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 400 }}>
            <FormControl sx={{ m: 1, minWidth: 400 }}>
              <InputLabel htmlFor="demo-dialog-native">Type</InputLabel>
              <Select
                native
                onChange={handleChange}
                value={props.selectedFile}
                input={<OutlinedInput label="FileType" id="demo-dialog-native" />}
              >
                <option value={FileType.CSV}>CSV</option>
                <option value={FileType.XLSX}>XLSX</option>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
