import { Theme, useMediaQuery } from '@mui/material';

export const useDeviceQuery = (): { isMobile: boolean; isTablet: boolean; isDesktop: boolean } => {
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const tablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'lg'));
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isDesktop = desktop;
  const isTablet = tablet && !desktop;
  const isMobile = mobile && !tablet;

  return { isMobile, isTablet, isDesktop };
};
