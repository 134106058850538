import React, { useContext, useState } from 'react';
import { useScenario, useScenariosQuery } from '../modules/scenarios/hooks';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { navLinks } from './components/nav-links';
import { SideNavigationItem } from './components/side-navigation-item';
import { Outlet, useNavigate } from 'react-router-dom';
import { Logo } from '../components/logo';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../modules/auth';
import { TranslationContext } from '../modules/translation';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@mui/lab';

export const DashboardLayout: React.FC = () => {
  const { id: selectedScenarioId } = useScenario();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [languageAnchorEl, setLanguageAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { tokenParsed, logout } = useAuth();
  const { currentLanguage, setCurrentLanguage } = useContext(TranslationContext);
  const { t } = useTranslation();
  const { data, isLoading } = useScenariosQuery();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLanguageAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const handleLanguageClick = (event: React.MouseEvent<HTMLElement>) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLanguageChange = (language: string) => {
    setCurrentLanguage(language);
    handleClose();
  };
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const openLanguage = Boolean(languageAnchorEl);
  const id = open ? 'user-popover' : undefined;
  const languageId = openLanguage ? 'language-popover' : undefined;
  return (
    <Stack direction={'row'} flexGrow={1}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'background.default',
          boxShadow: 'none',
          borderBottom: '2px solid',
          borderColor: '#EBEEF3',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Toolbar
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Logo />
          </Stack>
          <IconButton
            onClick={handleDrawerToggle}
            sx={{
              display: {
                xs: 'block',
                md: 'none',
              },
              marginLeft: 'auto',
            }}
          >
            <MenuIcon />
          </IconButton>

          <IconButton
            sx={{
              display: {
                xs: 'none',
                md: 'block',
              },
            }}
            onClick={handleClick}
          >
            <AccountCircleOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: {
            xs: 'block',
            md: 'none',
          },
          '& .MuiDrawer-paper': { width: 300 },
        }}
      >
        <Box
          sx={{
            pt: 2,
            width: 300,
            backgroundColor: 'background.paper',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Toolbar>
              <Logo />
            </Toolbar>
            <Stack alignItems={'center'} spacing={1}>
              <Autocomplete
                fullWidth
                value={
                  data?.scenarioCollection.find((scenario) => scenario.scenarioId === selectedScenarioId)
                    ?.scenarioName ?? ''
                }
                onChange={(_, value) => {
                  const scenario = data?.scenarioCollection.find((scenario) => scenario.scenarioName === value);
                  if (scenario) {
                    navigate(`/scenario/${scenario.scenarioId}/`);
                  }
                }}
                sx={{
                  px: 2,
                }}
                renderInput={(params) => <TextField {...params} label={t('users.selectScenario')} variant="outlined" />}
                options={data?.scenarioCollection.map((scenario) => scenario.scenarioName) ?? []}
                loading={isLoading}
              />
              {navLinks.map((link) => (
                <SideNavigationItem
                  label={link.name}
                  path={link.getLink(selectedScenarioId)}
                  key={link.name}
                  icon={link.icon}
                />
              ))}
            </Stack>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              {tokenParsed?.name} {tokenParsed?.lastName}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {tokenParsed?.user_email}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <MenuItem onClick={handleLanguageClick}>
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText primary={currentLanguage?.toUpperCase()} />
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={t('navbar.logout')} />
            </MenuItem>
          </Box>
        </Box>
      </Drawer>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2, minWidth: 250 }}>
          <Typography variant="subtitle1" gutterBottom>
            {tokenParsed?.name} {tokenParsed?.lastName}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {tokenParsed?.user_email}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <MenuItem onClick={handleLanguageClick}>
            <ListItemIcon>
              <LanguageIcon />
            </ListItemIcon>
            <ListItemText primary={currentLanguage?.toUpperCase()} />
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={t('navbar.logout')} />
          </MenuItem>
        </Box>
      </Popover>

      <Popover
        id={languageId}
        open={openLanguage}
        anchorEl={languageAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2 }}>
          {['en', 'pl', 'es'].map((lang) => (
            <MenuItem key={lang} onClick={() => handleLanguageChange(lang)}>
              {lang.toUpperCase()}
            </MenuItem>
          ))}
        </Box>
      </Popover>

      <Box
        sx={{
          backgroundColor: 'background.paper',
          pt: 2,
          display: {
            xs: 'none',
            md: 'flex',
          },
          width: 300,
          borderRight: '1px solid',
          borderColor: '#EBEEF3',
          minWidth: 300,
          height: '100vh',
          position: 'fixed',
          top: 0,
          zIndex: (theme) => theme.zIndex.drawer,
        }}
      >
        <Stack
          alignItems={'center'}
          spacing={1}
          sx={{
            width: 280,
            paddingTop: '59px',
            height: '100vh',
          }}
        >
          <Autocomplete
            fullWidth
            value={
              data?.scenarioCollection.find((scenario) => scenario.scenarioId === selectedScenarioId)?.scenarioName ??
              ''
            }
            onChange={(_, value) => {
              const scenario = data?.scenarioCollection.find((scenario) => scenario.scenarioName === value);
              if (scenario) {
                navigate(`/scenario/${scenario.scenarioId}/`);
              }
            }}
            sx={{
              pl: 2,
            }}
            renderInput={(params) => <TextField {...params} label={t('users.selectScenario')} variant="outlined" />}
            options={data?.scenarioCollection.map((scenario) => scenario.scenarioName) ?? []}
            loading={isLoading}
          />
          {navLinks.map((link) => (
            <SideNavigationItem
              label={link.name}
              path={link.getLink(selectedScenarioId)}
              key={link.name}
              icon={link.icon}
            />
          ))}
        </Stack>
      </Box>
      <Box
        sx={{
          flex: 1,
          overflowX: 'auto',
          marginLeft: {
            xs: 0,
            md: '300px',
          },
          marginTop: '64px',
          minHeight: '100vh',
        }}
      >
        <Outlet />
      </Box>
    </Stack>
  );
};
