import { PaletteOptions } from '@mui/material/styles/createPalette';

export const paletteOptions: PaletteOptions = {
  text: {
    primary: '#181C20',
    secondary: '#3F4947',
    disabled: '#A3A6AA',
  },
  primary: {
    main: '#006A60',
  },
  background: {
    paper: '#fff',
    default: '#F7F9FF',
  },
  secondary: {
    light: '#A1EFFF',
    main: '#009FB4',
    dark: '#001F25',
  },
  info: {
    main: '#456179',
    light: '#CCE5FF',
    dark: '#001E31',
    contrastText: '#001E31',
  },
  divider: '#EBEEF3',
  error: {
    main: '#BA1A1A',
    light: '#FFDAD6',
    dark: '#410002',
  },
  success: {
    main: '#406836',
    light: '#C1EFAF',
    dark: '#012200',
  },
  warning: {
    main: '#EF6C00',
    dark: '#663C00',
    light: '#FFF4E5',
  },
  common: {
    white: '#fff',
  },
};
