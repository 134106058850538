import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { reportingHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';
import { ReportColumnType } from '../types';

type ResponseType = {
  name: string;
  scenarioId: string;
  viewName: string;
  columnMapping: ReportColumnType[];
};

export const useReportColumns = (id: string): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const { id: scenarioId } = useScenario();

  return useQuery<ResponseType, Error>(
    ['report-columns', scenarioId, id],
    async () => {
      const response = await reportingHttpClient.get<ResponseType>(`/BusinessReports/${scenarioId}?id=${id}`);
      return response.data;
    },
    {
      enabled: authenticated && !!id,
    },
  );
};
