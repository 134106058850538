import React, { useEffect } from 'react';
import { SetValueType, useSetValue } from '../../../common/hooks/set-value.hook';
import { CallsScheduleCollectionStatusEnum } from '../data';
import { ScheduleFileType } from '../types';

type CallsScheduleContextType = {
  scheduleFiles: SetValueType<ScheduleFileType[]>;
  statusFilter: SetValueType<CallsScheduleCollectionStatusEnum>;
  selectedStatusFilter: SetValueType<CallsScheduleCollectionStatusEnum>;
};

const initialData: CallsScheduleContextType = {
  scheduleFiles: { set: () => null, value: [] },
  statusFilter: { set: () => null, value: CallsScheduleCollectionStatusEnum.ALL },
  selectedStatusFilter: { set: () => null, value: CallsScheduleCollectionStatusEnum.ALL },
};

export const CallsScheduleContext = React.createContext<CallsScheduleContextType>(initialData);

export const CallsScheduleProvider: React.FC<React.PropsWithChildren> = (props) => {
  const files = useSetValue(initialData.scheduleFiles.value);
  const statusFilter = useSetValue(initialData.statusFilter.value);
  const selectedStatusFilter = useSetValue(initialData.statusFilter.value);

  useEffect(() => {
    statusFilter.set(selectedStatusFilter.value);
  }, [selectedStatusFilter.value]);

  const value: CallsScheduleContextType = {
    scheduleFiles: files,
    statusFilter: statusFilter,
    selectedStatusFilter: selectedStatusFilter,
  };

  return <CallsScheduleContext.Provider value={value}>{props.children}</CallsScheduleContext.Provider>;
};
