import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { reportingHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';
import { ReportsContext } from '../provider/reports-context.provider';
import { ReportDataType } from '../types';
import { getFiltersURLString } from '../utils/filters';

type ResponseType = ReportDataType;

export const useReportData = (id: string): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const { id: scenarioId } = useScenario();
  const reportsContext = useContext(ReportsContext);

  return useQuery<ResponseType, Error>(
    [
      'report-data',
      scenarioId,
      id,
      reportsContext.dateRange.value,
      reportsContext.pageNumber,
      reportsContext.distinctValues,
    ],
    async () => {
      const filerString = getFiltersURLString(reportsContext);
      const pageSize = 10;
      const response = await reportingHttpClient.get<ResponseType>(
        `/BusinessReports/${scenarioId}/data?Id=${id}&PageNumber=${reportsContext.pageNumber.value}&PageSize=${pageSize}${filerString}`,
      );
      return response.data;
    },
    {
      enabled: authenticated && !!id,
      staleTime: 5000,
      retry: false,
    },
  );
};
