import React from 'react';
import { Box, TableCell, TableRow } from '@mui/material';
import { IdCellContent } from '../../../../components/id-cell-content';

type Props = {
  id: string;
  status: string;
  savedOnDateUtc: string;
  phoneNumber: string;
};

export const CallsTableRow: React.FC<Props> = (props) => {
  // const modal = useModal();
  return (
    <TableRow>
      <TableCell>
        <Box>
          <IdCellContent callId={props.id} />
        </Box>
      </TableCell>
      <TableCell>{props.savedOnDateUtc}</TableCell>
      <TableCell>{props.status}</TableCell>
      <TableCell>{props.phoneNumber}</TableCell>
      <TableCell>
        {/*<IconButton color="primary" onClick={modal.open}>*/}
        {/*  <Info />*/}
        {/*</IconButton>*/}
        {/*<RecordPreviewModal modalOpen={modal.isOpen} handleModalClose={modal.close} recordId={props.id} />*/}
      </TableCell>
    </TableRow>
  );
};
