import React from 'react';
import ReactPlayer from 'react-player';
import { Box } from '@mui/material';
import { keycloakInstance } from '../../modules/auth';

type Props = {
  stream?: string;
};

export const AudioStreamPlayer: React.FC<Props> = (props) => {
  return (
    <Box
      sx={{
        paddingY: 2,
        paddingX: 0,
      }}
    >
      <ReactPlayer
        controls
        height="55px"
        width="100%"
        url={props.stream ? props.stream : undefined}
        config={{
          file: {
            forceHLS: true,
            forceAudio: true,
            hlsOptions: {
              xhrSetup: function (xhr: XMLHttpRequest) {
                const { token } = keycloakInstance;
                xhr.setRequestHeader('Authorization', `Bearer ${token}`);
              },
            },
          },
        }}
      />
    </Box>
  );
};
