import React from 'react';
import { Button } from '@mui/material';

type PropsType = {
  icon: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

export const RoundedButton: React.FC<PropsType> = (props) => {
  return (
    <Button
      onClick={props.onClick}
      disabled={props.disabled}
      sx={{
        width: 36,
        height: 36,
        minWidth: 'unset',
        borderRadius: '50%',
        '& svg': {
          color: 'common.white',
          width: '26px',
          height: '26px',
        },
        '& .Mui-disabled': {
          backgroundColor: 'grey.A200',
        },
      }}
    >
      {props.icon}
    </Button>
  );
};
