import React, { useEffect } from 'react';
import { downloadFileWithToken } from '../../common/utils';
import { useLocation } from 'react-router-dom';
import { environment } from '../../environment';

export const RecordingDownloadPage: React.FC = () => {
  const location = useLocation();
  const id = location.pathname.split('/recordings/')?.[1]?.split('/')?.[0];

  const downloadRecording = () => {
    const fileUrl = `${environment.API_URL}/Recordings/${id}/Download`;
    const fileName = `${id}.wav`;
    downloadFileWithToken(fileUrl, fileName);
  };

  useEffect(() => {
    downloadRecording();
  }, [id]);

  return <></>;
};
