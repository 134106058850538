import React, { useState } from 'react';
import Clipboard from 'react-clipboard.js';
import { useTranslation } from 'react-i18next';
import { FileCopy } from '@mui/icons-material';
import { Button, Stack, Tooltip, Typography } from '@mui/material';

type PropsType = {
  callId: string;
};

export const IdCellContent: React.FC<PropsType> = (props) => {
  const { t } = useTranslation();
  const [text, setText] = useState(`${t('copy')} Id`);

  return (
    <Stack direction={'row'} alignItems={'center'} width={'fit-content'}>
      <Tooltip
        title={
          <Typography paragraph fontSize={14} m={0}>
            {props.callId}
          </Typography>
        }
        placement="bottom-start"
      >
        <Typography
          paragraph
          m={0}
          sx={{
            maxWidth: '70px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {props.callId}
        </Typography>
      </Tooltip>
      <Clipboard component="div" data-clipboard-text={props.callId} onSuccess={() => setText(`${t('copied')}!`)}>
        <Tooltip
          title={
            <Typography paragraph fontSize={14} m={0}>
              {text}
            </Typography>
          }
          leaveDelay={500}
          onClose={() => setText(`${t('copy')} Id`)}
          arrow
          placement="top"
        >
          <Button
            size={'small'}
            sx={{
              minWidth: 'unset',
            }}
          >
            <FileCopy fontSize={'small'} />
          </Button>
        </Tooltip>
      </Clipboard>
    </Stack>
  );
};
