import React from 'react';
import { useTimezoneQuery } from '../hooks';

type TimezoneContextType = {
  timezone: string;
  timezoneToDisplay: string;
  isLoading: boolean;
};

const initialData: TimezoneContextType = {
  timezone: '',
  isLoading: false,
  timezoneToDisplay: '',
};

export const TimeZoneContext = React.createContext<TimezoneContextType>(initialData);

export const TimeZoneContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { data, isLoading } = useTimezoneQuery();
  const value: TimezoneContextType = {
    timezone: data?.ianaTimeZoneId ?? '',
    timezoneToDisplay: data?.timeZone?.displayName ?? '',
    isLoading,
  };

  return <TimeZoneContext.Provider value={value}>{props.children}</TimeZoneContext.Provider>;
};
