import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  label: string;
  path: string;
  icon: React.ReactNode;
};

export const SideNavigationItem: React.FC<Props> = (props) => {
  const isActive = window.location.pathname === props.path;
  const navigate = useNavigate();
  const [t] = useTranslation();
  return (
    <Stack
      width="100%"
      onClick={() => {
        navigate(props.path);
      }}
      alignItems="center"
      borderRadius={12}
      flexDirection="row"
      gap={1}
      sx={{
        backgroundColor: isActive ? 'secondary.light' : 'transparent',
        cursor: 'pointer',
        width: '90%',
        p: 2,
        '&:hover': {
          backgroundColor: 'secondary.main',
          color: 'common.white',
        },
      }}
    >
      {props.icon}
      <Typography fontSize="medium">{t(props.label)}</Typography>
    </Stack>
  );
};
