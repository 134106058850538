import React from 'react';
import ReactJson from 'react-json-view';

type Props = {
  src: Record<string, unknown>;
  setter: (...event: unknown[]) => void;
};

export const JsonEditor: React.FC<Props> = ({ src, setter }) => {
  return (
    <ReactJson
      src={src}
      name={false}
      theme={'rjv-default'}
      indentWidth={4}
      enableClipboard={false}
      displayDataTypes={false}
      onEdit={(e) => setter(e.updated_src)}
      onDelete={(e) => setter(e.updated_src)}
      onAdd={(e) => setter(e.updated_src)}
    />
  );
};
