import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ReactKeycloakProvider } from '@react-keycloak-fork/web';
import { setHTMLAttrLangTag } from './common/i18next';
import { queryClient } from './common/utils';
import { SplashScreen } from './components/splash-screen';
import { keycloakInitOptions, keycloakInstance } from './modules/auth';
import { ScenarioContextProvider } from './modules/scenarios/provider';
import { TranslationProvider } from './modules/translation';
import { RootRouting } from './routes';
import { theme } from './theme';
import { TimeZoneContextProvider } from './modules/scenarios/provider/timezone-context.provider';
import { environment } from './environment';
import { IntercomProvider } from 'react-use-intercom';
import { IntercomUserProvider } from './modules/intercom/intercom-user-provider';

const App: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    setHTMLAttrLangTag(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const keycloak = keycloakInstance;
    const refreshToken = async () => {
      if (keycloak.isTokenExpired()) {
        try {
          await keycloak.updateToken(30);
        } catch (error) {
          keycloak.logout();
        }
      }
    };
    keycloak.onTokenExpired = () => {
      refreshToken();
    };
  }, []);

  return (
    <ReactKeycloakProvider authClient={keycloakInstance} initOptions={keycloakInitOptions}>
      <ThemeProvider theme={theme}>
        <SplashScreen>
          <SnackbarProvider maxSnack={5}>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <TranslationProvider>
                  <ScenarioContextProvider>
                    <TimeZoneContextProvider>
                      <IntercomProvider appId={environment.INTERCOM_APP_ID}>
                        <IntercomUserProvider>
                          <RootRouting />
                          <CssBaseline />
                        </IntercomUserProvider>
                      </IntercomProvider>
                    </TimeZoneContextProvider>
                  </ScenarioContextProvider>
                </TranslationProvider>
              </QueryClientProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </SplashScreen>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
