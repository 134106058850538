import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { callingHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { RecordType } from '../types';

type ResponseType = {
  record: RecordType;
};

export const useRecordQuery = (recordId: string, allow: boolean): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const scenarioId = location.pathname.split('/scenario/')?.[1]?.split('/')?.[0];

  return useQuery<ResponseType, Error>(
    ['record-details', recordId],
    async () => {
      const response = await callingHttpClient.get<ResponseType>(`/Record/${scenarioId}/${recordId}`);
      return response.data;
    },
    {
      enabled: authenticated && allow,
      staleTime: 5000,
    },
  );
};
