import { useContext } from 'react';
import { addDays, differenceInDays, endOfDay, startOfDay } from 'date-fns';
import { MAX_DATE_RANGE } from '../../../common/constants';
import { FilterType } from '../../../common/enums/filters.enum';
import { ReportsContext } from '../provider/reports-context.provider';

export const useExportReportDateQueryFilter = (): string | undefined => {
  const { dateRange, dateColumnName } = useContext(ReportsContext);

  let dateQueryFilter = '';
  let dateFrom = dateRange.value.from || new Date();
  const dateTo = dateRange.value.to || new Date();
  const differenceBetweenDates = differenceInDays(dateTo, dateFrom);

  if (differenceBetweenDates > MAX_DATE_RANGE) {
    dateFrom = addDays(dateFrom, differenceBetweenDates - (MAX_DATE_RANGE - 1));
  }

  dateQueryFilter += `&filters[0].type=${FilterType.DatePickerExportRange}&filters[0].columnName=${encodeURIComponent(
    dateColumnName.value,
  )}`;
  dateQueryFilter += `&filters[0].value.from=${encodeURIComponent(startOfDay(dateFrom).toISOString())}`;
  dateQueryFilter += `&filters[0].value.to=${encodeURIComponent(endOfDay(dateTo).toISOString())}`;

  return dateQueryFilter;
};
