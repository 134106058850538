import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import { Box, Button, FormGroup, Stack, Typography } from '@mui/material';
import { BasicSkeletons } from '../../../components/basic-skeletons';
import { InputText } from '../../../components/inputs';
import { useScenario, useScenariosParameters } from '../../scenarios/hooks';
import { useImmediateCall } from '../hooks/use-immediate-call.mutation';
import { useImmediateCalls } from '../hooks/use-immediate-calls.query';
import { ScenarioParameters } from '../../../components/scenario-parameters/scenario-parameters';

type FormData = {
  phoneNumber?: string;
  [key: string]: string | number | boolean | undefined | Date;
};

export const AddImmediateCallForm: React.FC = () => {
  const { data: scenarioParameters, isLoading: isScenarioParamsLoading } = useScenariosParameters();
  const { id } = useScenario();
  const form = useForm<FormData>();
  const { t } = useTranslation();
  const { mutate, isLoading: isRecordInsertLoading } = useImmediateCall();
  const { enqueueSnackbar } = useSnackbar();

  const immediateCalls = useImmediateCalls();

  const resetFormData = () => {
    form.reset();
  };

  const submit = (data: FormData) => {
    const phoneNumber = data.phoneNumber || '';
    const convertedAllTypesToSting = Object.entries(data).reduce((acc, [key, value]) => {
      acc[key] = value?.toString();
      return acc;
    }, {} as FormData);

    if (convertedAllTypesToSting.phoneNumber) {
      delete convertedAllTypesToSting.phoneNumber;
    }
    mutate(
      {
        scenarioId: id,
        record: {
          id: uuid(),
          phoneNumber,
          parameters: {
            ...(convertedAllTypesToSting as Record<string, string>),
          },
        },
      },
      {
        onSuccess: () => {
          resetFormData();
          immediateCalls.refetch();
        },
        onError: async (error) => {
          enqueueSnackbar('Nie udało się zadzwonić', { variant: 'error' });
          if (error.response.status === 400) {
            form.setError('phoneNumber', {
              message: 'Wpisane pole jest nieprawidłowe',
            });
          }
        },
      },
    );
  };

  return (
    <FormProvider {...form}>
      <Stack>
        <Typography variant={'subtitle2'}>{t('call.add')}</Typography>
        <form>
          <Stack
            flexWrap={'wrap'}
            columnGap={3}
            rowGap={1}
            sx={{
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
            }}
          >
            {isScenarioParamsLoading || isRecordInsertLoading || !scenarioParameters ? (
              <BasicSkeletons number={3} width={231} />
            ) : (
              <>
                <Box>
                  <Controller
                    name="phoneNumber"
                    control={form.control}
                    defaultValue=""
                    rules={{ required: t('validation.required') as string }}
                    render={({ field, fieldState: { error } }) => (
                      <InputText
                        fullWidth
                        margin={'dense'}
                        label={t('callsSchedule.record.phoneNumber')}
                        error={error}
                        name={field.name}
                        onChange={field.onChange}
                        value={field.value as string}
                      />
                    )}
                  />
                </Box>
                <ScenarioParameters parameters={scenarioParameters?.parameters || null} />
              </>
            )}
          </Stack>
          <FormGroup
            row
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
              justifyContent: 'space-between',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={form.handleSubmit(submit)}
              sx={{
                mt: 2,
              }}
            >
              {t('call.call')}
            </Button>
          </FormGroup>
        </form>
      </Stack>
    </FormProvider>
  );
};
