import React from 'react';
import * as ReactDOM from 'react-dom/client';
import '@fontsource/mulish/400.css';
import '@fontsource/mulish/700.css';
import App from './app';
import './common/i18next';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

type IntercomCommand =
  | 'boot'
  | 'shutdown'
  | 'update'
  | 'show'
  | 'hide'
  | 'showMessages'
  | 'showNewMessage'
  | 'onHide'
  | 'onShow'
  | 'onUnreadCountChange';

interface IntercomSettings {
  app_id: string;
  [key: string]: any;
}

declare global {
  interface Window {
    _env_: Record<string, string>;
    Intercom: (command: IntercomCommand, settings?: IntercomSettings | string) => void;
  }
}

root.render(<App />);
