import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { callingHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { CallsScheduleContext } from '../../calls-schedule/provider';
import { useScenario } from '../../scenarios/hooks';
import { CallRecord } from '../types';

type ResponseType = {
  records: CallRecord[];
  totalCount: number;
};

export const useImmediateCalls = (): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const { id: scenarioId } = useScenario();
  const { selectedStatusFilter } = useContext(CallsScheduleContext);

  return useQuery<ResponseType, Error>(
    ['immediate', scenarioId, selectedStatusFilter.value],
    async () => {
      const pageNumber = 1;
      const pageSize = 15;
      const response = await callingHttpClient.get<ResponseType>(
        `/Batch/Immediate/${scenarioId}?PageNumber=${pageNumber}&PageSize=${pageSize}`,
      );

      return response.data;
    },
    {
      enabled: authenticated && !!selectedStatusFilter.value,
      staleTime: 5000,
      refetchInterval: 10000,
    },
  );
};
