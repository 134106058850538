import React from 'react';
import { Stack } from '@mui/material';
import { useKeycloak } from '@react-keycloak-fork/web';
import { Logo } from '../logo';

export const SplashScreen: React.FC<React.PropsWithChildren> = (props) => {
  const { initialized } = useKeycloak();

  if (!initialized)
    return (
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          width: '100%',
          height: '100vh',
        }}
      >
        <Logo />
      </Stack>
    );

  return <>{props.children}</>;
};
