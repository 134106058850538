import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type TranslationContextType = {
  currentLanguage?: string;
  setCurrentLanguage(lang: string): void;
};

export const TranslationContext = React.createContext<TranslationContextType>({
  currentLanguage: undefined,
  setCurrentLanguage: () => null,
});

export const TranslationProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);

  const setLang = useCallback((lang: string) => {
    setCurrentLanguage(lang);
    i18n.changeLanguage(lang);
    window.localStorage.setItem('lang', lang);
  }, []);

  const values = {
    currentLanguage,
    setCurrentLanguage: setLang,
  };

  useEffect(() => {
    if (window.localStorage.getItem('lang')) {
      window.localStorage.removeItem('lang');
      window.localStorage.setItem('lang', currentLanguage);
    }
    setLang(i18n.language);
  }, [i18n.language]);

  return <TranslationContext.Provider value={values}>{props.children}</TranslationContext.Provider>;
};
