import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import { environment } from '../../../environment';

declare module 'keycloak-js/dist/keycloak' {
  interface KeycloakTokenParsed {
    SipName: string;
    SipPassword: string;
    preferred_username: string;
    given_name: string;
    family_name: string;
    name: string;
    tenant_id?: string[];
    user_scenario_id?: string[];
    admin_scenario_id?: string[];
  }
}

export const keycloakInstance = new Keycloak({
  clientId: `${environment.KEYCLOAK_CLIENT_ID}`,
  realm: `${environment.KEYCLOAK_REALM}`,
  url: `${environment.KEYCLOAK_URL}`,
});

export const keycloakInitOptions: KeycloakInitOptions = {
  redirectUri: window.location.href,
  pkceMethod: 'S256',
  onLoad: 'login-required',
  checkLoginIframe: false,
};
