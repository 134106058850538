import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PauseCircleOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useDeviceQuery } from '../../../../common/hooks';
import { useScenario } from '../../../scenarios/hooks';
import { useBatchesQuery, useCallStop } from '../../hooks';
import { BatchContext } from '../../provider';

export const StopCallingButton: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useDeviceQuery();
  const { mutate } = useCallStop();
  const { batchId, callingInProgress } = useContext(BatchContext);
  const scenario = useScenario();
  const batchesQuery = useBatchesQuery();

  const handleCallStop = () => {
    mutate(
      {
        batchId: batchId.value,
        scenarioId: scenario.id,
      },
      {
        onSuccess: () => {
          callingInProgress.set(false);
          batchesQuery.refetch();
        },
      },
    );
  };

  return (
    <>
      {isMobile ? (
        <Button
          disabled={disabled}
          color="primary"
          variant="contained"
          startIcon={<PauseCircleOutline />}
          onClick={handleCallStop}
          sx={{
            height: (theme) => theme.spacing(6),
            minWidth: 48,
            display: 'flex',
            alignItems: 'center',
            columnGap: (theme) => theme.spacing(2),
            whiteSpace: 'nowrap',
            my: 0,
            mx: 2,
            '& .MuiButton-startIcon': {
              margin: 0,
            },
          }}
        />
      ) : (
        <Button
          disabled={disabled}
          color="primary"
          variant="contained"
          startIcon={<PauseCircleOutline />}
          onClick={handleCallStop}
          sx={{
            height: (theme) => theme.spacing(6),
            minWidth: 48,
            display: 'flex',
            alignItems: 'center',
            columnGap: (theme) => theme.spacing(2),
            whiteSpace: 'nowrap',
            my: 0,
            mx: 2,
          }}
        >
          {`${t('callsSchedule.collection.stop')} ${isDesktop ? t('callsSchedule.collection.calling') : ''} `}
        </Button>
      )}
    </>
  );
};
