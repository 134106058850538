import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Heading } from '../../components/heading';
import { DraftCollectionPage } from './draft-collection.page';
import { DraftCollectionProvider } from './provider';

export const AddCollectionPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        height: '100%',
      }}
    >
      <Heading name={t('callsSchedule.heading')} />
      <DraftCollectionProvider>
        <DraftCollectionPage />
      </DraftCollectionProvider>
      <Outlet />
    </Stack>
  );
};
