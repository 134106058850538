import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Grid, ListItem, Typography } from '@mui/material';
import { useDeviceQuery } from '../../../../common/hooks';
import { JsonEditor } from '../../../../components/json-editor';
import { useCreateCollectionValues } from '../../hooks';

type Props = {
  collectionKeyName?: string;
  refetch: () => void;
  setIsNewDocumentJsonOpen: (value: boolean) => void;
};

type FormData = {
  values: {
    [key: string]: unknown;
  };
};

export const CollectionValuesNewJson: React.FC<Props> = ({ collectionKeyName, refetch, setIsNewDocumentJsonOpen }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isLoading: isCreateCollectionValuesLoading } = useCreateCollectionValues();
  const { isDesktop, isMobile } = useDeviceQuery();

  const form = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    const { values } = data;
    mutate(
      {
        collectionKeyName: collectionKeyName ?? '',
        value: values,
      },
      {
        onSuccess: async () => {
          enqueueSnackbar(t('scenarioConfigurator.createFeedback.success'), { variant: 'success' });
          await refetch();
          setIsNewDocumentJsonOpen(false);
          form.reset({});
        },
        onError: async () => {
          enqueueSnackbar(t('scenarioConfigurator.createFeedback.error'), { variant: 'error' });
        },
      },
    );
  };

  return (
    <ListItem
      sx={{
        display: 'flex',
        flexDirection: 'column',
        px: 1,
        py: 0.5,
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        sx={{
          height: (theme) => theme.spacing(7),
          backgroundColor: 'background.default',
          borderRadius: 1,
          px: 2,
          py: 1,
          columnGap: (theme) => theme.spacing(isMobile ? 2 : 5),
        }}
      >
        <Grid
          item
          md={isDesktop ? 4 : 12}
          zeroMinWidth
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: (theme) => theme.spacing(isMobile ? 2 : 3),
            marginRight: 2,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            startIcon={<Close />}
            onClick={() => setIsNewDocumentJsonOpen(false)}
            sx={{
              width: 32,
              height: 32,
              minHeight: 32,
              minWidth: 32,
              borderRadius: 50,
              '& .MuiButton-startIcon': {
                margin: 0,
              },
            }}
          />
          <Typography
            variant="h5"
            title={t('scenarioConfigurator.newDocument')}
            noWrap
            sx={{
              fontWeight: 'bold',
              fontSize: 18,
            }}
          >
            {t('scenarioConfigurator.newDocument')}
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: '100%',
          padding: 1,
        }}
      >
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Controller
            control={form.control}
            name="values"
            render={({ field: { onChange, value } }) => <JsonEditor src={value ? value : {}} setter={onChange} />}
          />
          <LoadingButton
            disabled={!form.formState.isDirty}
            type="submit"
            variant="contained"
            color="primary"
            loading={isCreateCollectionValuesLoading}
            sx={{
              marginTop: 2,
            }}
          >
            {t('scenarioConfigurator.save')}
          </LoadingButton>
        </form>
      </Box>
    </ListItem>
  );
};
