import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { genericHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';
import { CollectionType } from '../types';

type ResponseType = {
  collections: CollectionType[];
  scenarioId: string;
};

export const useCollectionQuery = (): UseQueryResult<ResponseType, Error> => {
  const { authenticated, tokenParsed } = useAuth();
  const { id: scenarioId } = useScenario();
  const tenantId = tokenParsed?.tenant_id ? tokenParsed?.tenant_id[0] : '';

  return useQuery<ResponseType, Error>(
    ['collection', tenantId, scenarioId],
    async () => {
      const response = await genericHttpClient.get<ResponseType>(`/ScenarioCollections/${tenantId}/${scenarioId}`);
      return response.data;
    },
    {
      enabled: authenticated && !!tenantId && !!scenarioId,
      staleTime: 5000,
    },
  );
};
