import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckCircleOutlineOutlined,
  DoNotDisturbAltOutlined,
  ErrorOutlineOutlined,
  FolderCopyOutlined,
  HelpOutline,
  HourglassEmptyOutlined,
  InputOutlined,
  PhoneOutlined,
  SaveAsOutlined,
  ScreenRotationAltOutlined,
} from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { displayHour } from '../../../../common/utils';
import { RecordType } from '../../hooks/use-batch-records.query';
import { useDeviceQuery } from '../../../../common/hooks';
import { TimeZoneContext } from '../../../scenarios/provider/timezone-context.provider';

type Props = {
  record: RecordType;
};

export const RecordHistory: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useDeviceQuery();
  const { timezone } = useContext(TimeZoneContext);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setIconOfStatus = (status: string) => (
    <>
      {status == 'Unknown' ? <HelpOutline /> : null}

      {status == 'Drafted' ? <SaveAsOutlined /> : null}

      {status == 'Saved' ? <HourglassEmptyOutlined /> : null}

      {status == 'ProcessingRequested' ? <InputOutlined /> : null}

      {status == 'Processing' ? <ScreenRotationAltOutlined /> : null}

      {status == 'InCall' ? <PhoneOutlined /> : null}

      {status == 'Waiting' ? <ScreenRotationAltOutlined /> : null}

      {status == 'Completed' ? <CheckCircleOutlineOutlined /> : null}

      {status == 'CancelingRequested' ? <ScreenRotationAltOutlined /> : null}

      {status == 'Canceled' ? <DoNotDisturbAltOutlined /> : null}

      {status == 'Faulted' ? <ErrorOutlineOutlined sx={{ fontSize: '90%' }} /> : null}
    </>
  );

  const setBackgroundColorOfStatusIcon = (status: string): string => {
    let colorName = '#FFFFFF';

    {
      status == 'Unknown' ? (colorName = '#F26F61') : null;
    }

    {
      status == 'Drafted' ? (colorName = '#000000') : null;
    }

    {
      status == 'Saved' ? (colorName = '#666666') : null;
    }

    {
      status == 'ProcessingRequested' ? (colorName = '#40a6ce') : null;
    }

    {
      status == 'Processing' ? (colorName = '#3C45BB') : null;
    }

    {
      status == 'InCall' ? (colorName = '#3cb97a') : null;
    }

    {
      status == 'Waiting' ? (colorName = '#3C45BB') : null;
    }

    {
      status == 'Completed' ? (colorName = '#339900') : null;
    }

    {
      status == 'CancelingRequested' ? (colorName = '#edb168') : null;
    }

    {
      status == 'Canceled' ? (colorName = '#ec942c') : null;
    }

    {
      status == 'Faulted' ? (colorName = '#d92324') : null;
    }

    return colorName;
  };

  return (
    <>
      <Tooltip title={t('callsSchedule.collection.recordHistory.tooltip')} arrow enterDelay={500} leaveDelay={200}>
        <Button
          onClick={handleClickOpen}
          endIcon={<FolderCopyOutlined />}
          variant="outlined"
          sx={{
            minWidth: isDesktop ? 80 : isMobile ? 50 : 0,
            marginBottom: 0.5,
            marginLeft: 1,
          }}
        >
          {isDesktop ? t('callsSchedule.collection.recordHistory.buttonTitle') : ''}
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 900 }}>
          {t(`callsSchedule.collection.recordHistory.title`, { phoneNumber: props.record.phoneNumber })}
        </DialogTitle>
        <DialogContent>
          <Timeline position="alternate">
            {props.record.recordStatusHistory.map((statusHistory, index) => (
              <TimelineItem key={statusHistory.modifiedOn}>
                <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
                  {displayHour(statusHistory.modifiedOn, timezone)}
                </TimelineOppositeContent>

                <TimelineSeparator>
                  {statusHistory.status === 'Completed' ||
                  statusHistory.status === 'Canceled' ||
                  statusHistory.status === 'Faulted' ? (
                    <TimelineConnector sx={{ height: 32, backgroundColor: 'white' }} />
                  ) : (
                    <TimelineConnector sx={{ height: 32 }} />
                  )}
                  <TimelineDot sx={{ backgroundColor: setBackgroundColorOfStatusIcon(statusHistory.status) }}>
                    {setIconOfStatus(statusHistory.status)}
                  </TimelineDot>
                  {index === props.record.recordStatusHistory.length - 1 ? (
                    <TimelineConnector sx={{ height: 32, backgroundColor: 'white' }} />
                  ) : (
                    <TimelineConnector sx={{ height: 32 }} />
                  )}
                </TimelineSeparator>

                <TimelineContent sx={{ py: '30px', px: 2 }}>
                  <Typography variant="h6" component="span" sx={{ fontWeight: '700' }}>
                    {t(`callsSchedule.collection.recordHistory.${statusHistory.status.toLowerCase()}`)}
                  </Typography>
                  <Typography sx={{ fontSize: '90%' }}>
                    {t(`callsSchedule.collection.recordHistory.${statusHistory.status.toLowerCase()}Description`)}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>

          <DialogActions>
            <br />
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
