import { useMutation } from 'react-query';
import { callingHttpClient } from '../../../common/utils';

const cancelCallingRecord = async ({ scenarioId, recordId }: { scenarioId: string; recordId: string }) => {
  const response = await callingHttpClient.put(`/Record/${scenarioId}/${recordId}/Cancel`);
  return response.data;
};

export const useCancelCallingRecord = () => {
  return useMutation(({ scenarioId, recordId }: { scenarioId: string; recordId: string }) =>
    cancelCallingRecord({ scenarioId, recordId }),
  );
};
