import { IconButton, Stack, SxProps, Typography } from '@mui/material';
import React, { FC } from 'react';
import { format } from 'date-fns';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

type Props = {
  onRemove?: () => void;
  isEditable?: boolean;
  sx?: SxProps;
  date: {
    start: Date;
    stop: Date;
  };
};

export const RangeTime: FC<Props> = (props: Props) => {
  const start = new Date(props.date.start);
  const stop = new Date(props.date.stop);
  const formattedStart = format(start, 'dd-MM-yyyy HH:mm');
  const formattedStop = format(stop, 'dd-MM-yyyy HH:mm');

  const date =
    formattedStart.substring(0, 10) === formattedStop.substring(0, 10)
      ? formattedStart.substring(0, 10)
      : `${formattedStart.substring(0, 10)} - ${formattedStop.substring(0, 10)}`;

  const time = `${formattedStart.substring(11, 16)} - ${formattedStop.substring(11, 16)}`;

  return (
    <Stack
      sx={{
        ...props.sx,
        width: '100%',
        px: 1,
      }}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack flexDirection="row" gap={1}>
        <Typography variant="body2" color="textSecondary">
          {date}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            fontWeight: 'bold',
          }}
        >
          {time}
        </Typography>
      </Stack>
      {props.onRemove && (
        <IconButton onClick={props.onRemove} disabled={!props.isEditable}>
          <DeleteOutlineIcon />
        </IconButton>
      )}
    </Stack>
  );
};
