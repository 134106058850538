import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { callingHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';
import { DraftRecordType } from '../types';

type ResponseType = {
  totalCount: number;
  records: DraftRecordType[];
};

export const useDraftRecords = (): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const { id: scenarioId } = useScenario();

  return useQuery<ResponseType, Error>(
    ['draftRecords', scenarioId],
    async () => {
      const response = await callingHttpClient.get<ResponseType>(`/Record/Drafts/${scenarioId}`);
      return response.data;
    },
    {
      enabled: authenticated && !!scenarioId,
    },
  );
};
