import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { useAlert } from '../../../common/hooks/use-alert';
import { reportingHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';
import { DashboardContext } from '../provider/dashboard-context.provider';
import { DashboardChartType, DashboardWidgetType } from '../types';

type ResponseType = {
  widgets: [DashboardWidgetType];
  chart: DashboardChartType;
};

export const useCallGetAllWidgets = (): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const { id: scenarioId } = useScenario();
  const { errorAlert } = useAlert();
  const { days } = useContext(DashboardContext);

  return useQuery<ResponseType, Error>(
    ['call-getallwidgets', scenarioId, days],
    async () => {
      const response = await reportingHttpClient.get<ResponseType>(
        `/Dashboards/${scenarioId}/getallwidgets/?Days=${days.value}`,
      );
      return response.data;
    },
    {
      enabled: authenticated,
      staleTime: 5000,
      refetchInterval: 10000,
      retry: false,
      onError: () => {
        errorAlert('Wystąpił problem podczas pobierania danych');
      },
    },
  );
};
