import React, { useContext, useEffect, useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { enGB, pl } from 'date-fns/locale';
import { TranslationContext } from '../../modules/translation';
import { PopoverFilter } from '../popover-filter';

type Props = {
  value: { from?: Date; to?: Date };
  setFunction: (value: { from?: Date; to?: Date }) => void;
};

export const DateFilterColumn: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { currentLanguage } = useContext(TranslationContext);
  const [locale, setLocale] = useState({});
  const [value, setValue] = useState<Range>({
    startDate: props.value.from,
    endDate: props.value.to,
    key: 'selection',
    color: '#1c1b40',
  });

  useEffect(() => {
    const languages: { [key: string]: Locale } = {
      pl: pl,
      en: enGB,
    };
    setLocale(languages[currentLanguage || 'pl']);
  }, [currentLanguage]);

  return (
    <PopoverFilter
      clearable
      onAcceptClick={() => {
        props.setFunction({ from: value.startDate, to: value.endDate });
      }}
      onResetClick={() => {
        props.setFunction({});
        setValue({ key: 'selection', color: '#1c1b40' });
      }}
    >
      <DateRange
        dateDisplayFormat="dd.MM.yyyy"
        editableDateInputs={true}
        startDatePlaceholder={t('datePicker.dateFrom')}
        endDatePlaceholder={t('datePicker.dateTo')}
        locale={locale}
        onChange={(item) => {
          const dates = item as { selection: Range };
          setValue(dates.selection);
        }}
        moveRangeOnFirstSelection={false}
        ranges={[{ ...value, endDate: value.endDate || new Date() }]}
      />
    </PopoverFilter>
  );
};
