import React from 'react';
import { SetValueType, useSetValue } from '../../../common/hooks';

type DashboardContextType = {
  days: SetValueType<number>;
};

const initialData: DashboardContextType = {
  days: { set: () => null, value: 1 },
};

export const DashboardContext = React.createContext<DashboardContextType>(initialData);

export const DashboardContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const days = useSetValue<number>(initialData.days.value);
  const value: DashboardContextType = { days };

  return <DashboardContext.Provider value={value}>{props.children}</DashboardContext.Provider>;
};
