import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { callingHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';
import { History } from '../types';

type ResponseType = {
  history: History[];
};

export const useCallHistoryTranscription = (callId?: string): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const { id: scenarioId } = useScenario();

  return useQuery<ResponseType, Error>(
    ['call-history-transcription', scenarioId, callId],
    async () => {
      const response = await callingHttpClient.get<ResponseType>(`/CallTranscription/${scenarioId}/${callId}`);

      return response.data;
    },
    {
      enabled: authenticated && !!callId,
      staleTime: 30000,
    },
  );
};
