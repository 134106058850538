import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { FileType } from '../../../common/enums/file-types.enums';
import { reportingHttpClient } from '../../../common/utils';
import { useScenario } from '../../scenarios/hooks';
import { useExportReportDateQueryFilter } from './use-export-report-date-query-filter';

export const useExportReportData = (id: string, fileType: FileType): UseQueryResult<Blob, Error> => {
  const { id: scenarioId } = useScenario();
  const filters = useExportReportDateQueryFilter();

  return useQuery<Blob, Error>(
    ['export-report-data', scenarioId, id, filters],
    async () => {
      const response = await reportingHttpClient.get<Blob>(
        `/BusinessReports/${scenarioId}/export/${fileType}/?Id=${id}${filters}`,
        {
          responseType: 'arraybuffer',
          headers: { 'Content-Type': 'blob' },
        },
      );
      return response.data;
    },
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 5000,
    },
  );
};
