import React from 'react';
import { Card, Grid, Theme, Typography, useMediaQuery } from '@mui/material';

type PropsType = {
  widgets: Widget[];
};

type Widget = {
  index: number;
  label: string;
  value: string;
  valueType: string;
};

export const WidgetsContainer: React.FC<PropsType> = ({ widgets }) => {
  const isNumber = (value: string): boolean => !isNaN(Number(value)) && !isNaN(parseFloat(value));
  const xlMore = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Grid item xs={12} lg={7}>
      <Grid
        container
        spacing={1}
        sx={{
          height: (theme) => `calc(100% - ${theme.spacing(xlMore ? 0 : 0.5)})`,
        }}
      >
        {widgets.map((widget, index) => (
          <Grid
            item
            key={index}
            xs={12}
            lg={6}
            sx={{
              minHeight: 1 / 4,
            }}
          >
            <Card
              sx={{
                display: {
                  xs: !widget.index ? 'none' : 'flex',
                  lg: 'flex',
                },
                alignItems: 'center',
                height: '100%',
                backgroundColor: 'background.paper',
                padding: 3,
              }}
            >
              <Typography
                component="p"
                sx={{
                  minWidth: 67,
                  mr: 1,
                  fontSize: isNumber(widget.value) ? 36 : 24,
                  textAlign: 'center',
                }}
              >
                {widget.value ? (
                  widget.value
                ) : widget.index ? (
                  <Typography
                    component={'span'}
                    style={{
                      display: 'block',
                      textAlign: 'center',
                    }}
                  >
                    -
                  </Typography>
                ) : (
                  ''
                )}
              </Typography>
              <Typography component="p">{widget.label}</Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
