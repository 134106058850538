import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useModal } from '../../common/hooks';
import { useCallDetails } from '../../common/hooks/use-call-details.query';
import { displayDate, displayDurationFormat, displayHour, downloadFileWithToken } from '../../common/utils';
import { AudioStreamPlayer } from '../audio-stream-player';
import { BasicModal } from '../basic-modal';
import { IdCellContent } from '../id-cell-content';
import { RoundedButton } from '../rounded-button';
import { TimeZoneContext } from '../../modules/scenarios/provider/timezone-context.provider';

type Props = {
  callId: string;
  disabled?: boolean;
};

export const CallDetailsModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const modal = useModal();
  const { timezone } = useContext(TimeZoneContext);
  const callDetails = useCallDetails(modal.isOpen ? props.callId : undefined);
  const recording = callDetails.data?.recordingUrl || '';
  const stream = callDetails.data?.streamUrl || '';

  const modalBody = (
    <Stack justifyContent={'space-between'} height={'100%'}>
      <Box>
        {!callDetails.data && !stream && !recording && (
          <Typography
            variant="body2"
            align="center"
            sx={{
              marginY: 3,
              marginX: 0,
            }}
          >
            {t('history.call.noDetails')}
          </Typography>
        )}
      </Box>

      {!!callDetails.data && (
        <Stack
          flexWrap={'wrap'}
          sx={{
            flexDirection: {
              sm: 'row',
            },
            marginY: 2,
            marginX: 0,
            gap: '16px 40px',
            '& > div': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '20px',
              width: {
                sm: 'calc(50% - 20px)',
              },
              '& p': {
                margin: 0,
              },
            },
          }}
        >
          <Box>
            <strong>{t('history.connectionId')}</strong>
            <IdCellContent callId={callDetails.data?.callId || ''} />
          </Box>
          <Box>
            <strong>{t('history.date')}</strong>
            <p>{displayDate(callDetails.data?.callStartDateTime || '', timezone)}</p>
          </Box>
          <Box>
            <strong>{t('history.hour')}</strong>
            <p>{displayHour(callDetails.data?.callStartDateTime || '', timezone)}</p>
          </Box>
          <Box>
            <strong>{t('history.direction')}</strong>
            <p>
              {callDetails.data?.callDirection ? t(`history.directionValues.${callDetails.data?.callDirection}`) : '-'}
            </p>
          </Box>
          <Box>
            <strong>{t('history.numberFrom')}</strong>
            <p>{callDetails.data?.callerPhoneNumber}</p>
          </Box>
          <Box>
            <strong>{t('history.numberTo')}</strong>
            <p>{callDetails.data?.calleePhoneNumber}</p>
          </Box>
          <Box>
            <strong>{t('history.status')}</strong>
            <p>{callDetails.data?.callStatus ? t(`history.statusValues.${callDetails.data?.callStatus}`) : '-'}</p>
          </Box>
          <Box>
            <strong>{t('history.reasonForNoResponse')}</strong>
            <p>
              {callDetails.data?.callMissedPhoneReason
                ? t(`history.reasonForNoResponseValues.${callDetails.data?.callMissedPhoneReason}`)
                : '-'}
            </p>
          </Box>
          <Box>
            <strong>{t('history.duration')}</strong>
            <p>{displayDurationFormat(callDetails.data?.duration || '')}</p>
          </Box>
        </Stack>
      )}

      {!!stream && !!recording && (
        <Box
          sx={{
            marginTop: 2,
          }}
        >
          <Typography variant="h5">Nagranie</Typography>

          {stream && <AudioStreamPlayer stream={stream} />}
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <Button
              size="small"
              onClick={() => {
                const fileName = `${callDetails.data?.calleePhoneNumber}_${
                  callDetails.data?.callerPhoneNumber
                }_${format(new Date(callDetails.data?.callStartDateTime || ''), 'yyyy_MM_dd_HH_mm_ss')}.wav`;
                downloadFileWithToken(recording, fileName);
              }}
              color="primary"
              variant="contained"
              sx={{
                color: 'common.white',
              }}
            >
              {t('history.recordingModal.downloadRecording')}
            </Button>
          </Stack>
        </Box>
      )}
    </Stack>
  );

  return (
    <>
      <RoundedButton icon={<InfoOutlined />} disabled={props.disabled} onClick={modal.open} />
      <BasicModal
        title={t('history.call.details')}
        modalBody={modalBody}
        handleModalClose={modal.close}
        modalOpen={modal.isOpen}
      />
    </>
  );
};
