import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ListItem, Stack, Typography } from '@mui/material';
import { History } from '../../types';

type Props = {
  entry: History;
};

export const TranscriptionItem: React.FC<Props> = ({ entry }) => {
  const { t } = useTranslation();

  return (
    <ListItem
      sx={{
        display: 'flex',
        alignSelf: entry.Discriminator === 'HumanSaid' ? 'flex-end' : 'flex-start',
        maxWidth: '80%',
        width: 'auto',
        px: 0,
      }}
    >
      <Box
        sx={{
          borderRadius: '16px',
          backgroundColor: entry.Discriminator !== 'HumanSaid' ? '#3ae0df47' : 'background.default',
          paddingY: 1.5,
          paddingX: 2,
          width: 'auto',
          '& > *': {
            textAlign: entry.Discriminator === 'HumanSaid' ? 'right' : undefined,
            justifyContent: entry.Discriminator === 'HumanSaid' ? 'flex-end' : undefined,
          },
        }}
      >
        <Stack gap={'8px'} m={'0 0 10px 0'}>
          <Typography fontWeight={600} fontSize={16} m={0}>
            {t(`history.transcription.${entry.Discriminator.toLowerCase()}`)}
          </Typography>
        </Stack>

        {entry.Discriminator === 'HumanSaid' ? (
          <Typography m={0}>{entry.Text || `-- ${t('history.transcription.noAnswer')} --`}</Typography>
        ) : (
          ''
        )}

        {entry.Discriminator === 'BotSaid' && entry.InternalAudioFile?.Text ? (
          <Typography m={0}>
            {entry.InternalAudioFile?.Text.replace(/(<([^>]+)>)/g, '') ||
              `-- ${t('history.transcription.noAnswer')} --`}
          </Typography>
        ) : (
          ''
        )}

        {entry.Discriminator === 'BotSaid' && entry.ExternalAudioFile != null
          ? entry.ExternalAudioFile.Description && (
              <Typography m={0}>
                [odtworzono komunikat:{' '}
                {entry.ExternalAudioFile.Description || `-- ${t('history.transcription.noAnswer')} --`}]
              </Typography>
            )
          : ''}
      </Box>
    </ListItem>
  );
};
