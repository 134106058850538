import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { callingHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';
import { BatchDraftType } from '../types';

type ResponseType = BatchDraftType;

export const useBatchDraft = (): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const { id: scenarioId } = useScenario();

  return useQuery<ResponseType, Error>(
    ['batch-draft', scenarioId],
    async () => {
      const response = await callingHttpClient.get<ResponseType>(`/Batch/Draft?ScenarioId=${scenarioId}`);
      return response.data;
    },
    {
      enabled: authenticated,
    },
  );
};
