import axios from 'axios';
import { environment } from '../../environment';
import { keycloakInstance } from '../../modules/auth';

const getAuthorizationHeaders = () => {
  const { token } = keycloakInstance;
  return {
    Authorization: `Bearer ${token}`,
  };
};

const createClient = (baseURL: string) => {
  const instance = axios.create({ baseURL });
  instance.interceptors.request.use(async (request) => {
    request.headers = getAuthorizationHeaders();
    return request;
  });
  instance.interceptors.response.use(async (response) => {
    if (response.status === 401) {
      keycloakInstance.clearToken();
    }
    return response;
  });
  return instance;
};

export const callingHttpClient = createClient(environment.CALLING_API_URL);
export const reportingHttpClient = createClient(environment.REPORTING_API_URL);
export const genericHttpClient = createClient(environment.GENERIC_API_URL);
export const identityHttpClient = createClient(environment.IDENTITY_API_URL);
export const phoneHttpClient = createClient(environment.PHONE_API_URL);

export const foundationHttpClient = createClient(environment.FOUNDATION_API_URL);
export const apiHttpClient = createClient(environment.API_URL);
