import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup } from '@mui/material';
import { BasicModal } from '../../../../components/basic-modal';
import { InputText } from '../../../../components/inputs';
import { theme } from '../../../../theme';
import { useScenario } from '../../../scenarios/hooks';
import { useBatchesQuery, useUpdateBatchName } from '../../hooks';
import { BatchContext } from '../../provider';

type PropsType = {
  handleModalClose: () => void;
  modalOpen: boolean;
};
type LoginFormValues = {
  batchName: string;
};

export const EditCollectionModal: React.FC<PropsType> = (props) => {
  const { t } = useTranslation();
  const { id } = useScenario();
  const { mutate } = useUpdateBatchName();
  const { handleSubmit, control, reset } = useForm<LoginFormValues>();
  const batches = useBatchesQuery();
  const { batchId, batchName } = useContext(BatchContext);

  const handleEditCollection = (data: LoginFormValues) => {
    batchName.set(data.batchName);
    mutate(
      {
        batchId: batchId.value,
        scenarioId: id,
        batchName: data.batchName,
      },
      {
        onSuccess: () => {
          reset();
          batches.refetch();
          props.handleModalClose();
        },
      },
    );
  };

  const modalBody = (
    <form
      onSubmit={handleSubmit(handleEditCollection)}
      style={{
        marginTop: theme.spacing(2),
        width: 'calc(100vw - 150px)',
        maxWidth: 504,
      }}
    >
      <Controller
        name="batchName"
        control={control}
        defaultValue={batchName.value}
        rules={{ required: t('callsSchedule.draftCollection.errors.enterCollectionName') as string }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <InputText
            name="collectionName"
            type={'text'}
            label={t('callsSchedule.draftCollection.collectionName')}
            value={value}
            onChange={onChange}
            error={error}
            fullWidth={true}
          />
        )}
      />
      <FormGroup
        row
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            marginTop: 4,
          }}
        >
          {t('saveChanges')}
        </Button>
      </FormGroup>
    </form>
  );

  return (
    <BasicModal
      title={t('callsSchedule.collection.editCollectionModal.title')}
      modalBody={modalBody}
      handleModalClose={props.handleModalClose}
      modalOpen={props.modalOpen}
    />
  );
};
