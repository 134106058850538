import { ButtonClasses, ButtonProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const buttonsStyles: {
  defaultProps?: Partial<ButtonProps<'button'>> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof ButtonClasses>> | undefined;
} = {
  defaultProps: {
    variant: 'contained',
  },
  styleOverrides: {
    root: {
      textTransform: 'uppercase',
      fontSize: '14px',
      fontWeight: 700,
    },
    containedPrimary: {
      color: '#FFFFFF',
    },
    containedSecondary: {
      color: '#FFFFFF',
    },
  },
};
