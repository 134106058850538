import React from 'react';
import { Skeleton, SxProps } from '@mui/material';

type PropsType = {
  number: number;
  height?: number | string;
  width?: number | string;
  variant?: 'text' | 'circular' | 'rectangular';
  sx?: SxProps;
};

export const BasicSkeletons: React.FC<PropsType> = ({ number, height = 50, width, variant, sx }) => {
  return (
    <>
      {Array.from(Array(number).keys()).map((key) => (
        <Skeleton key={key} height={height} width={width} variant={variant} sx={sx} />
      ))}
    </>
  );
};
