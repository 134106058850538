import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { addDays, differenceInDays, isToday, startOfDay } from 'date-fns';
import { MAX_DATE_RANGE } from '../../../common/constants';
import { useAlert } from '../../../common/hooks/use-alert';
import { phoneHttpClient } from '../../../common/utils';
import { useScenario } from '../../scenarios/hooks';
import { Directions } from '../enums/directions.enum';
import { HistoryContext, HistoryContextType } from '../provider/history-context.provider';
import { FileType } from '../../../common/enums/file-types.enums';

const getFiltersString = ({ dateRange, directions }: Partial<HistoryContextType>) => {
  let dateQueryFilter = '';
  let dateFrom = dateRange?.value.from || new Date();
  const dateTo = !dateRange?.value.to || isToday(dateRange?.value.to) ? new Date() : dateRange?.value.to;
  const differenceBetweenDates = differenceInDays(dateTo, dateFrom);

  if (differenceBetweenDates > MAX_DATE_RANGE) {
    dateFrom = addDays(dateFrom, differenceBetweenDates - (MAX_DATE_RANGE - 1));
  }

  if (directions?.value.incoming) {
    dateQueryFilter += `&Direction=${Directions.Incoming}`;
  }

  if (directions?.value.outgoing) {
    dateQueryFilter += `&Direction=${Directions.Outcoming}`;
  }

  dateQueryFilter += `&DateTimeFrom=${startOfDay(dateFrom).toISOString()}`;
  dateQueryFilter += `&DateTimeTo=${dateTo.toISOString()}`;

  return dateQueryFilter;
};

export const useCallHistoryFile = (fileType: FileType): UseQueryResult<Blob, Error> => {
  const { id: scenarioId } = useScenario();
  const { successAlert, errorAlert } = useAlert();
  const historyContext = useContext(HistoryContext);

  return useQuery<Blob, Error>(
    ['call-history-csv-file', scenarioId, historyContext.directions.value, historyContext.dateRange.value],
    async () => {
      const filterString = getFiltersString(historyContext);

      const response = await phoneHttpClient.get<Blob>(
        `/CallsHistories/${scenarioId}/export/${fileType}?${filterString}`,
        {
          responseType: 'arraybuffer',
          headers: { 'Content-Type': 'blob' },
        },
      );
      return response.data;
    },
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        successAlert('Raport został pomyślnie wygenerowany');
      },
      onError: () => {
        errorAlert('Wystąpił problem podczas generowania raportu');
      },
    },
  );
};
