import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Button, FormGroup, Typography } from '@mui/material';
import { BasicModal } from '../../../../components/basic-modal';
import { routes } from '../../../../routes';
import { useScenario } from '../../../scenarios/hooks';
import { useRemoveDraft } from '../../hooks';
import { useDuplicateBatch } from '../../hooks/use-duplicate-batch.mutation';
import { BatchContext, DraftCollectionContext } from '../../provider';

type PropsType = {
  handleModalClose: () => void;
  modalOpen: boolean;
};

export const DuplicateCollectionModal: React.FC<PropsType> = (props) => {
  const { mutate } = useDuplicateBatch();
  const removeDraft = useRemoveDraft();
  const { id } = useScenario();
  const { t } = useTranslation();
  const { batchId } = useContext(BatchContext);
  const { draftId } = useContext(DraftCollectionContext);
  const navigate = useNavigate();

  const handleClose = () => {
    props.handleModalClose();
  };

  const handleDuplicateCollection = () => {
    const newDraftId = uuid();
    removeDraft.mutate(
      { scenarioId: id },
      {
        onSuccess: () => {
          mutate(
            { newBatchId: newDraftId, duplicationBatchId: batchId.value, scenarioId: id },
            {
              onSuccess: () => {
                draftId.set(newDraftId);
                props.handleModalClose();
                navigate(routes.scenario.callsSchedule.addCollection.link({ id }));
              },
            },
          );
        },
      },
    );
  };

  const modalBody = (
    <>
      <Typography component={'p'}>{t('callsSchedule.collection.duplicateCollectionModal.info')}</Typography>
      <FormGroup
        row
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          columnGap: '2rem',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleDuplicateCollection}
          sx={{
            marginTop: 2,
            flexGrow: 1,
          }}
        >
          {t('yes')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          sx={{
            marginTop: 2,
            flexGrow: 1,
          }}
        >
          {t('no')}
        </Button>
      </FormGroup>
    </>
  );

  return (
    <BasicModal
      title={t('callsSchedule.collection.duplicateCollectionModal.title')}
      modalBody={modalBody}
      handleModalClose={props.handleModalClose}
      modalOpen={props.modalOpen}
    />
  );
};
