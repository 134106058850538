import React from 'react';
import { SetValueType, useSetValue } from '../../../common/hooks';
import { ReportColumnType } from '../types';

export type ReportsContextType = {
  reportId: SetValueType<string>;
  dateRange: SetValueType<{ from?: Date; to?: Date }>;
  pageNumber: SetValueType<number>;
  dateColumnName: SetValueType<string>;
  columns: SetValueType<ReportColumnType[]>;
  callDetails: SetValueType<boolean>;
  distinctValues: SetValueType<string[] | undefined>;
};

const initialData: ReportsContextType = {
  reportId: { set: () => null, value: '' },
  dateRange: { set: () => null, value: { to: undefined, from: undefined } },
  pageNumber: { set: () => null, value: 1 },
  dateColumnName: { set: () => null, value: '' },
  columns: { set: () => null, value: [] },
  callDetails: { set: () => null, value: false },
  distinctValues: { set: () => null, value: undefined },
};

export const ReportsContext = React.createContext<ReportsContextType>(initialData);

export const ReportsContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const reportId = useSetValue<string>(initialData.reportId.value);
  const dateRange = useSetValue<{ from?: Date; to?: Date }>(initialData.dateRange.value);
  const pageNumber = useSetValue<number>(initialData.pageNumber.value);
  const dateColumnName = useSetValue<string>(initialData.dateColumnName.value);
  const columns = useSetValue<ReportColumnType[]>(initialData.columns.value);
  const callDetails = useSetValue<boolean>(initialData.callDetails.value);
  const distinctValues = useSetValue<string[] | undefined>(initialData.distinctValues.value);

  const value: ReportsContextType = {
    reportId,
    dateRange,
    pageNumber,
    dateColumnName,
    columns,
    callDetails,
    distinctValues,
  };

  return <ReportsContext.Provider value={value}>{props.children}</ReportsContext.Provider>;
};
