import React from 'react';
import { Card, Typography } from '@mui/material';

type PropsType = {
  currentCallsAmount: number;
  label: string;
};

export const DashboardCurrentCalls: React.FC<PropsType> = (props) => {
  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: 2,
        paddingY: 2,
        paddingX: 3,
        borderRadius: '0.5rem',
      }}
    >
      <Typography fontSize={'4rem'} component={'p'}>
        {props.currentCallsAmount}
      </Typography>
      <Typography fontSize={'1.5rem'} ml={7} component={'p'}>
        {props.label}
      </Typography>
    </Card>
  );
};
