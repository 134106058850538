import React from 'react';
import { useAuth } from '../auth';
import { environment } from '../../environment';
import { IntercomProvider as ReactIntercomProvider, useIntercom } from 'react-use-intercom';

export const IntercomUserProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { tokenParsed, role, authenticated } = useAuth();
  const { boot, shutdown } = useIntercom();

  const name = tokenParsed ? `${tokenParsed.name} ${tokenParsed.lastName}` : undefined;
  const user_email = tokenParsed?.user_email;
  React.useEffect(() => {
    if (authenticated) {
      boot({
        userId: tokenParsed?.sub,
        name: name,
        email: user_email,
      });
    } else {
      shutdown();
    }
  }, [authenticated, name, user_email, role, boot, shutdown]);
  return <ReactIntercomProvider appId={environment.INTERCOM_APP_ID}>{props.children}</ReactIntercomProvider>;
};
