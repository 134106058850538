import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { callingHttpClient } from '../../../common/utils';

type Body = {
  scenarioId: string;
  batchName: string;
};

type Response = unknown;

export const useSaveAndStartBatch = (): UseMutationResult<Response, MutationErrorType, Body> => {
  return useMutation(async (body) => {
    const response = await callingHttpClient.post('/Batch/SaveAndStart', body);
    return response.data;
  });
};
