import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { SaveAlt } from '@mui/icons-material';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { displayDate, displayDurationFormat, displayHour, downloadFileWithToken } from '../../../../common/utils';
import { DateFilterColumn } from '../../../../components/date-filter-column';
import { IdCellContent } from '../../../../components/id-cell-content';
import { LoaderComponent } from '../../../../components/loader-component';
import { RecordingModal } from '../../../../components/recording-modal';
import { RoundedButton } from '../../../../components/rounded-button';
import { TableHeaderCell } from '../../../../components/table-header-cell';
import { useCallHistory } from '../../hooks';
import { HistoryContext } from '../../provider/history-context.provider';
import { CallDirectionFilter } from '../call-direction-filter';
import { TranscriptionModal } from '../transcription-modal';
import { TimeZoneContext } from '../../../scenarios/provider/timezone-context.provider';

export const HistoryTable: React.FC = () => {
  const { t } = useTranslation();
  const { directions, dateRange } = useContext(HistoryContext);
  const { data, isLoading } = useCallHistory();
  const { timezone } = useContext(TimeZoneContext);

  const downloadRecording = (fileUrl: string, callePhoneNumber: string, callerPhoneNumber: string, date: string) => {
    const fileName = `${callePhoneNumber}_${callerPhoneNumber}_${format(new Date(date), 'yyyy_MM_dd_HH_mm_ss')}.wav`;
    downloadFileWithToken(fileUrl, fileName);
  };

  return (
    <Box
      sx={{
        mx: {
          xs: 2.5,
          sm: 3,
        },
        overflowX: 'auto',
        overflowY: 'hidden',
        borderColor: 'background.paper',
        borderStyle: 'solid',
        borderLeftWidth: 24,
        borderRightWidth: 24,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        boxSizing: 'border-box',
      }}
    >
      <Table>
        <TableHead>
          <TableRow
            sx={{
              '& .MuiTableCell-root': {
                whiteSpace: 'nowrap',
              },
            }}
          >
            <TableHeaderCell cellName={t('history.connectionId')} />
            <TableHeaderCell cellName={t('history.date')}>
              <DateFilterColumn value={dateRange.value} setFunction={dateRange.set} />
            </TableHeaderCell>
            <TableHeaderCell cellName={t('history.hour')} />
            <TableHeaderCell cellName={t('history.direction')}>
              <CallDirectionFilter value={directions.value} setFunction={directions.set} />
            </TableHeaderCell>
            <TableHeaderCell cellName={t('history.numberFrom')} />
            <TableHeaderCell cellName={t('history.numberTo')} />
            <TableHeaderCell cellName={t('history.status')} />
            <TableHeaderCell cellName={t('history.reasonForNoResponse')} />
            <TableHeaderCell cellName={t('history.duration')} />
            <TableHeaderCell cellName={t('history.recording')} stickyCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.callHistory?.map((call) => {
            return (
              <TableRow
                key={call.callId}
                sx={{
                  '& .MuiTableCell-root': {
                    whiteSpace: 'nowrap',
                  },
                }}
              >
                <TableCell>
                  <IdCellContent callId={call.callId} />
                </TableCell>
                <TableCell>{displayDate(call.callStartDateTime, timezone)}</TableCell>
                <TableCell>{displayHour(call.callStartDateTime, timezone)}</TableCell>
                <TableCell>{t(`history.directionValues.${call.direction}`)}</TableCell>
                <TableCell>{call.callerPhoneNumber}</TableCell>
                <TableCell>{call.calleePhoneNumber}</TableCell>
                <TableCell>{t(`history.statusValues.${call.status}`)}</TableCell>
                <TableCell>
                  {call.missedPhoneReason ? t(`history.reasonForNoResponseValues.${call.missedPhoneReason}`) : '-'}
                </TableCell>
                <TableCell>{displayDurationFormat(call.duration)}</TableCell>
                <TableCell
                  sx={{
                    display: 'flex',
                    columnGap: 1,
                    alignItems: 'center',
                    position: 'sticky',
                    right: -5,
                  }}
                >
                  <RecordingModal callId={call.callId} disabled={!call.recordingUrl} />
                  {call.status === 'ReceivedCall' ? (
                    <TranscriptionModal callId={call.callId} disabled={false} />
                  ) : (
                    <TranscriptionModal callId={call.callId} disabled={true} />
                  )}

                  <Box>
                    <RoundedButton
                      disabled={!call.recordingUrl}
                      onClick={() =>
                        downloadRecording(
                          call.recordingUrl ? call.recordingUrl : '',
                          call.calleePhoneNumber,
                          call.callerPhoneNumber,
                          call.callStartDateTime,
                        )
                      }
                      icon={<SaveAlt />}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {data?.totalCount === 0 ? (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '1.1rem',
            fontWeight: 700,
            width: '100%',
            mt: 3,
          }}
        >
          {t('history.noResults')}
        </Typography>
      ) : null}
      {isLoading ? <LoaderComponent /> : null}
    </Box>
  );
};
