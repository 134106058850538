import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell, TableHead, TableRow } from '@mui/material';

export const CallsTableHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow
        sx={{
          '& .MuiTableCell-root': {
            whiteSpace: 'nowrap',
          },
        }}
      >
        <TableCell>{t('call.callId')}</TableCell>
        <TableCell>{t('call.callDate')}</TableCell>
        <TableCell>{t('call.callStatus')}</TableCell>
        <TableCell>{t('call.phoneNumber')}</TableCell>
        {/*<TableCell>{t('call.details')}</TableCell>*/}
      </TableRow>
    </TableHead>
  );
};
