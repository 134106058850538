import { CssBaselineProps, CSSObject } from '@mui/material';

export const cssBaselineStyles: {
  defaultProps?: Partial<CssBaselineProps> | undefined;
  styleOverrides?: Partial<CSSObject>;
} = {
  styleOverrides: {
    a: {
      textDecoration: 'none',
    },
    body: {
      // backgroundColor: '#FFFFFF',
      '& #root': {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
};
