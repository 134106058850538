import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { callingHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';
import { DefaultParametersType } from '../types/default-parameters';

type ResponseType = {
  defaultParameters: DefaultParametersType;
};

export const useDefaultParameters = (): UseQueryResult<ResponseType, Error> => {
  const { tokenParsed } = useAuth();
  const tenantId = tokenParsed?.tenant_id ? tokenParsed?.tenant_id[0] : '';
  const { id: scenarioId } = useScenario();

  return useQuery<ResponseType, Error>(['defaultParameters', scenarioId], async () => {
    const response = await callingHttpClient.get<ResponseType>(
      `/ScenarioConfiguration/${tenantId}/${scenarioId}/defaultCallsScheduleParameters`,
    );
    return response.data;
  });
};
