import React from 'react';
import { ParamType } from '../../modules/scenarios/hooks';
import { Checkbox, Stack, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { InputText } from '../inputs';
import { format, parseISO } from 'date-fns';

type Props = {
  fullWidth?: boolean;
  parameters: {
    [key: string]: {
      defaultValue: string | number | boolean;
      readableName: string;
      type: string;
      required: boolean;
    };
  } | null;
};

export const ScenarioParameters: React.FC<Props> = ({ fullWidth, parameters }) => {
  function formatDate(dateString: string) {
    const parsedDate = parseISO(dateString);
    const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm");
    return formattedDate;
  }
  const { control } = useFormContext();

  return (
    <>
      {parameters &&
        Object.entries(parameters).map(([key, parameter]) => {
          const type = parameter.type;
          if (type === ParamType.Boolean) {
            return (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                key={key}
                sx={{
                  width: fullWidth ? '100%' : 'auto',
                }}
              >
                <Typography variant={'body1'}>{parameter.readableName}</Typography>
                <Controller
                  control={control}
                  name={key}
                  defaultValue={parameter.defaultValue as unknown as boolean}
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value as boolean}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
              </Stack>
            );
          }
          if (type === ParamType.DateTime) {
            return (
              <Controller
                key={key}
                name={key}
                rules={{
                  required: parameter.required,
                }}
                defaultValue={formatDate(parameter.defaultValue as unknown as string)}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      fullWidth={fullWidth}
                      InputLabelProps={{ shrink: true }}
                      name={key}
                      type={'datetime-local'}
                      margin={'dense'}
                      value={value || ''}
                      onChange={onChange}
                      label={parameter.readableName}
                    />
                  );
                }}
              />
            );
          }

          return (
            <Controller
              key={key}
              name={key}
              rules={{
                required: parameter.required,
              }}
              control={control}
              defaultValue={parameter.defaultValue as unknown as string}
              render={({ field, fieldState: { error } }) => (
                <InputText
                  fullWidth={fullWidth}
                  type={
                    type === ParamType.Double || type === ParamType.Int
                      ? 'number'
                      : type === ParamType.DateOnly
                      ? 'date'
                      : type === ParamType.TimeOnly
                      ? 'time'
                      : 'text'
                  }
                  shrink={type === ParamType.DateOnly || type === ParamType.TimeOnly}
                  margin={'dense'}
                  label={parameter.readableName}
                  error={error}
                  width={type === ParamType.TimeOnly ? '180px' : undefined}
                  name={field.name}
                  onChange={field.onChange}
                  value={field.value as string}
                />
              )}
            />
          );
        })}
    </>
  );
};
