import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { callingHttpClient } from '../../../common/utils';
import { useScenario } from '../../scenarios/hooks';

type Body = {
  recordId: string;
};

type Response = unknown;

export const useRemoveDraftRecord = (): UseMutationResult<Response, MutationErrorType, Body> => {
  const { id } = useScenario();
  return useMutation(async (body: { recordId: string }) => {
    const response = await callingHttpClient.delete(`/Record/RemoveDraft?recordId=${body.recordId}&scenarioId=${id}`);
    return response.data;
  });
};
