import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { callingHttpClient } from '../../../common/utils';
import { ImmediateCallRecordType } from '../types';

type Response = null;

type Body = {
  scenarioId: string;
  record: ImmediateCallRecordType;
};

export const useImmediateCall = (): UseMutationResult<Response, MutationErrorType, Body> => {
  return useMutation(async (body) => {
    const response = await callingHttpClient.post('/Batch/ImmediateStart', body);
    return response.data;
  });
};
