import React, { useContext, useEffect } from 'react';
import Truncate from 'react-truncate';
import { Box, Stack, TableCell, Tooltip } from '@mui/material';
import { DateFilterColumn } from '../../../../components/date-filter-column';
import { useReportDistinctValues } from '../../hooks';
import { ReportsContext } from '../../provider/reports-context.provider';
import DistinctValuesFilter from '../distinct-values-filter/distinct-values-filter';

type PropsType = {
  reportId: string;
  displayName: string;
  filterType?: string;
  columnName: string;
  lastCellSticky?: boolean;
};

export const TableHeaderCell: React.FC<PropsType> = (props) => {
  const { dateRange, dateColumnName, distinctValues } = useContext(ReportsContext);
  const { data: distinctValuesOptions } = useReportDistinctValues(props.reportId, 'status');

  useEffect(() => {
    if (props.filterType === 'DatePicker') {
      dateColumnName.set(props.columnName);
    }
  }, [dateColumnName]);

  return (
    <TableCell
      sx={{
        minWidth: 100,
        whiteSpace: 'nowrap',
        backgroundColor: 'background.paper',
        position: props.lastCellSticky ? 'sticky' : undefined,
        right: props.lastCellSticky ? -26 : undefined,
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        sx={{
          width: '100%',
        }}
      >
        <Tooltip placement={'bottom-start'} title={props.displayName}>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Truncate
              ellipsis={'...'}
              style={{
                width: '100%',
                minWidth: 100,
              }}
            >
              {props.displayName}
            </Truncate>
          </Box>
        </Tooltip>
        {props.filterType === 'DatePicker' ? (
          <DateFilterColumn value={dateRange.value} setFunction={dateRange.set} />
        ) : null}
        {props.filterType === 'Distict' && props.columnName === 'status' ? (
          <DistinctValuesFilter
            distinctValues={distinctValues}
            distinctValuesOptions={distinctValuesOptions?.distinctValues ? distinctValuesOptions.distinctValues : []}
          />
        ) : null}
      </Stack>
    </TableCell>
  );
};
