import React from 'react';
import Truncate from 'react-truncate';
import { Box, Stack, TableCell, Tooltip } from '@mui/material';

type PropsType = {
  cellName: string;
  stickyCell?: boolean;
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
};

export const TableHeaderCell: React.FC<React.PropsWithChildren<PropsType>> = (props) => {
  const { cellName, stickyCell, align, children } = props;
  return (
    <TableCell
      align={align}
      sx={{
        minWidth: 100,
        whiteSpace: 'nowrap',
        backgroundColor: 'background.paper',
        position: stickyCell ? 'sticky' : undefined,
        right: stickyCell ? -5 : undefined,
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        sx={{
          width: '100%',
        }}
      >
        <Tooltip placement={align === 'center' ? 'bottom' : 'bottom-start'} title={cellName}>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Truncate
              ellipsis={'...'}
              style={{
                width: '100%',
                minWidth: 100,
              }}
            >
              {cellName}
            </Truncate>
          </Box>
        </Tooltip>
        {children}
      </Stack>
    </TableCell>
  );
};
