import { AppBarClasses, AppBarProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const appBarStyles: {
  defaultProps?: Partial<AppBarProps<'header'>> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof AppBarClasses>> | undefined;
} = {
  styleOverrides: {
    root: {
      boxShadow: '0px 0px 30px 0px rgb(0 0 0 / 10%)',
    },
  },
};
