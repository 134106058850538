import React from 'react';
import { Container } from '@mui/material';

import { DraftCollectionForm, DraftCollectionPreview, LoginFormValues } from './components/draft-collection';
import { FormProvider, useForm } from 'react-hook-form';
import { useBatchDraft } from './hooks';
import { useDefaultParameters } from './hooks/use-default-parameters.query';
import { format, isMatch, parse, parseISO } from 'date-fns';

function formatDate(dateString: string) {
  const parsedDate = parseISO(dateString);
  return format(parsedDate, "yyyy-MM-dd'T'HH:mm");
}

export const DraftCollectionPage: React.FC = () => {
  const batchDraftData = useBatchDraft();
  const defaultParametersData = useDefaultParameters();

  const repeat = batchDraftData.data?.parameters?.repeat || defaultParametersData.data?.defaultParameters.repeat || 0;
  const delay = batchDraftData.data?.parameters?.delay || defaultParametersData.data?.defaultParameters.delay || 0;

  const batchName = batchDraftData.data?.batchName || '';

  const dates =
    batchDraftData?.data?.parameters?.callsDateTimes ||
    defaultParametersData?.data?.defaultParameters?.firstAvailableCallsDateTimes ||
    [];

  const getAdditionalParameters = () => {
    return Object.entries(defaultParametersData.data?.defaultParameters?.additionalParameters || {}).reduce<{
      [key: string]: string | number | boolean | Date | null;
    }>((acc, [key, value]) => {
      const existedInDraft = Object.entries(batchDraftData.data?.parameters?.additionalParameters || {}).find(
        ([keyDraft]) => keyDraft === key,
      );

      if (value.type === 'DateOnly') {
        if (!isMatch(String(existedInDraft?.[1] || value.defaultValue), 'dd.MM.yyyy')) {
          acc[key] = String(existedInDraft?.[1] || value.defaultValue);
          return acc;
        }

        if (isMatch(String(existedInDraft?.[1] || value.defaultValue), 'dd.MM.yyyy')) {
          const parsed = parse(String(existedInDraft?.[1] || value.defaultValue), 'dd.MM.yyyy', new Date());
          acc[key] = format(parsed, 'yyyy-MM-dd');
          return acc;
        }
      }

      if (value.type === 'DateTime') {
        acc[key] = formatDate(String(existedInDraft?.[1] || value.defaultValue));
        return acc;
      }

      if (value.type === 'Boolean') {
        if (typeof existedInDraft?.[1] === 'boolean') {
          acc[key] = existedInDraft?.[1];
          return acc;
        }

        acc[key] = value.defaultValue;
        return acc;
      }

      acc[key] = existedInDraft?.[1] || value.defaultValue;

      return acc;
    }, {});
  };

  const form = useForm<LoginFormValues>({
    defaultValues: {
      repeat: repeat,
      delay: delay,
      rangeDate: dates,
      additionalParameters: getAdditionalParameters() as { [key: string]: string | number | boolean | undefined },
      batchName: batchName,
      file: undefined,
    },
  });

  return (
    <FormProvider {...form}>
      <Container
        maxWidth="xxl"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: {
            xs: 'wrap',
            md: 'nowrap',
          },
        }}
      >
        <DraftCollectionForm />
        <DraftCollectionPreview />
      </Container>
    </FormProvider>
  );
};
