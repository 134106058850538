import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { callingHttpClient } from '../../../common/utils';
import { useScenario } from '../../scenarios/hooks';

type Body = {
  fileId: string;
};

type Response = unknown;

export const useRemoveDraftFile = (): UseMutationResult<Response, MutationErrorType, Body> => {
  const { id: scenarioId } = useScenario();

  return useMutation(async (body: { fileId: string }) => {
    const response = await callingHttpClient.delete(`/File/${scenarioId}/RemoveFile?fileId=${body.fileId}`);
    return response.data;
  });
};
