import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../components/heading';
import { CollectionList } from './components/collection';

export const ScenarioConfiguratorPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading name={t('scenarioConfigurator.heading')} className={'intercom-heading-scenario-configurator'} />
      <CollectionList />
    </>
  );
};
