import { plPL } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { breakpointsOptions } from './breakpoints';
import {
  appBarStyles,
  backdropStyles,
  buttonsStyles,
  cssBaselineStyles,
  inputLabelStyles,
  paperStyles,
  tableCellStyles,
} from './components';
import { paletteOptions } from './palette';
import { typographyOptions } from './typography';

export const theme = createTheme(
  {
    breakpoints: breakpointsOptions,
    palette: paletteOptions,
    typography: typographyOptions,
    components: {
      MuiAppBar: appBarStyles,
      MuiCssBaseline: cssBaselineStyles,
      MuiButton: buttonsStyles,
      MuiPaper: paperStyles,
      MuiInputLabel: inputLabelStyles,
      MuiTableCell: tableCellStyles,
      MuiBackdrop: backdropStyles,
    },
  },
  plPL,
);
