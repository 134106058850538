import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../components/heading';
import { ReportTable } from './components/report-table';
import { ReportsSelectBar } from './components/reports-select-bar';
import { ReportsContextProvider } from './provider/reports-context.provider';

export const ReportsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ReportsContextProvider>
      <Heading name={t('reports.heading')} className={'intercom-heading-reports'}>
        <ReportsSelectBar />
      </Heading>
      <ReportTable />
    </ReportsContextProvider>
  );
};
