import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { genericHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';
import { CollectionValuesDocumentType } from '../types';

type Body = {
  collectionKeyName: string;
  value: CollectionValuesDocumentType;
};

type Response = unknown;

export const useCreateCollectionValues = (): UseMutationResult<Response, MutationErrorType, Body> => {
  const { tokenParsed } = useAuth();
  const { id: scenarioId } = useScenario();
  const tenantId = tokenParsed?.tenant_id ? tokenParsed?.tenant_id[0] : '';

  return useMutation(async (body) => {
    const response = await genericHttpClient.post(
      `/ScenarioCollectionValues/${tenantId}/${scenarioId}/createscenariocollectionvalue`,
      body,
    );
    return response.data;
  });
};
