import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { reportingHttpClient } from '../../../common/utils';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';

type ResponseType = {
  distinctValues: string[];
};

export const useReportDistinctValues = (id: string, columnName: string): UseQueryResult<ResponseType, Error> => {
  const { authenticated } = useAuth();
  const { id: scenarioId } = useScenario();

  return useQuery<ResponseType, Error>(
    ['report-distinct-values', scenarioId, id],
    async () => {
      const response = await reportingHttpClient.get<ResponseType>(
        `/BusinessReports/${scenarioId}/distinctvalues?id=${id}&columnName=${columnName}`,
      );
      return response.data;
    },
    {
      enabled: authenticated && !!id,
      staleTime: 5000,
      retry: false,
    },
  );
};
