import { keycloakInstance } from '../../modules/auth';

export const downloadFile = (data: BlobPart, filename: string): void => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export const downloadFileWithToken = async (fileUrl: string, fileName: string): Promise<void> => {
  const { token } = keycloakInstance;
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const response = await fetch(fileUrl, { headers: { Authorization: `Bearer ${token}` } });

  const blob = await response.blob();

  const objectUrl = window.URL.createObjectURL(blob);

  anchor.href = objectUrl;
  anchor.download = fileName || 'no-name-file';
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};
