import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  keyframes,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { useScenariosParameters } from '../../../scenarios/hooks';
import { useBatchRecordsQuery } from '../../hooks';
import { BatchTableCellSkeletons } from './batch-skeletons';
import { RecordPreviewModal } from './record-preview-modal';
import { CancelCallingInRecord } from './cancel-calling-record';
import { CallOutlined, UnfoldMoreOutlined } from '@mui/icons-material';
import { useDeviceQuery } from '../../../../common/hooks';
import { RecordHistory } from './record-history';

type Props = {
  batchId?: string;
  isTableVisible: boolean;
};

export const BatchTable: React.FC<Props> = ({ batchId, isTableVisible }) => {
  const { t } = useTranslation();
  const ADDITIONAL_COLUMNS_NUMBER = 2;
  const batchRecords = useBatchRecordsQuery(batchId);
  const scenarioParams = useScenariosParameters();
  const [recordPreview, setRecordPreview] = useState<string | null>(null);
  const { isMobile, isDesktop } = useDeviceQuery();
  const [searchValue, setSearchValue] = useState(''); // State for search value

  const params2SlicedArray = <T,>(params: T) => {
    type ParamEntry = [string, T[keyof T]];
    if (!params) return [];
    const entries = Object.entries(params).slice(0, ADDITIONAL_COLUMNS_NUMBER);
    return entries as ParamEntry[];
  };

  const parameterColumns =
    scenarioParams.data &&
    params2SlicedArray(scenarioParams.data.parameters).map(([paramKey]) => (
      <TableCell sx={{ width: '10%', overflow: 'hidden' }} key={paramKey}>
        {paramKey}
      </TableCell>
    ));

  const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

  const filteredRecords = batchRecords.data
    ? batchRecords.data.records.filter((record) => record.phoneNumber.toLowerCase().includes(searchValue.toLowerCase()))
    : [];

  return isTableVisible ? (
    <Paper
      elevation={0}
      sx={{
        width: '100%',
        padding: 1,
      }}
    >
      <TableContainer
        sx={{
          maxHeight: '60vh',
        }}
      >
        <Table
          stickyHeader
          sx={{
            maxWidth: '100%',
          }}
        >
          {batchRecords.isLoading ? (
            <>
              <TableHead>
                <TableRow>
                  <BatchTableCellSkeletons />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover>
                  <BatchTableCellSkeletons />
                </TableRow>
                <TableRow>
                  <BatchTableCellSkeletons />
                </TableRow>
                <TableRow>
                  <BatchTableCellSkeletons />
                </TableRow>
              </TableBody>
            </>
          ) : (
            <>
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
                    '& .MuiTableCell-root': {
                      '& .MuiTableCell-stickyHeader': {
                        backgroundColor: 'background.paper',
                      },
                    },
                  }}
                >
                  {batchRecords.data && batchRecords.data.records.length !== 0 ? (
                    <>
                      <TableCell>
                        {t('callsSchedule.records.phone')}
                        <Box sx={{ mt: 1 }}>
                          <TextField
                            label={t('search')}
                            variant="outlined"
                            size="small"
                            sx={{
                              maxWidth: '100%',
                            }}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                        </Box>
                      </TableCell>
                      <TableCell>{t('callsSchedule.records.status')}</TableCell>
                      {isDesktop ? parameterColumns : null}
                      <TableCell></TableCell>
                    </>
                  ) : (
                    <TableCell>{t('callsSchedule.records.noRecords')}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              {filteredRecords.length !== 0 && (
                <TableBody>
                  {filteredRecords.map((record) => (
                    <TableRow
                      hover
                      key={record.id}
                      sx={{
                        ':hover': { fontWeight: '500', backgroundColor: 'green' },
                        '& .MuiTableCell-root': {
                          borderBottom: 'none',
                        },
                        '&.MuiTableRow-hover:hover': {
                          fontWeight: 'bold',
                        },
                      }}
                    >
                      <TableCell sx={{ ':hover': { color: 'black' }, width: '15%', overflow: 'hidden' }}>
                        {record.phoneNumber}
                      </TableCell>
                      <TableCell sx={{ ':hover': { color: 'black' }, width: '20%' }}>
                        {record.status === 'InCall' ? (
                          <CallOutlined
                            sx={{ marginRight: 1, fontSize: '95%', animation: `${blink} 2s linear infinite` }}
                          ></CallOutlined>
                        ) : (
                          ''
                        )}
                        {t(`callsSchedule.recordStatus.${record.status.toLowerCase()}`)}
                      </TableCell>
                      {params2SlicedArray(scenarioParams.data?.parameters).map(([paramKey]) =>
                        isDesktop === true ? (
                          <TableCell sx={{ ':hover': { color: 'black' } }} key={paramKey}>
                            {record.parameters[paramKey] as ReactNode}
                          </TableCell>
                        ) : null,
                      )}
                      <TableCell
                        sx={{
                          ':hover': { color: 'black' },
                          minWidth: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignContent: 'flex-start',
                          paddingRight: 1,
                          flexWrap: 'wrap',
                        }}
                      >
                        <Tooltip
                          title={t('callsSchedule.records.parametersTooltip')}
                          arrow
                          enterDelay={500}
                          leaveDelay={200}
                        >
                          <Button
                            endIcon={<UnfoldMoreOutlined />}
                            variant="outlined"
                            sx={{
                              minWidth: isDesktop ? 120 : isMobile ? 30 : 30,
                              marginBottom: 0.5,
                            }}
                            onClick={() => setRecordPreview(record.id)}
                          >
                            {isDesktop ? t('callsSchedule.records.parameters') : ''}
                          </Button>
                        </Tooltip>
                        <RecordHistory record={record} />
                        <RecordPreviewModal
                          recordId={record.id}
                          handleModalClose={() => {
                            setRecordPreview(null);
                          }}
                          modalOpen={record.id === recordPreview}
                        />
                        <CancelCallingInRecord
                          phoneNumber={record.phoneNumber}
                          recordId={record.id}
                          batchId={batchId}
                          disabled={record.status == 'completed' || record.status == 'Canceled'}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </>
          )}
        </Table>
      </TableContainer>
    </Paper>
  ) : null;
};
