import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { SetValueType, useSetValue } from '../../../common/hooks';
import { useAuth } from '../../auth';
import { useScenario } from '../../scenarios/hooks';
import { useBatchDraft, useCreateDraft } from '../hooks';
import { useDefaultParameters } from '../hooks/use-default-parameters.query';

type DraftCollectionContextType = {
  draftId: SetValueType<string>;
  scenarioHasNotBeenConfigured: SetValueType<boolean>;
};

const initialData: DraftCollectionContextType = {
  draftId: { set: () => null, value: '' },
  scenarioHasNotBeenConfigured: { set: () => null, value: false },
};

export const DraftCollectionContext = React.createContext(initialData);

export const DraftCollectionProvider: React.FC<React.PropsWithChildren> = (props) => {
  const draftId = useSetValue(initialData.draftId.value);
  const scenarioHasNotBeenConfigured = useSetValue(initialData.scenarioHasNotBeenConfigured.value);
  const { data, isLoading } = useBatchDraft();
  const batchDraftData = useBatchDraft();
  const defaultParametersData = useDefaultParameters();

  const { authenticated } = useAuth();
  const { id } = useScenario();
  const createDraft = useCreateDraft();

  useEffect(() => {
    if (isLoading) return;
    const newUuid = uuid();
    draftId.set(data?.batchId || newUuid);
    if (!data?.batchId && !isLoading && authenticated) {
      createDraft.mutate(
        { id: newUuid, scenarioId: id },
        {
          onError: () => {
            scenarioHasNotBeenConfigured.set(true);
          },
        },
      );
    }
  }, [data, isLoading]);

  const value: DraftCollectionContextType = {
    draftId: draftId,
    scenarioHasNotBeenConfigured: scenarioHasNotBeenConfigured,
  };

  const hasAllNecessaryDataToRender = batchDraftData.isFetched && defaultParametersData.isFetched && draftId.value;

  return (
    <DraftCollectionContext.Provider value={value}>
      {hasAllNecessaryDataToRender && props.children}
    </DraftCollectionContext.Provider>
  );
};
