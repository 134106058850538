import React from 'react';
import { DashboardContextProvider } from './provider/dashboard-context.provider';
import { Stack } from '@mui/material';
import { DashboardContent, DashboardNav } from './components';

export const DashboardPage: React.FC = () => {
  return (
    <DashboardContextProvider>
      <Stack
        sx={{
          flexGrow: 1,
        }}
      >
        <DashboardNav />
        <DashboardContent />
      </Stack>
    </DashboardContextProvider>
  );
};
