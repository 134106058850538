import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typographyOptions: TypographyOptions = {
  fontFamily: 'mulish',
  htmlFontSize: 16,
  subtitle1: {
    fontSize: '1.125rem',
  },
  subtitle2: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  h2: {
    fontSize: '3rem',
    fontWeight: 'bold',
    color: '#302f5b',
  },
};
