import { useMutation, UseMutationResult } from 'react-query';
import { MutationErrorType } from '../../../common/types';
import { callingHttpClient } from '../../../common/utils';

type Data = {
  scenarioId: string;
  batchId: string;
  start: Date;
  stop: Date;
};

type Response = unknown;

export const useRemoveCallsDateTime = (): UseMutationResult<Response, MutationErrorType, Data> => {
  return useMutation(async (body) => {
    const response = await callingHttpClient.delete(
      `/Batch/${body.batchId}/callsScheduleParameters/CallsDateTime/Remove`,
      {
        data: {
          scenarioId: body.scenarioId,
          start: body.start,
          stop: body.stop,
        },
      },
    );
    return response.data;
  });
};
