import { InputLabelClasses, InputLabelProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const inputLabelStyles: {
  defaultProps?: Partial<InputLabelProps> | undefined;
  styleOverrides?: Partial<OverridesStyleRules<keyof InputLabelClasses>> | undefined;
} = {
  styleOverrides: {
    outlined: {
      '&$marginDense': {
        transform: 'translate(14px, 14px) scale(1)',
      },
    },
  },
};
