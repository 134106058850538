import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CallsTable } from './calls-history/calls-table';

export const CallsList: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Stack>
      <Typography variant={'subtitle2'}>{t('call.pastCalls')}</Typography>
      <CallsTable />
    </Stack>
  );
};
