import { useMutation, UseMutationResult } from 'react-query';
import { callingHttpClient } from '../../../common/utils';
import { InsertFileErrorBody } from '../types';

type Response = null;

type Body = {
  scenarioId: string;
  file: File;
};

export const useInsertDraftFile = (): UseMutationResult<Response, InsertFileErrorBody, Body> => {
  return useMutation(async (body) => {
    const formData = new FormData();
    formData.append('file', body.file);
    formData.append('scenarioId', body.scenarioId);
    const response = await callingHttpClient.post('/File/InsertDraftFile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  });
};
