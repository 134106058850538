import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { Button } from '@mui/material';
import { useScenario } from '../../../scenarios/hooks';
import { useBatchDraft, useCreateDraft, useDraftRecords, useRemoveDraft } from '../../hooks';
import { DraftCollectionContext } from '../../provider';
import { useFormContext } from 'react-hook-form';
import { LoginFormValues } from './draft-collection-form';

export const NewDraftButton: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useScenario();
  const draftRecords = useDraftRecords();
  const removeDraft = useRemoveDraft();
  const createDraft = useCreateDraft();
  const batchDraft = useBatchDraft();
  const { draftId } = useContext(DraftCollectionContext);
  const { reset } = useFormContext<LoginFormValues>();

  const handleCreateDraft = () => {
    const newDraftId = uuid();
    draftId.set(newDraftId);
    removeDraft.mutate(
      { scenarioId: id },
      {
        onSuccess: async () => {
          if (draftRecords.data?.records?.length === 0) {
            await createDraft.mutate(
              { id: newDraftId, scenarioId: id },
              {
                onSuccess: () => {
                  draftRecords.refetch();
                  batchDraft.refetch();
                  reset();
                },
              },
            );
            return;
          }
          draftRecords.refetch();
          batchDraft.refetch();
        },
      },
    );
  };

  return (
    <Button
      onClick={handleCreateDraft}
      disabled={draftRecords.isLoading || removeDraft.isLoading || createDraft.isLoading}
      variant="contained"
      color="secondary"
      sx={{
        color: 'common.white',
        backgroundColor: 'error.main',
        '&:hover': {
          backgroundColor: 'error.light',
        },
        width: {
          xs: '100%',
          md: 'unset',
        },
      }}
    >
      {t('callsSchedule.draftCollection.clearCollection')}
    </Button>
  );
};
