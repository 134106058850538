import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { BasicModal } from '../../../../components/basic-modal';
import { useRecordQuery } from '../../hooks';
import { format } from 'date-fns';
import { RangeTime } from '../../range-time';

type PropsType = {
  recordId: string;
  handleModalClose: () => void;
  modalOpen: boolean;
};
export const RecordPreviewModal: React.FC<PropsType> = ({ recordId, handleModalClose, modalOpen }) => {
  const { t } = useTranslation();
  const { data } = useRecordQuery(recordId, modalOpen);
  const record = data?.record;
  const requiredParameters = record ? Object.keys(record.parameters) : [];
  const additionalParameters = record ? Object.keys(record?.additionalParameters || {}) : [];
  console.log(record);
  const modalBody = (
    <Box
      sx={{
        paddingBottom: 2,
        width: 450,
        maxHeight: 440,
      }}
    >
      <Grid
        container
        direction="column"
        spacing={1}
        sx={{
          pb: 2,
          rowGap: (theme) => theme.spacing(3),
        }}
      >
        <Typography variant="h6" textAlign="center" fontWeight={600}>
          {t('callsSchedule.record.requiredParameters')}
        </Typography>
        <Grid
          item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: (theme) => theme.spacing(2),
          }}
        >
          <Typography variant="subtitle1">{t('callsSchedule.record.phoneNumber')}</Typography>
          <Typography variant="subtitle2">{record?.phoneNumber}</Typography>
        </Grid>
        {requiredParameters.map((key) => (
          <Grid
            item
            key={key}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: (theme) => theme.spacing(2),
            }}
          >
            <Typography variant="subtitle1">{key}</Typography>
            <Typography variant="subtitle2">{record?.parameters[key]}</Typography>
          </Grid>
        ))}
        <Typography variant="h6" textAlign="center" fontWeight={600}>
          {t('callsSchedule.record.additionalParameters')}
        </Typography>
        {additionalParameters.map((key) => {
          const type = record?.additionalParameters?.[key]?.type;
          const value = record?.additionalParameters?.[key]?.value;
          return (
            <Grid
              item
              key={key}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: (theme) => theme.spacing(2),
              }}
            >
              <Typography variant="subtitle1">{record?.additionalParameters?.[key]?.readableName}</Typography>
              <Typography variant="subtitle2">
                {type === 'DateTime'
                  ? format(new Date(value || ''), 'dd.MM.yyyy HH:mm')
                  : type === 'Boolean'
                  ? value
                    ? 'Tak'
                    : 'Nie'
                  : value}
              </Typography>
            </Grid>
          );
        })}
        <Typography variant="h6" textAlign="center" fontWeight={600}>
          Harmonogram
        </Typography>
        {record?.callsPlanParameters?.firstAvailableCallsDateTimes?.map((date, index) => (
          <RangeTime
            isEditable={false}
            key={index}
            date={date}
            sx={{
              backgroundColor: index % 2 === 0 ? 'transparent' : 'rgba(0, 0, 0, 0.02)',
            }}
          />
        ))}
      </Grid>
    </Box>
  );
  return (
    <BasicModal
      title={t('callsSchedule.record.details')}
      modalBody={modalBody}
      handleModalClose={handleModalClose}
      modalOpen={modalOpen}
    />
  );
};
